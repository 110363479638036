import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode } from '@app/country-code';

@Pipe({
    name: 'address',
})
export class AddressPipe implements PipeTransform {
    countryCodeData = new CountryCode();

    transform(value: any, separator?: any): any {
        let hasSeparator: boolean = false;

        separator ? (hasSeparator = true) : (separator = '<br>');

        if (!value) return value;

        const address = [];
        const fields = ['address_line_1', 'address_line_2', 'city', 'postal_code', 'country'];

        fields.forEach((key: string) => {
            if (key in value && !!value[key]) {
                if (key === 'country') {
                    const country: any = this.countryCodeData.allCountries.find((c) => c[1].toString() === value[key]);

                    if (!country && value[key]) {
                        address.push(value[key]);
                    } else {
                        address.push(country ? country[0] : 'N/A');
                    }
                } else if (key === 'address_line_2' && hasSeparator) {
                    address[0] += ' ' + value[key];
                } else {
                    address.push(value[key]);
                }
            }
        });
        return address.length === 0 ? $localize`:@@not defined:not defined` : address.join(separator);
    }
}
