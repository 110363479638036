<div class="fm-main">
    <div class="header">
        <button id="close-btn" *ngIf="displayCloseBtn" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        <h2 mat-dialog-title>
            {{ headerText }}
        </h2>
    </div>

    <div class="fm-content">
        <div class="row">
            <div class="col-12 pt-3 px-4">
                <p i18n="warning|Warning dialog dob body@@dob-warning.message">
                    <strong>{{ data.username }}</strong>, in order to use this platform you need to add your date of birth.</p>
                <mat-form-field class="w-50">
                    <mat-label>date of birth</mat-label>
                    <input matInput autocomplete="off" [matDatepicker]="picker" [formControl]="dob">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="dob.hasError('required')">
                        {{ headerText }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 d-flex justify-content-end w-100 my-4">
                <button mat-raised-button class="fm-button-regular mr-2" [disabled]="dob?.invalid" (click)="close()"
                    i18n="warning|Warning dialog dob btn yes@@confirm">
                    confirm
                </button>
            </div>
        </div>
    </div>
</div>