<div class="fm_menu flex-column row no-gutters active" (swipeleft)="toogleMenu()">
    <div class="col-auto logo row">
        <div class="col-auto h-100">
            <img class="h-100 d-lg-none" src="./assets/img/logos/FreddieMed_icon_blue.svg" alt="freddie med logo"
                (click)="toogleMenu()">
            <img *ngIf="!disableMainNavigation" routerLink="/" class="h-100 d-none d-lg-block"
                src="./assets/img/logos/FreddieMed_logo_blue.svg" alt="freddie med logo">
        </div>
    </div>

    <!-- Main navigationlinks -->
    <div class="col" *ngIf="!disableMainNavigation; else show_doctor_card_for_booking">
        <ul class="links" *ngIf="authService.user">
            <li>
                <a routerLinkActive="active" [routerLink]="['/']" (click)="toogleMenu()"
                    [routerLinkActiveOptions]="{ exact: true }"
                    i18n="site sidebar|Navigation links@@shared.fm-menu.home">
                    home
                </a>
            </li>
            <li>
                <a routerLinkActive="active" [routerLink]="['/demographics']" (click)="toogleMenu()"
                    i18n="site sidebar|Navigation links@@general.demographics">
                    demographics
                </a>
            </li>
            <li>
                <a routerLinkActive="active" [routerLink]="['/appointments']" (click)="toogleMenu()"
                    i18n="site sidebar|Navigation links@@shared.fm-menu.appointments">
                    appointments
                </a>
            </li>
            <li>
                <a routerLinkActive="active" [routerLink]="['/attachments']" (click)="toogleMenu()"
                    i18n="site sidebar|Navigation links@@shared.fm-menu.attachments">
                    attachments
                </a>
            </li>
        </ul>
    </div>
    <ng-template #show_doctor_card_for_booking>

        <div class="col">
            <ul class="links avatar-logo">

                <ng-container *ngIf="bookingInfo && bookingInfo.medicalGroup">
                    <li>
                        <div class="row d-flex justify-content-start">
                            <div class="col-4 p-0">
                                <img class="doctor-logo" alt="logo" [src]="bookingInfo.medicalGroup.logo"
                                    *ngIf="bookingInfo.medicalGroup?.logo"
                                    (error)="bookingInfo.medicalGroup.logo = null">
                            </div>
                            <ng-container *ngIf="bookingInfo.professional">
                                <div class="col-4 p-0 pl-0 pl-md-4">
                                    <img class="doctor-logo" alt="logo" [src]="bookingInfo.professional.avatar"
                                        *ngIf="bookingInfo.professional?.avatar"
                                        (error)="bookingInfo.professional.avatar = null">
                                </div>
                            </ng-container>
                        </div>
                    </li>

                    <li>
                        <div class="row d-flex col-md-12 m-0 pt-1 pb-1 pl-1">
                            <div class="col-12 p-0">
                                <p class="speciality mb-0 mt-1">{{ bookingInfo.medicalGroup.name }}</p>
                            </div>
                            <ng-container *ngIf="bookingInfo.professional">
                                <div class="col-12 p-0">
                                    <p>
                                        <strong class="name mb-2 d-block">
                                            {{ bookingInfo.professional | name: true:'' }}
                                        </strong>
                                    </p>
                                </div>
                            </ng-container>
                        </div>
                    </li>
                </ng-container>

                <ng-container *ngIf="!bookingInfo.medicalGroup && bookingInfo.professional">
                    <li>
                        <div class="row d-flex justify-content-start">
                            <div class="col-12 text-center p-0 pl-0 pl-md-4">
                                <img class="doctor-logo" alt="logo" [src]="bookingInfo.professional.avatar"
                                    *ngIf="bookingInfo.professional?.avatar"
                                    (error)="bookingInfo.professional.avatar = null">
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="row d-flex col-md-12 m-0 pt-1 pb-1 pl-1">
                            <div class="col-12 p-0">
                                <p class="speciality mb-0 mt-1">
                                    {{ bookingInfo.professional | mainSpeciality: '' }}
                                </p>
                            </div>
                            <div class="col-12 p-0">
                                <p class="m-0">
                                    <strong class="name mb-2 d-block">
                                        {{ bookingInfo.professional | name: true:'' }}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </ng-template>

    <!-- Terms links -->
    <div class="col-auto">
        <ul class="links fm_links">
            <li class="m-0" *ngIf="disableMainNavigation; else terms_and_conditions">
                <img routerLink="/" class="terms-logo d-none d-lg-block"
                    src="./assets/img/logos/FreddieMed_logo_blue.svg" alt="freddie med logo">
            </li>

            <ng-template #terms_and_conditions>
                <li>
                    <a href="https://www.freddiemed.com/about" target="_blank" rel="noopener noreferrer"
                        i18n="site sidebar|Navigation links@@shared.fm-menu.about freddie med">
                        about Freddie Med
                    </a>
                </li>
                <li>
                    <a href="https://www.freddiemed.com/contact" target="_blank" rel="noopener noreferrer"
                        i18n="site sidebar|Navigation links@@general.contact">
                        contact
                    </a>
                </li>
                <li>
                    <a (click)="goTo(termsAndConditions)" i18n="site aside bar|Navigation links@@general.terms">
                        terms
                    </a>
                </li>
                <li>
                    <a (click)="goTo(privacyPolicy)" i18n="site aside bar|Navigation links@@shared.fm-menu.privacy">
                        privacy
                    </a>
                </li>
            </ng-template>

            <li>
                <a class="copyright">
                    <ng-container *ngIf="disableMainNavigation"
                        i18n="site aside bar|Navigation links@@shared.fm-menu.booking powered by">
                        Booking powered by
                        <br>
                    </ng-container>
                    &copy; Freddie Med {{ date | date: 'yyyy' }}
                </a>
            </li>
        </ul>
    </div>
</div>