import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainSpecialityPipe } from '@app/pipes/main-speciality.pipe';
import { NamePipe } from '@app/pipes/name.pipe';
import { TitlePipe } from '@app/pipes/title.pipe';
import { BaseComponent } from './base/base.component';
import { GeneralHeaderComponent } from './general-header/general-header.component';
import { ThumbnailComponent } from './thumbnail/thumbnail.component';
import { MatIconModule } from '@angular/material/icon';
import { EscapePipe } from '@app/pipes/escape.pipe';
import { AddressPipe } from '@app/pipes/address.pipe';
import { LetterReferencePipe } from '@app/pipes/letter-reference.pipe';
import { ProfessionNamePipe } from '@app/pipes/profession-name.pipe';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { AutofocusDirective } from '@app/directives/autofocus.directive';
import { RouterModule } from '@angular/router';
import { FmMenuComponent } from './fm-menu/fm-menu.component';
import { ServiceTypePipe } from '@app/pipes/service-type.pipe';
import { GenderTextPipe } from '@app/pipes/gender-text.pipe';
import { MaritalStatusPipe } from '@app/pipes/marital-status.pipe';
import { UserAddressPipe } from '@app/pipes/user-address.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { ReligionPipe } from '@app/pipes/religion.pipe';
import { LayoutModule } from '@angular/cdk/layout';
import { MatDividerModule } from '@angular/material/divider';
import { CurrencyPipe } from '@app/pipes/currency.pipe';
import { SafariOptimizedComponent } from './safari-optimized/safari-optimized.component';
import { SwitchUserComponent } from './switch-user/switch-user.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { PatientFileIosCheckComponent } from './patient-file-ios-check/patient-file-ios-check.component';
import { ProfessionSpecialityPipe } from '@app/pipes/profession-speciality.pipe';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { MatButtonModule } from '@angular/material/button';
import { AppointmentReasonPipe } from '../pipes/appointment-reason.pipe';
import { ReadmoreComponent } from './readmore/readmore.component';
import { WarningDialogModule } from '../modules/warning-dialog/warning-dialog.module';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { DebounceClickPreventerDirective } from '@app/directives/debounce-click.directive';


@NgModule({
    declarations: [
        AutofocusDirective,
        DebounceClickPreventerDirective,
        MainSpecialityPipe,
        ServiceTypePipe,
        NamePipe,
        TitlePipe,
        EscapePipe,
        AddressPipe,
        LetterReferencePipe,
        ProfessionNamePipe,
        ProfessionSpecialityPipe,
        GenderTextPipe,
        UserAddressPipe,
        BaseComponent,
        GeneralHeaderComponent,
        ThumbnailComponent,
        UserMenuComponent,
        FmMenuComponent,
        MaritalStatusPipe,
        ReligionPipe,
        CurrencyPipe,
        SafariOptimizedComponent,
        SwitchUserComponent,
        PatientFileIosCheckComponent,
        ActionButtonsComponent,
        AppointmentReasonPipe,
        ReadmoreComponent,
        SelectLanguageComponent
    ],
    exports: [
        SafariOptimizedComponent,
        ActionButtonsComponent,
        AutofocusDirective,
        DebounceClickPreventerDirective,
        MainSpecialityPipe,
        ServiceTypePipe,
        NamePipe,
        TitlePipe,
        EscapePipe,
        AddressPipe,
        LetterReferencePipe,
        ProfessionNamePipe,
        ProfessionSpecialityPipe,
        GenderTextPipe,
        UserAddressPipe,
        BaseComponent,
        GeneralHeaderComponent,
        ThumbnailComponent,
        UserMenuComponent,
        FmMenuComponent,
        MaritalStatusPipe,
        ReligionPipe,
        CurrencyPipe,
        SwitchUserComponent,
        MatCardModule,
        AppointmentReasonPipe,
        ReadmoreComponent,
        SelectLanguageComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        RouterModule,
        MatMenuModule,
        LayoutModule,
        MatDividerModule,
        MatDialogModule,
        MatCardModule,
        MatButtonModule,
        WarningDialogModule,
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        };
    }
}
