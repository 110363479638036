<!-- Loading view -->
<app-loading *ngIf="layoutService.loaded$ | async; else router_outlet"></app-loading>

<div class="container">
    <ng-template #router_outlet>
        <!-- Authenticated outlet, with navbars -->
        <mat-drawer-container class="example-container" [hasBackdrop]="!desktopLayout" *ngIf="!isInLogin; else auth">
            <!-- FM menu -->
            <mat-drawer #fmMenu [mode]="mode" [opened]="desktopLayout">
                <app-fm-menu></app-fm-menu>
            </mat-drawer>

            <!-- User menu -->
            <mat-drawer
                #userMenu
                id="user-menu"
                [mode]="'over'"
                [position]="'end'"
                *ngIf="!desktopLayout; else desktopUserMenu"
            >
                <app-user-menu></app-user-menu>
            </mat-drawer>

            <ng-template #desktopUserMenu>
                <app-user-menu></app-user-menu>
            </ng-template>

            <mat-drawer-content>
                <div class="page" [ngClass]="{ 'action-buttons': enableActionButtons }">
                    <app-general-header></app-general-header>
                    <router-outlet></router-outlet>
                </div>
                <app-action-buttons></app-action-buttons>
            </mat-drawer-content>
        </mat-drawer-container>

        <ng-template #auth>
            <div class="page bg-white" [ngClass]="{ 'action-buttons': enableActionButtons }">
                <router-outlet></router-outlet>
            </div>
            <app-action-buttons></app-action-buttons>
        </ng-template>
    </ng-template>
</div>
