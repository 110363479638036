import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent, WarningDialogData } from '@app/modules/warning-dialog/warning-dialog.component';
import { Subject, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PopUpService {
    navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
    popUpIsOpen: boolean = false;

    constructor(private readonly _dialog: MatDialog) {}

    open(configs: WarningDialogData = {}): any {
        if (this.popUpIsOpen) {
            return;
        }

        this.popUpIsOpen = true;

        const dialog = this._dialog.open(WarningDialogComponent, {
            width: '437px',
            disableClose: true,
            autoFocus: false,
            panelClass: ['freddie-dialog'],
            data: configs,
        });

        dialog
            .afterClosed()
            .pipe(take(1))
            .subscribe((res: boolean) => {
                this.popUpIsOpen = false;
                return this.navigateAwaySelection$.next(res);
            });
    }
}
