import { Injectable } from '@angular/core';
import currencies from '@data/currencies.json';
import { pick } from 'lodash';

import locales from '@data/locales.json';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '@env/environment';
import { map, retry, shareReplay } from 'rxjs/operators';
import { AppEnv } from '@app/types';
import { Platforms } from 'fm-core';

export function startupServiceFactory(dataService: DataService): Function {
    return () => {
        lastValueFrom(dataService.getTimezones());
        return lastValueFrom(dataService.configs());
    };
}

export let professions = [];
export let specialities = [];

@Injectable({
    providedIn: 'root',
})
export class DataService {
    env$: BehaviorSubject<AppEnv> = new BehaviorSubject<AppEnv>(null);

    get env() {
        return this.env$.value as AppEnv;
    }

    professionsChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    specialitiesChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    titlesChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    langChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    relationshipsChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    timezonesChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);

    constructor(private readonly http: HttpClient) {}

    get locales() {
        return locales;
    }

    get availableLocales() {
        return pick(this.locales, this.langChange.value);
    }

    set availableLocales(languagues) {
        this.langChange.next(languagues);
    }

    get professions() {
        return this.professionsChange.value;
    }

    set professions(professions) {
        this.professionsChange.next(professions);
    }

    get specialities() {
        return this.specialitiesChange.value;
    }

    set specialities(specialities) {
        this.specialitiesChange.next(specialities);
    }

    get titles() {
        return this.titlesChange.value;
    }

    set titles(titles) {
        this.titlesChange.next(titles);
    }

    get relationships() {
        return this.relationshipsChange.value;
    }

    set relationships(relationships) {
        this.relationshipsChange.next(relationships);
    }

    get timezones() {
        return this.timezonesChange.value;
    }

    set timezones(timezones) {
        this.timezonesChange.next(timezones);
    }

    get currencies() {
        return currencies;
    }

    getCurrencySymbol(currency): string {
        return this.currencies.find((c) => c.code === currency)?.symbol ?? '';
    }

    /**
     * Get timezones.
     *
     * @link api/v1/patient-portal/configs/timezones
     * @returns Observable<Array<string>>
     */
    getTimezones(): Observable<any> {
        return this.http.get<Array<string>>(`${env.API_URL}configs/timezones`).pipe(
            retry(2),
            shareReplay(),
            map((r: any) => {
                this.timezones = r;
            })
        );
    }
    /**
     * @link api/v1/patient-portal/configs
     * @returns
     */
    configs(): Observable<any> {
        const headers = new HttpHeaders().set('Freddiemed-Platform', `${Platforms.PATIENT}`);

        return this.http
            .get(`${env.API_URL}configs`, { headers })
            .pipe(
                map((r: any) => {
                    this.availableLocales = r.languagues;

                    if (r.professions) {
                        professions = this.professions = r.professions;
                    }

                    if (r.specialities) {
                        specialities = this.specialities = r.specialities;
                    }

                    if (r.titles) {
                        this.titles = Object.entries(r.titles).map((t) => {
                            return { key: +t[0], value: t[1] };
                        });
                    }

                    if (r.relationships) {
                        this.relationships = r.relationships;
                    }

                    if (r.env) {
                        this._validateEnvData(r.env);
                        this.env$.next(r.env);
                    }
                })
            );
    }

    /**
     * Validates env data that comes from the server.
     * In case one of these properties is missing from data,
     * and exception will be thrown and the application
     * will not be bootstrap correctly.
     *
     * @param env
     */
    private _validateEnvData(env: AppEnv): void | never {
        const keys: Array<string> = [
            'DISPENSATION_URL',
            'GOOGLE_MAPS_STATIC_KEY',
            'STRIPE_PUBLIC_KEY',
            'AVATARS',
            'SHOW_IMAGES',
            'VC_URL',
            'LANDING_PAGE_URL',
            'GOOGLE_ANALYTICS_TRACKING_ID',
        ];

        keys.forEach((key: string) => {
            if (!env.hasOwnProperty(key)) {
                throw new Error(`Missing configuration. The key "${key}" does not exists in configs request.`);
            }
        });
    }
}
