import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthComponent } from './auth.component';
import { LoginGuard } from '@app/guards/login.guard';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [ LoginGuard ],
        children: [
            { path: '', component: LoginComponent, /* pathMatch: 'full', */ data: { animation: 'left' } },
            { path: 'password/reset', component: ResetPasswordComponent, data: { animation: 'left' } },
            { path: 'password/reset/:token', component: ResetPasswordComponent, data: { animation: 'left' } },
        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
