import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EditService {
    constructor() {}

    private editDataChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    get editData(): any {
        return this.editDataChange.value;
    }

    updateData(editData): void {
        this.editDataChange.next(editData);
    }
}
