import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { NavigationError, Router } from '@angular/router';
import { Jwt } from '@app/models/Jwt';
import { AlertService, AuthService, LayoutService } from '@app/services';

@Injectable()
export class SessionsExpiredInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private _layoutService: LayoutService,
        private readonly _alertService: AlertService
    ) {}

    // Whitelist routes to avoid opening the session expired popup
    private whiteListRoutes: Array<string> = ['patient-confirm'];

    /**
     * Intercept every http request.
     *
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: any) => this.handleError(err, request, next)));
    }

    /**
     * Custom handler for errors.
     *
     * @param err HttpErrorResponse
     * @return Observable<never>
     */
    private handleError(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // to intercept authenticated requests
        if (this.authService.logged) {
            if (err.status === 423) {
                this._layoutService.isLoading(false);

                if (err.error.code === 610) {
                    this.router.navigate(['settings', 'freddiemed-terms']);
                    return EMPTY;
                } else if (err.error.code === 611) {
                    this.router.navigate(['settings', 'legal-documents', err.error.workspace_id]);
                    return EMPTY;
                }
            } else if (err.status === 401) {
                // Token expired
                if (err.error === 'token_expired' || err.error.error === 'token_expired') {
                    return this.authService.refresh().pipe(
                        mergeMap((data: Jwt) => {
                            this.authService.setSession(data);
                            const cloneRequest = request.clone({
                                setHeaders: { Authorization: `Bearer ${data.access_token}` },
                            });
                            return next.handle(cloneRequest);
                        }),
                        catchError(() => {
                            if (!this.authService.popedUp) {
                                this.authService.openSessionExpiredDialog();
                                this.authService.popedUp = true;
                            }
                            return EMPTY;
                        })
                    );
                } else {
                    // session expired
                    this.router.events.subscribe((event: any) => {
                        if (event instanceof NavigationError) {
                            const isWhiteListUrl: boolean = event.url
                                .split('/')
                                .some((part: string) => this.whiteListRoutes.includes(part));

                            if (isWhiteListUrl) {
                                this.authService.clearSessionStorage();
                                this._layoutService.isLoading(false);
                                this.router.navigateByUrl(event.url);
                                return EMPTY;
                            } else {
                                localStorage.setItem('previousUrl', this.router.url);

                                if (!this.authService.popedUp) {
                                    this.authService.openSessionExpiredDialog();
                                    this.authService.popedUp = true;
                                }
                                return EMPTY;
                            }
                        }

                        return EMPTY;
                    });
                }
            } else if (err.status === 429) {
                this._alertService.openSnackError(
                    $localize`:Alert|Messages@@too many attempts:Too many attempts, please try again later`
                );
                return EMPTY;
            }
        }

        return throwError(() => err);
    }
}
