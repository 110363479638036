export class ScriptHelper {
    static loadExternalScript(src: string, id: string, callback: Function): void {
        const existingScript = document.getElementById('jitsi-meet');

        if (!existingScript) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = src;
            s.id = id;
            s.onload = () => callback?.();
            document.head.appendChild(s);
        }
        if (existingScript && callback) {
            callback();
        }
    }
}
