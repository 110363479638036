import { User } from './User';

export class Patient extends User {
    permission?: any;
    dependents?: any;
    responsibles?: any;
    labels?: any;
    contact_party: any;
    care_provider: any;
    role: string;
    address_line_1: string;
    address_line_2: string;
    postal_code: string;
    local_authority: string;
    city: string;
    country: string;
    has2step: number;

    constructor(obj) {
        super(obj);

        this.responsibles = this.responsibles ? this.responsibles.map(r => new Patient(r)) : null;
        this.dependents = this.dependents ? this.dependents.map(d => new Patient(d)) : null;
    }

}
