import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
    MatButtonModule,
    MatDialogRef,
    MatIconModule,
    MatDatepickerModule,
    MAT_DIALOG_DATA,
    MatFormFieldModule,
    MatInputModule,
} from 'fm-core';

@Component({
    selector: 'app-dob-warning',
    templateUrl: './dob-warning.component.html',
    styleUrls: ['./dob-warning.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatDatepickerModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class DobWarningComponent {
    displayCloseBtn: boolean = false;
    headerText: string = $localize`:warning|Warning dialog dob title@@dob-warning.date of birth missing:Date of birth is missing`;

    private _dialogRef = inject(MatDialogRef<Component>);

    data: { username: string } = inject(MAT_DIALOG_DATA);

    dob = new FormControl('', {
        validators: [Validators.required],
        updateOn: 'blur',
    });

    constructor() {}

    close(): void {
        this._dialogRef.close(this.dob.value);
    }
}
