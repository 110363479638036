import { AbstractControl } from '@angular/forms';
import { isValidNumber } from 'libphonenumber-js';
import { Utils } from '@app/utils';

export function ValidatePhone(control: AbstractControl) {

    if (control.parent === undefined) {
        return null;
    }

    const parent = control.parent && control.parent.controls['country_phone'] ? control.parent.controls['country_phone'].value : null;
    const value = control && control.value && typeof control.value === 'string' ? control.value.replace(/\s/g, '') : '';

    if (value.length > 0) {
        if (!isValidNumber(Utils.formatPhone(value, parent))) {
            return { 'invalidPhone': { value: true } };
        }
    }

    return null;
}

