import { User, Clinic, MedicalGroup } from '@app/models';
import { cloneDeep as _cloneDeep } from 'lodash';
import { Patient } from './Patient';
import * as moment from 'moment';
import { NamePipe } from '@app/pipes/name.pipe';
import { ENV } from '@app/tokens/env.token';
import { AppInjector } from '@app/app.module';

export class Appointment {
    env: any = AppInjector.get(ENV);

    check_in_at: string;
    check_in_status: any;
    clinic: Clinic | null;
    clinic_id: string | number;
    day: string;
    description: string;
    appointment_contact: string;
    user: User;
    id: string | number;
    user_appointment_id?: string | number;
    patient_file_id?: string | number;
    medical_group: MedicalGroup;
    medical_group_id: string | number;
    patient: Patient;
    patient_confirmed_at: null;
    price: number | string;
    phone: number | string;
    reminder_type: any;
    reminder_time: any;
    reminder: any;
    speciality_id: string | number;
    type: any;
    appointment_type?: any;
    reason: any;
    permission?: any;
    missing_documents?: any;
    start: Date | string;
    end: Date | string;
    service: any;
    video_id?: string;
    video_joined?: boolean;
    assessment?: any;
    is_past_appointment?: boolean;
    deleted_at?: Date | string;

    constructor(data) {
        Object.assign(this, _cloneDeep(data));

        this.start = moment(this.start).toDate();
        this.end = moment(this.end).toDate();

        this.user = this.user ? new User(this.user) : null;
        this.medical_group = this.medical_group ? new MedicalGroup(this.medical_group) : null;
        this.patient = this.patient ? new Patient(this.patient) : null;

        if (typeof data.reminder === 'string') {
            data.reminder = JSON.parse(data.reminder);
        }

        const formClinic = this.medical_group ? this.medical_group : this.user;

        if (formClinic && formClinic.clinics) {
            this.clinic = new Clinic(
                Object.assign(
                    this.clinic,
                    formClinic.clinics.find((c) => c.id === this.clinic.id)
                )
            );
        }
    }

    get videoUrl(): string {
        const params = `#userInfo.displayName="${encodeURIComponent(new NamePipe().transform(this.patient))}"`;
        
        return `${this.env.VC_URL}/${this.video_id}${params}`;
    }

    get canJoinVideoCall(): boolean {
        return (
            !this.is_past_appointment &&
            (this.check_in_status === 1 || this.check_in_status === 3) &&
            (this.appointment_type === 3 || this.type === 3) &&
            this.video_joined &&
            this.video_id.length > 0
        );
    }
}
