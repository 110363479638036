import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';
import { User } from '@app/models';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    constructor(private readonly _http: HttpClient) {}

    private _makeUrl(uri = ''): string {
        return env.API_URL + 'search/' + uri;
    }

    searchDoctors(data: any): Observable<any> {
        return this._http.post(this._makeUrl('professional'), data).pipe(
            map((response: any) => {
                response.data = response.data.map((d: any) => new User(d));
                return response.data;
            })
        );
    }

    /**
     * Search clinics.
     *
     * @link api/v1/patient-portal/search/clinic
     * @param data
     * @returns Observable<any>
     */
    searchClinics(data: any): Observable<any> {
        return this._http.post(this._makeUrl('clinic'), data).pipe(map((response: any) => response.data));
    }

    /**
     * Search languages.
     *
     * @link api/v1/patient-portal/search/languages
     * @param val
     * @returns Observable<any>
     */
    searchLanguages(val: string): Observable<any> {
        return this._http.get(this._makeUrl('languages')).pipe(
            map((response: any) => {
                if (val) {
                    return response.data
                        .filter((l: any) => l.name.toLowerCase().includes(val.toLowerCase()))
                        .map((l: any) => l.name);
                } else {
                    return response.data.map((l: any) => l.name);
                }
            })
        );
    }
}
