import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Router } from '@angular/router';
import { TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@app/global';
import { AuthService, BookingService, LayoutService } from '@app/services';
import { filter } from 'rxjs';

@Component({
    selector: 'app-fm-menu',
    templateUrl: './fm-menu.component.html',
    styleUrls: ['./fm-menu.component.scss'],
})
export class FmMenuComponent extends BaseComponent {
    termsAndConditions = '/terms/';
    privacyPolicy = '/terms/';
    date = new Date();
    bookingInfo: any = null;
    disableMainNavigation: boolean = false;

    constructor(
        public readonly authService: AuthService,
        private readonly _router: Router,
        private readonly _layoutService: LayoutService,
        private readonly _bookingService: BookingService
    ) {
        super();

        this.subscriptions.add(
            this._bookingService.currentChange$.subscribe((current) => {
                this.bookingInfo = current;
                this.disableMainNavigation = current != null && current.booking_type !== 'internal';
            })
        );

        this.termsAndConditions += TERMS_AND_CONDITIONS;
        this.privacyPolicy += PRIVACY_POLICY;
    }

    goTo(url: string = '/'): void {
        this.toogleMenu();
        this._router.navigateByUrl(url);
    }

    toogleMenu(): void {
        this._layoutService.updateDrawerFmMenuState(true);
    }
}
