import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FmErrorMatcher } from '@app/error-state-matchers/fm-error-matcher';
import { AuthService } from '@app/services';
import { Utils } from '@app/utils';
import { take } from 'rxjs';

@Component({
    selector: 'step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
    @Output() onloggedin = new EventEmitter<number>();
    @Output() onpassword = new EventEmitter<string>();

    @ViewChild('passwordElem', { static: false }) passwordElem;

    private passwordMinLength = 6;

    matcher = new FmErrorMatcher();

    twoStepFormGroup = new UntypedFormGroup({
        password: new UntypedFormControl('', [Validators.required]),
    });

    constructor(private authService: AuthService, private _cdr: ChangeDetectorRef) {
        this.initForm();
    }

    ngOnInit(): void {
        this.controls.doubleCheck.setErrors({ invalid: true });
    }

    ngAfterViewChecked(): void {
        if (this.passwordElem) {
            this.passwordElem.nativeElement.focus();
        }
        this._cdr.detectChanges();
    }

    validatePassword(): void {
        if (this.controls.password.value.length < this.passwordMinLength) {
            this.controls.password.setErrors({ server: { value: true } });
        }

        if (this.controls.password.valid) {
            this.authService
                .verifyPassword(this.controls.password.value)
                .pipe(take(1))
                .subscribe((response: any) => {
                    Utils.responseHandler(
                        response,
                        () => {
                            this.controls.doubleCheck.updateValueAndValidity();
                            this.onpassword.emit(this.controls.password.value);
                            this._changeStep(2);
                        },
                        () => {
                            this.controls.password.setErrors({ server: { value: true } });
                        }
                    );
                });
        }
    }

    private _changeStep(stepperIndex: number): void {
        this.onloggedin.emit(stepperIndex);
    }

    private initForm(): void {
        this.twoStepFormGroup = new UntypedFormGroup({
            password: new UntypedFormControl('', [
                Validators.required,
                /* Validators.minLength(env.PASSWORD_LENGTH), */
            ]),
            doubleCheck: new UntypedFormControl('', []),
        });
    }

    get controls(): any {
        return this.twoStepFormGroup.controls;
    }
}
