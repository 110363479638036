import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActionButtonsService {
    actionButtonsDataChanged: BehaviorSubject<any> = new BehaviorSubject<any>({});
    buttonClickedDataChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private config = {
        visible: false,
        submitText: null,
        cancelText: $localize`:@@general.cancel:cancel`,
        hasCancel: false,
        submitDisabled: false,
        disablePrevious: false,
        stepperButtons: null,
        callbacks: {
            submitCallBack: null,
            cancelCallBack: null,

            nextCallBack: null,
            skipCallBack: null,
            previousCallBack: null,
            findCallBack: null,
        },
    };

    constructor() {}

    /**
     * @todo:
     * Global OnDestroy -> set buttonsData = { visible: false } -> subscriptions.unsubscribe > buttonClicked = Null
     */

    set buttonsData(data) {
        this.actionButtonsDataChanged.next({ ...this.config, ...data });
    }

    get buttonsData() {
        return this.actionButtonsDataChanged.value;
    }

    set buttonClicked(button: string) {
        if (button === 'submit' || button === 'next') {
            this.actionButtonsDataChanged.next({ ...this.buttonsData, ...{ submitDisabled: true } });
        }
        this.buttonClickedDataChanged.next(button);
    }
}
