import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@app/services';
import { Utils } from '@app/utils';
import { environment as env } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-select-language',
    templateUrl: './select-language.component.html',
    styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent {
    country = '';
    locales = [];

    @Input() general_header = false;

    constructor(
        private readonly _dataService: DataService,
        private readonly _cookieService: CookieService,
        private readonly _router: Router
    ) {
        let lang = 'en_GB';

        if (env.PRODUCTION) {
            lang = window.location.href.split('/')[3].replace('-', '_');
        }

        this.country = './assets/img/flags/' + lang + '.png';
        this.locales = this._dataService.availableLocales;
    }

    changeLanguage(language: string): void {
        language = language.replace('_', '-');
        this._cookieService.set('lang', language, 365, '/');

        if (Utils.currentLang != language) {
            const ORIGIN: string = window.location.origin.replace(/\/$/, '').concat('/');
            const newUrl: string = `${ORIGIN}${language}${this._router.url}`;

            window.location.href = newUrl;
        }
    }
}
