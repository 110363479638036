import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Slot } from './Slot';
import { Utils } from '@app/utils';
import { Clinic } from './Clinic';
import { User } from './User';
import { uniq } from 'lodash';
import { CurrencyPipe } from '@app/pipes/currency.pipe';
import { MedicalGroup } from './MedicalGroup';

export class Calendar {
    static getAllAvailableServices(user, type = null, clinic = null) {
        const services = [];

        const slots = Calendar.getRangeSlots(
            user,
            moment().startOf('day').toDate(),
            moment().add(1, 'year').endOf('day').toDate()
        ).filter((s) => (!type || (type && s.types.indexOf(type) !== -1)) && (!clinic || clinic.id === s.clinic_id));

        user.medical_groups.forEach((group: MedicalGroup) => {
            group
                .getMemberServices(user)
                .filter((s) => !type || (type && s.type === type))
                .forEach((service) => {
                    if (slots.find((s) => s.medical_group_id === group.id && s.types.indexOf(service.type) !== -1)) {
                        service.medical_group_id = group.id;
                        Array.prototype.push.apply(services, [service]);
                    }
                });
        });

        user.servicesByProfession
            .filter((s) => !type || (type && s.type === type))
            .forEach((service) => {
                if (slots.find((s) => s.medical_group_id === null && s.types.indexOf(service.type) !== -1)) {
                    service.medical_group_id = null;
                    Array.prototype.push.apply(services, [service]);
                }
            });

        return services;
    }

    static getAllEvents(date, user, values, appointments = null) {
        const start = moment(date).startOf('day').toDate();
        const end = moment(date).endOf('day').add(2, 'days').toDate();

        const availableServices = Calendar.getAllAvailableServices(user, values.type);

        const newEvents = Calendar.getRangeSlots(user, start, end, appointments).filter((c) => {
            return (
                availableServices.find((s) => s.medical_group_id === c.medical_group_id) &&
                !c.isDetachedAppointment &&
                (+values.type !== 1 || !values.clinic || +values.clinic.id === +c.clinic_id) &&
                (!values.type || (values.type && c.types.indexOf(values.type) !== -1))
            );
        });

        // Fill the event blocks in selected slots
        if (appointments) {
            newEvents.forEach((slot) => {
                slot.excluded_times = appointments
                    .filter((a) => a.id !== values.id && Calendar.hasAppointmentInOtherSlot(slot, a))
                    .map((a) => ({ ...a, ...{ patient: null } }));
            });
        }

        return newEvents;
    }

    static getGroupEvents(date, group, filterData, values, appointments = null) {
        const start = moment(date).startOf('day').toDate();
        const end = moment(date).endOf('day').add(2, 'days').toDate();

        let slots = [];
        const profession_id = filterData.profession.id;
        const speciality_id = filterData.profession.speciality ? filterData.profession.speciality.id : null;
        group.members
            .filter((m) => {
                if (filterData.user) {
                    return m.id === filterData.user.id;
                } else if (filterData.language) {
                    return m.languages.find(l => l.id === filterData.language.id) && m.profession_id === profession_id && m.speciality_id === speciality_id;
                }
                return m.profession_id === profession_id && m.speciality_id === speciality_id;
            })
            .forEach((m) => {
                const userAppointments = appointments.filter((a) => (a.user_id === m.id));

                const newEvents = Calendar.getRangeSlots(m, start, end, userAppointments).filter((c) => {
                    return (
                        c.medical_group_id === group.id &&
                        !c.isDetachedAppointment &&
                        (+values.type !== 1 || !values.clinic || +values.clinic.id === +c.clinic_id) &&
                        (!values.type || (values.type && c.types.indexOf(values.type) !== -1))
                    );
                });

                // Fill the event blocks in selected slots
                if (userAppointments) {
                    newEvents.forEach((slot) => {
                        slot.excluded_times = userAppointments
                            .filter((a) => a.id !== values.id && Calendar.hasAppointmentInOtherSlot(slot, a))
                            .map((a) => ({ ...a, ...{ patient: null } }));
                    });
                }
                slots = [...slots, ...newEvents];
            });

        return slots;
    }

    static getRangeSlots(user, start, end, appointments = null) {
        if (!user) {
            return [];
        }

        const newEvents = [];

        const createEvent = (e, r) => {
            newEvents.push(new Slot({ ...cloneDeep(e), ...{ start: r } }));
        };

        if (user.calendar && user.calendar.slots) {
            user.calendar.slots.forEach((e) => {
                if (e.rrule) {
                    const hours = moment(e.start).hours();
                    const minutes = moment(e.start).minutes();

                    const exdates = e.rrule.exdates().map((d) => moment(d).format('YYYY-MM-DD'));
                    e.rrule
                        .between(start, end, true)
                        .filter((d) => exdates.indexOf(moment(d).format('YYYY-MM-DD')) === -1)
                        .forEach((dt) => createEvent(e, moment(dt).set({ hours, minutes }).toISOString()));
                } else {
                    if (moment(e.start).isBetween(start, end, null, '[]')) {
                        createEvent(e, e.start.toISOString());
                    }
                }
            });
        }

        if (appointments) {
            appointments.forEach((a) => {
                const slot = newEvents.find((box) => this.canFitAppointmentInSlot(box, a));

                if (slot) {
                    if (!slot.appointments) {
                        slot.appointments = [];
                    }
                    slot.appointments.push(a);
                } else {
                    newEvents.push(
                        new Slot({
                            id: -a.id,
                            isDetachedAppointment: true,
                            start: a.start,
                            duration: moment(a.end).diff(moment(a.start), 'minutes'),
                            editable: true,
                            rrule: null,
                            user_id: null,
                            visibility: 0,
                            medical_group_id: a.medical_group_id,
                            clinic_id: a.clinic_id,
                            types: [a.type],
                            status: 1,
                            appointments: [a],
                            blocked: false,
                        })
                    );
                }
            });
        }

        return newEvents;
    }

    static getTimeOptions(events: Array<any>, date, type, reason, user: User): Array<any> {
        const times = [];
        if (events) {
            date = moment(date);
            events.forEach((slot) => {
                if (date.isSame(moment(slot.start), 'day')) {
                    let service;

                    if (!slot.medical_group_id) {
                        service = user.servicesByProfession.find((s) => +s.type === +type && +s.reason === +reason);
                    } else {
                        service = user
                            .findMedicalGroup(slot.medical_group_id)
                            .getMemberServices(user)
                            .find((s) => +s.type === +type && +s.reason === +reason);
                    }

                    if (service) {
                        const end = moment(slot.start).add(slot.duration, 'minutes');
                        const currentDuration = service.duration;
                        const cDStart = moment(slot.start);
                        const currentDateEnd = moment(slot.start).add(currentDuration, 'minutes');
                        const now = new Date();

                        let currentAEnd = null;
                        const temp = [
                            ...(slot.appointments ? slot.appointments : []),
                            ...(slot.excluded_times ? slot.excluded_times : []),
                        ].filter((a) => a.id !== null && !a.isSelected);
                        while (end.diff(currentDateEnd, 'minutes') >= 0) {
                            currentAEnd = moment(cDStart).add(currentDuration, 'minutes');

                            if (
                                cDStart.toDate() > now &&
                                currentAEnd.toDate() > now &&
                                temp.find((a) =>
                                    Utils.isDateRangeInInclusive(cDStart.toDate(), currentAEnd, a.start, a.end)
                                ) === undefined &&
                                currentAEnd.toDate() <= end.toDate() &&
                                !Calendar.isOutOfOffice(user, cDStart)
                            ) {
                                times.push({
                                    hour: cDStart.format('HH:mm'),
                                    clinic: new Clinic(slot.clinic),
                                    slot: [slot],
                                    service: service,
                                });
                            }

                            cDStart.add(currentDuration, 'minutes');
                            currentDateEnd.add(currentDuration, 'minutes');
                        }
                    }
                }
            });
        }
        return times;
    }

    static getGroupTimeOptions(
        events: Array<any>,
        date,
        medicalGroup,
        service
    ): Array<any> {
        const times = [];
        if (events) {
            date = moment(date);
            events.forEach((slot) => {
                if (date.isSame(moment(slot.start), 'day')) {
                    const end = moment(slot.start).add(slot.duration, 'minutes');
                    const currentDuration = service.duration;
                    const cDStart = moment(slot.start);
                    const currentDateEnd = moment(slot.start).add(currentDuration, 'minutes');
                    const now = new Date();

                    let currentAEnd = null;
                    const temp = [
                        ...(slot.appointments ? slot.appointments : []),
                        ...(slot.excluded_times ? slot.excluded_times : []),
                    ].filter((a) => a.id !== null && !a.isSelected);
                    while (end.diff(currentDateEnd, 'minutes') >= 0) {
                        currentAEnd = moment(cDStart).add(currentDuration, 'minutes');

                        if (
                            cDStart.toDate() > now &&
                            currentAEnd.toDate() > now &&
                            temp.find((a) =>
                                Utils.isDateRangeInInclusive(cDStart.toDate(), currentAEnd, a.start, a.end)
                            ) === undefined &&
                            currentAEnd.toDate() <= end.toDate() &&
                            medicalGroup.members.find((m) => !Calendar.isOutOfOffice(m, cDStart))
                        ) {
                            times.push({
                                hour: cDStart.format('HH:mm'),
                                clinic: new Clinic(slot.clinic),
                                slot: [slot],
                                service: service,
                            });
                        }

                        cDStart.add(currentDuration, 'minutes');
                        currentDateEnd.add(currentDuration, 'minutes');
                    }
                }
            });
        }
        return times;
    }

    /**
     * Create blocks with 1 hour interval
     *
     * @param times
     * @return any
     */
    static createBlocksBasedOnTimeOptions(times: Array<any>): any {
        const blocks: Array<any> = [];

        times.forEach((t: any) => {
            const momentHour = moment(t.hour, 'HH:mm');
            const foundedBlock = blocks.find((b) => +b.timeRange.slice(0, 2) === momentHour.hour());

            if (foundedBlock) {
                foundedBlock.availableTimes.push(t);
            } else {
                const start = momentHour.set({ hour: momentHour.hour(), minute: 0 });

                blocks.push({
                    timeRange: `${start.format('HH:mm')} - ${start.add(1, 'hour').format('HH:mm')}`,
                    availableTimes: [t],
                });
            }
        });

        blocks.map((b: any) => {
            if (b.availableTimes) {
                const prices = b.availableTimes.map((at) => at.service.price);
                const currencies = uniq(b.availableTimes.map((at) => at.service.currency));
                const currency = !currencies.length || currencies.length > 1 ? null : currencies[0];

                b.priceRange =
                    Math['min'](...prices) === Math['max'](...prices)
                        ? new CurrencyPipe().transform(Math['min'](...prices), currency)
                        : '[' +
                          new CurrencyPipe().transform(Math['min'](...prices), currency) +
                          '...' +
                          new CurrencyPipe().transform(Math['max'](...prices), currency) +
                          ']';

                return b;
            }
        });

        return blocks;
    }

    static isOutOfOffice(user: User, date) {
        return user.calendar.out_of_offices.find((oo) => {
            return date.isBetween(moment(oo.start_time), moment(oo.end_time), 'minutes', '[]');
        });
    }

    static hasAppointmentInOtherSlot(slot, appointment): boolean {
        return (
            (!slot.appointments ||
                (slot.appointments && slot.appointments.find((sa) => sa.id === appointment.id) === undefined)) &&
            Utils.isDateRangeInInclusive(slot.start, slot.end, appointment.start, appointment.end)
        );
    }

    static canFitAppointmentInSlot(slot, a) {
        return (
            slot.medical_group_id === a.medical_group_id &&
            slot.types.indexOf(a.type) !== -1 &&
            (a.type === 2 || (a.type !== 2 && +a.clinic_id === +slot.clinic_id)) &&
            Utils.isDateRangeIn(slot.start, slot.end, a.start, a.end)
        );
    }
}
