import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthService } from '@app/services';

@Component({
    selector: 'app-two-factor-auth',
    templateUrl: './two-factor-auth.component.html',
    styleUrls: ['./two-factor-auth.component.scss'],
})
export class TwoFactorAuthComponent implements OnInit {
    @ViewChild('stepper', { static: false }) stepper: MatStepper;

    password: string;
    passwordValidated: boolean = false;
    key: string = null;
    type: number;
    qrCode!: SafeHtml;

    constructor(
        private readonly _authService: AuthService,
        private readonly _sanitizer: DomSanitizer,
        private readonly _matDialog: MatDialogRef<TwoFactorAuthComponent>
    ) {}

    ngOnInit(): void {
        this._authService
            .google2StepQrCode()
            .subscribe(({ image, code }: Record<string, any>) => {
                this.qrCode = this._sanitizer.bypassSecurityTrustHtml(image);
                this.key = code;
            });
    }

    close(data: boolean = false): void {
        this._matDialog.close(data);
    }

    onStepChange(index: number): void {
        this.stepper.selectedIndex = index;
    }

    setPassword(password: string): void {
        this.password = password;
    }

    typeChange(type: any): void {
        this.type = type;
    }
}
