<p class="step-2--title mt-2" i18n="2 step authentication step 1|Title@@general.step 2">step 2</p>

<!-- title -->
<div [ngSwitch]="type">
    <p *ngSwitchCase="1" class="step-2--subtitle" i18n="2 step auth|Step 3 paragraphs@@step3.paragraphs.1">
        Scan this code to get the authentication key and insert it below.
    </p>
    <p *ngSwitchCase="2" class="step-2--subtitle" i18n="2 step auth|Step 3 paragraphs@@step3.paragraphs.2">
        Check your mobile device and insert the authentication code below.
    </p>
</div>

<!-- (only) when user choosed 'Text message (SMS)' option -->
<p class="step-2--message" *ngIf="type === 2" i18n="2 step auth|Step 3 paragraphs@@step3.paragraphs.3">
    message sent to {{ userPhone }}
</p>

<div class="row align-items-center no-gutters">
    <div class="col-12 row align-items-center">
        <ng-container [ngSwitch]="type">
            <!-- when user choosed 'Authenticator app' option -->
            <ng-container *ngSwitchCase="1">
                <!-- Left card -->
                <div class="col-12 col-md-6 pl-0 step-2__card">
                    <!-- <img [src]="qrCode" alt="qrcode" class="step-2__card--qrcode"> -->
                    <div [innerHTML]="qrCode" class="step-2__card--qrcode"></div>
                </div>

                <!--Right card -->
                <div class="col-12 col-md-6 pr-0 step-2__card">
                    <div class="step-2__card">
                        <p i18n="2 step auth|Step 3 paragraphs@@step3.paragraphs.insert 6 digit code">insert 6 digit code</p>
                        <input class="w-100" type="text" placeholder="######" maxlength="6" [formControl]="code"
                            (keyup)="codeKeyUp($event)">
                        <mat-error i18n="2 step auth|Step 3 paragraphs@@Invalid code"
                            *ngIf="code.hasError('server') || code.hasError('minlength')">
                            Invalid code
                        </mat-error>
                    </div>
                </div>
            </ng-container>

            <!-- when user choosed 'Text message (SMS)' option -->
            <ng-container *ngSwitchCase="2">
                <div class="col-12 px-0 step-2__card">
                    <p i18n="2 step auth|Step 3 paragraphs@@step3.paragraphs.insert 6 digit code">insert 6 digit code</p>
                    <input type="text" placeholder="######" maxlength="6" [formControl]="code">
                    <mat-error i18n="2 step auth|Step 3 paragraphs@@Invalid code"
                        *ngIf="code.hasError('server') || code.hasError('minlength')">
                        Invalid code
                    </mat-error>
                    <mat-error *ngIf="codeInvalid" class="step-2__card--resend-btn" [ngClass]="disabledResend"
                        (click)="resendOTP()">
                        Resend {{ timerLeft }}
                    </mat-error>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <!-- controls -->
    <div class="col-auto ml-auto">
        <button class="mr-4 primary" mat-button matStepperPrevious (click)="back(2)"
            i18n="2 step auth|Step 3 actions@@general.back">
            back
        </button>
        <button mat-raised-button class="primary" (click)="validate()" i18n="2 step auth|Step 3 actions@@general.validate">
            validate
        </button>
    </div>
</div>