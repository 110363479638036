import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User, Appointment, MedicalGroup } from '@app/models';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root',
})
export class CalendarService {
    constructor(private http: HttpClient, private dataService: DataService) {}

    /**
     * @link api/v1/patient-portal/calendar/professionals
     * @returns
     */
    public getAppointmentProfessionals(): Observable<any> {
        return this.http.get(`${env.API_URL}calendar/professionals`).pipe(
            take(1),
            map((response: any) => {
                return response.data.map((p) => new User(p));
            })
        );
    }

    /**
     * @link api/v1/patient-portal/calendar/professional/{user}
     * @param professionalId
     * @returns
     */
    public getProfessionalCalendar(professionalId: string): Observable<any> {
        return this.http.get(`${env.API_URL}calendar/professional/${professionalId}`).pipe(
            take(1),
            map((response: any) => new User(response.data))
        );
    }

    /**
     * @link api/v1/patient-portal/calendar/medical-group/{medicalGroup}/professional/{user}
     * @param medicalGroupId
     * @param professionalId
     * @returns
     */
    public getAppointmentDoctorInsideGroup(medicalGroupId: string, professionalId: string): Observable<any> {
        return this.http
            .get(`${env.API_URL}calendar/medical-group/${medicalGroupId}/professional/${professionalId}`)
            .pipe(
                take(1),
                map((response: any) => new User(response.data))
            );
    }

    /**
     * @link api/v1/patient-portal/calendar/medical-group/{medicalGroup}/professional/{user}
     * @param medicalGroupId
     * @param professionalId
     * @returns
     */
    public getMedicalGroup(medicalGroupId: string): Observable<any> {
        return this.http.get(`${env.API_URL}calendar/medical-group/${medicalGroupId}`).pipe(
            take(1),
            map((response: any) => new MedicalGroup(response.data))
        );
    }

    /**
     * Get doctor appointments by data range.
     *
     * @link api/v1/patient-portal/calendar/{user}/range
     * @param user (Doctor)
     * @param start (YYYY-MM-DD)
     * @param end (YYYY-MM-DD)
     * @return Observable<any>
     */
    public getUserAppointmentsByDataRange(user: User, start: string, end: string): Observable<any> {
        return this.http.post(`${env.API_URL}calendar/${user.id}/range`, { start, end }).pipe(
            take(1),
            map((response: any) => {
                return response.data.map((a) => new Appointment(a));
            })
        );
    }

    /**
     * Get group members appointments by data range.
     *
     * @link api/v1/patient-portal/calendar/medical-group/{medicalGroup}/range
     * @param user (Doctor)
     * @param start (YYYY-MM-DD)
     * @param end (YYYY-MM-DD)
     * @return Observable<any>
     */
    public getGroupAppointmentsByDataRange(medicalGroup: MedicalGroup, start: string, end: string): Observable<any> {
        return this.http.post(`${env.API_URL}calendar/medical-group/${medicalGroup.id}/range`, { start, end }).pipe(
            take(1),
            map((response: any) => {
                return response.data.map((a) => new Appointment(a));
            })
        );
    }
}
