import { User, Clinic, MedicalGroup } from '@app/models';
import { cloneDeep as _cloneDeep } from 'lodash';
import { Patient } from './Patient';
import * as moment from 'moment';
import { Addendum } from './Addendum';
import { Vital } from './Vital';
import { SnomedItem } from './SnomedItem';
import { Attachement } from './Attachment';
import { GlobalVariable } from '@app/global';
import { PatientFile } from './PatientFile';
import { AppointmentReasonPipe } from '@app/pipes/appointment-reason.pipe';

export class Consultation {
    public static APPOINTMENT_NO_PAYMENT_OPTION = 0;
    public static APPOINTMENT_CREDIT_CARD_REQUIRED = 1;
    public static APPOINTMENT_CREDIT_CARD_OR_INSURANCE = 2;
    public static APPOINTMENT_INSURANCE = 3;

    public static CASH_TYPE = 1;
    public static INSURANCE_TYPE = 2;
    public static STRIPE_TYPE = 3;

    check_in_at: string;
    check_in_status: any;
    clinic: Clinic | null;
    clinic_id: string | number;
    day: string;
    description: string;
    appointment_contact: string;
    user: User;
    professional: User;
    id: string | number;
    user_appointment_id?: string | number;
    patient_file_id?: string | number;
    medical_group: MedicalGroup;
    medical_group_id: string | number;
    patient: Patient;
    patient_confirmed_at: null;
    price: number | string;
    phone: number | string;
    reminder_type: any;
    reminder_time: any;
    reminder: any;
    speciality_id: string | number;
    type: any;
    appointment_type?: any;
    patient_file_type?: any;
    is_appointment?: boolean;
    is_patient_file?: boolean;
    reason: any;
    permission?: any;
    start: Date | string;
    end: Date | string;
    service: any;
    video_id?: string;
    video_joined?: boolean;
    assessment?: any;
    assessment_files?: any;
    is_past_appointment?: boolean;
    deleted_at?: any;
    missing_documents?: any;

    user_id: number;
    patient_file: PatientFile;
    addendums?: Array<Addendum> = [];
    documents?: Array<any>;
    billings?: Array<any>;
    reports?: Array<any>;
    files?: Array<any>;
    summary?: any;
    prescriptions?: Array<any>;
    medical_histories?: Array<any>;
    examinations?: Array<any>;
    vitals?: Vital;
    referral: any;
    referrals?: Array<any>;
    works: Array<any> = [];
    authorized_at: any;
    medical_history_at: any;
    examination_at: any;
    vitals_at: any;
    expires_at: any;
    items?: Array<SnomedItem> | null;
    itemTree?: Array<SnomedItem> | null;
    attachments?: Array<Attachement> | null;
    accepted_terms?: boolean | null;
    meta: any;
    checked: boolean;
    link?: string;
    has_insurances?: boolean;
    has_stripe_integration?: boolean;
    insurance?: any;
    payment_option: number;
    can_collect: boolean;
    has_payment_intent: boolean;
    payment_method: any;
    should_charge: boolean;

    constructor(data, meta = null) {
        Object.assign(this, _cloneDeep(data));

        this.start = moment(this.start).toDate();
        this.end = moment(this.end).toDate();

        this.user = this.user ? new User(this.user) : null;
        this.medical_group = this.medical_group ? new MedicalGroup(this.medical_group) : null;
        this.patient = this.patient ? new Patient(this.patient) : null;

        if (typeof this.reminder === 'string') {
            this.reminder = JSON.parse(this.reminder);
        }

        const formClinic = this.medical_group ? this.medical_group : this.user;
        if (formClinic && formClinic.clinics) {
            this.clinic = new Clinic(
                Object.assign(
                    this.clinic,
                    formClinic.clinics.find((c) => c.id === this.clinic.id)
                )
            );
        }

        this.user = this.user ? new User(this.user) : null;
        this.patient = this.patient ? new Patient(this.patient) : null;
        this.addendums = this.addendums ? this.addendums.map((a) => new Addendum(a)) : [];
        this.patient_file = this.patient_file ? new PatientFile(this.patient_file, null) : null;
        this.meta = meta;
        this.link = this.user_appointment_id
            ? `/consultation/1/${this.user_appointment_id}`
            : `/consultation/2/${this.patient_file_id}`;
    }

    get videoUrl(): string {
        // const params = `#userInfo.displayName="${encodeURIComponent(new NamePipe().transform(this.patient))}"`;
        // return environment.VIDEO_APPOINTMENT_URI + this.video_id + params;
        return `verify/${this.video_id}`;
    }

    get canJoinVideoCall(): boolean {
        return (
            !this.is_past_appointment &&
            (this.check_in_status === 1 || this.check_in_status === 3) &&
            this.appointment_type === 3 &&
            this.video_joined &&
            this.video_id &&
            this.video_id.length > 0
        );
    }

    get isPaymentMissing(): boolean {
        switch (this.payment_option) {
            case Consultation.APPOINTMENT_CREDIT_CARD_REQUIRED:
                // STRIPE_TYPE = 3
                return (!((this.has_payment_intent || (this.payment_method && this.payment_method.type === 3)) && this.can_collect) && this.should_charge && this.has_stripe_integration);
            case Consultation.APPOINTMENT_INSURANCE:
                return !this.insurance;
            case Consultation.APPOINTMENT_CREDIT_CARD_OR_INSURANCE:
                // STRIPE_TYPE = 3
                return (!((this.has_payment_intent || (this.payment_method && this.payment_method.type === 3)) && this.can_collect) && this.has_stripe_integration) && !this.insurance;
        }
        return false;
    }

    /**
     * Missing data sharing
     * Missing accept legal documents
     * Missing payment details (credit card required, insurance required, credit card or insurance required)
     */
    get isOnboardingRequired(): boolean {
        return (
            !this.is_past_appointment &&
            !this.check_in_status &&
            (this.patient_confirmed_at === null || this.missing_documents.length || !this.permission || this.isPaymentMissing)
        );
    }

    get canChangeOnboarding(): boolean {
        return !(
            this.check_in_status !== 0 ||
            (this.patient_file && this.patient_file.authorized_at) ||
            this.is_past_appointment
        );
    }

    get professionalEmail(): string {
        if (this.medical_group) {
            const professionalGroup = this.medical_group.members.find((m) => m.id === this.user.id);

            if (professionalGroup && professionalGroup.work_email && professionalGroup.work_email.status === 1) {
                return professionalGroup.work_email.email;
            } else if (this.medical_group.email && this.medical_group.email_status === 1) {
                return this.medical_group.email;
            } else {
                return this.user.correspondenceEmail;
            }
        }
        return this.user.correspondenceEmail;
    }

    get professionalPhone(): string {
        if (this.medical_group) {
            const professionalGroup = this.medical_group.members.find((m) => m.id === this.user.id);

            if (professionalGroup && professionalGroup.work_phone && professionalGroup.work_phone.status === 1) {
                return professionalGroup.work_phone.phone;
            } else if (this.medical_group.phone) {
                return this.medical_group.phone;
            } else {
                return this.user.mainAndPublicPhone;
            }
        }
        return this.user.mainAndPublicPhone;
    }

    /** Patient file */

    get hasDocuments(): boolean {
        return (
            this.reports?.length > 0 ||
            this.referrals?.length > 0 ||
            this.prescriptions?.length > 0 ||
            this.documents?.length > 0 ||
            this.billings?.length > 0 ||
            this.files?.length > 0
        );
    }

    get serviceType() {
        return GlobalVariable.services[this.appointment_type - 1] ?? '-';
    }

    get titleType() {
        switch (this.patient_file_type) {
            case 2:
                return $localize`:title consultation note|Consultation type:consultation note`;
            case 3:
                return $localize`:title prescription|Consultation type:prescription`;
            case 4:
                return $localize`:title immunisation|Consultation type:immunisation`;
            default:
                return $localize`:title appointment|Consultation type@@general.appointment:appointment`;
        }
    }

    get consultationType() {
        switch (this.patient_file_type) {
            case 2:
                return $localize`:title consultation note|Consultation type:consultation note`;
            case 3:
                return $localize`:title prescription|Consultation type:prescription`;
            case 4:
                return $localize`:title immunisation|Consultation type:immunisation`;
            default:
                return `${this.serviceType}, ${new AppointmentReasonPipe().transform(this.reason)}`;
        }
    }

    find(snomed_id) {
        // return this.itemTree.find(i => +i.snomed_id === +snomed_id);
    }

    cleanData(snomed_id) {
        const currentItem = this.itemTree.find((it) => +it.snomed_id === +snomed_id);
        if (currentItem) {
            currentItem.value = '';
            currentItem.description = '';
            currentItem.items = [];
        }
    }

    setPrevData(snomed_id, data) {
        if (data.itemTree) {
            this.itemTree = this.itemTree.filter((it) => +it.snomed_id !== +snomed_id);
            const prevItem = data.itemTree.find((it) => +it.snomed_id === +snomed_id);
            if (prevItem) {
                this.itemTree.push(this.cleanItem(prevItem));
            }
        }
    }

    private cleanItem(item): SnomedItem {
        item.id = null;
        item.patient_file_item_id = null;
        if (item.items) {
            item.items.forEach((i) => {
                this.cleanItem(i);
            });
        }
        return item;
    }
}
