import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { SafariOptimizedComponent } from './shared/safari-optimized/safari-optimized.component';
import { IosGuard } from './guards/ios.guard';
import { PatientFileIosCheckComponent } from './shared/patient-file-ios-check/patient-file-ios-check.component';
import { BrowserCheckGuard } from './guards/browser-check.guard';
import { RouterErrorHandler } from './classes/handlers/router-error-handler';

const routes: Routes = [
    {
        path: '',
        canActivate: [BrowserCheckGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'login',
                loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
                canActivate: [LoginGuard],
            },
            {
                path: 'settings',
                loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
            },
            {
                path: 'demographics',
                loadChildren: () =>
                    import('./modules/demographics/demographics.module').then((m) => m.DemographicsModule),
            },
            {
                path: 'attachments',
                loadChildren: () => import('./modules/attachments/attachments.module').then((m) => m.AttachmentsModule),
            },
            {
                path: 'patient-confirm',
                loadChildren: () => import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
            },
            {
                path: 'booking',
                loadChildren: () =>
                    import('./modules/appointment-booking/appointment-booking.module').then(
                        (m) => m.AppointmentBookingModule
                    ),
            },
            {
                path: 'account',
                loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule),
            },
            {
                path: 'terms',
                loadChildren: () => import('./modules/terms/terms.module').then((m) => m.TermsModule),
            },
            {
                path: 'safari',
                component: SafariOptimizedComponent,
                canActivate: [IosGuard],
                data: { iosOnly: true },
            },
            {
                path: 'verify/:video-id',
                component: PatientFileIosCheckComponent,
            },
            {
                path: '**',
                redirectTo: '',
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy',
            errorHandler: RouterErrorHandler,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
