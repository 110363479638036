import { Component, Input, ChangeDetectorRef, inject } from '@angular/core';
import { AuthService } from '@app/services';
import { User } from '@app/models';
import { BaseComponent } from '../base/base.component';
import { ENV } from '@app/tokens/env.token';

@Component({
    selector: 'app-thumbnail',
    templateUrl: './thumbnail.component.html',
    styleUrls: ['./thumbnail.component.scss'],
})
export class ThumbnailComponent extends BaseComponent {
    @Input() size = '';
    @Input() user;

    env = inject(ENV);

    showImages = this.env.SHOW_IMAGES;

    constructor(private readonly _authService: AuthService, private readonly _cdr: ChangeDetectorRef) {
        super();
        
        if (this.user) {
            this.user = new User(this.user);
        } else {
            this.subscriptions.add(
                this._authService.userDataChange.subscribe((val: any) => (this.user = new User(val)))
            );
        }
    }

    imageError() {
        this.user.avatar = null;
        this._cdr.detectChanges();
    }
}
