import { User } from '@app/models/User';
import * as _ from 'lodash';

export class MedicalGroup {
    id: number;
    is_admin: number;
    accepted_group: number;
    name: string;
    description: string;
    legal_documents: any[] = [];
    specialities: any[] = [];
    keywords: any[] = [];
    members: any[] = [];
    clinics: any[] = [];
    services: any[] = [];
    professional_services: any[] = [];
    filtered_professional_services: any[] = [];
    selected = false;
    visible = true;
    access_schedule: number;
    access_billing: number;
    access_patient_files: number;
    book_own_appointments: number;
    is_group = true;
    billing_issuer_details: any;
    logo?: string;

    email: string;
    email_status: number;
    phone: string;

    address_line_1: string;
    address_line_2: string;
    postal_code: string;
    local_authority: string;
    region: string;
    city: string;
    country: string;

    constructor(obj) {
        Object.assign(this, _.cloneDeep(obj));

        this.members = (this.members && this.members.map((d) => new User(d))) || [];
    }

    get thumbnail() {
        return this.name ? 'letter-' + this.name[0].toLowerCase() + '-' + this.name[0].toLowerCase() : '';
    }

    get professions() {
        return this.members.map((m) => m.profession ?? null).filter((p) => p);
    }

    getServices(profession_id, speciality_id = null) {
        return this.services.filter(
            (s) => +s.profession_id === +profession_id && (!speciality_id || speciality_id === s.speciality_id)
        );
    }

    getService(profession, type = null, reason = null) {
        const profession_id = profession.id;
        const speciality_id = profession.speciality ? profession.speciality.id : null;
        return this.services.find(
            (s) =>
                s.profession_id === profession_id &&
                s.speciality_id === speciality_id &&
                +s.type === +type &&
                +s.reason === +reason
        );
    }

    getMemberServices(user: User) {
        const member = this.members.find((u) => u.id === user.id);
        return member ? this.getServices(member.profession_id, member.speciality_id) : [];
    }

    getServicesBySpeciality(profession) {
        const isProfessional = profession.is_professional;
        const profession_id = profession.id;
        const speciality_id = profession.speciality?.id;

        return this.services.filter((s) => {
            if (isProfessional) {
                return s.profession_id === profession_id && s.speciality_id === speciality_id;
            } else {
                return s.profession_id === profession_id;
            }
        });
    }

    getSlotsBySpeciality(profession) {
        const isProfessional = profession.is_professional;
        const profession_id = profession.id;
        const speciality_id = profession.speciality?.id;

        return _.flatten(
            this.members
                .filter((user: User) => {
                    if (isProfessional) {
                        return user.speciality_id === speciality_id && user.profession_id === profession_id;
                    } else {
                        return user.profession_id === profession_id;
                    }
                })
                .map((user: User) => {
                    return user.calendar.slots;
                })
        );
    }

    getSlotsByUser(user: User) {
        return user.calendar.slots.filter((s) => s.medical_group_id === this.id);
    }
}
