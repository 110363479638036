import { Pipe, PipeTransform } from '@angular/core';
import { TitlePipe } from '@app/pipes/title.pipe';

@Pipe({
    name: 'name',
})
export class NamePipe implements PipeTransform {
    transform(value: any, hasTitle = true, defaultValue: string = ''): any {
        if (!value || !value.first_name) {
            return defaultValue;
        }
        const title = hasTitle ? new TitlePipe().transform(value?.title) : '';

        return value
            ? `${title ? title + ' ' : ''}${value.first_name + ' ' + (value.last_name ? value.last_name : '')}`
            : $localize`:user no longer available|user name@@name pipe.user removed:User removed`;
    }
}
