import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Consultation } from '@app/models';
import moment from 'moment';
import { GlobalVariable } from '@app/global';

@Injectable({
    providedIn: 'root',
})
export class ConsultationsService {
    private consultationsDataChange: BehaviorSubject<Consultation[]> = new BehaviorSubject<Consultation[]>([]);
    private upcommingDataChange: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private pastDataChange: BehaviorSubject<any> = new BehaviorSubject<any>({});

    constructor(private readonly http: HttpClient) {}

    /**
     * Make the url.
     *
     * @param uri
     * @return string
     */
    private _makeUrl(uri: string = ''): string {
        return `${env.API_URL}consultations${uri}`;
    }

    get consultations() {
        return this.consultationsDataChange.value;
    }

    get upcoming() {
        return this.upcommingDataChange.value;
    }

    get past() {
        return this.pastDataChange.value;
    }

    public getUpcomingAppointment(): Observable<any> {
        return this.http.get(this._makeUrl('/upcoming')).pipe(
            map((res: any) => {
                const appointments = res.data.map((a) => new Consultation(a));
                this.consultationsDataChange.next(appointments);
                this.upcommingDataChange.next(this._mergeData(appointments, this.upcoming));
                return res;
            })
        );
    }

    public getConsultations(page): Observable<any> {
        const now = moment().subtract(GlobalVariable.UPCOMING_APPOINTMENTS_INTERVAL, 'hours');

        return this.http.post(this._makeUrl(), { page }).pipe(
            map((res: any) => {
                const consultations = res.data.map((a) => new Consultation(a));
                this._updateDayWithTimezone(consultations);
                const upcoming = [];

                const past = consultations.filter(
                    (item) =>
                        !(
                            moment(item.start).diff(now, 'minutes') > 0 &&
                            // && !item.check_in_status
                            !item.deleted_at &&
                            item.user_appointment_id &&
                            upcoming.push(item)
                        )
                );

                upcoming.reverse();
                this.consultationsDataChange.next(consultations);
                this.upcommingDataChange.next(this._mergeData(upcoming, this.upcoming));
                this.pastDataChange.next(this._mergeData(past, this.past));

                return { upcoming: Object.values(this.upcoming), past: Object.values(this.past), meta: res.meta };
            })
        );
    }

    private _updateDayWithTimezone(consultations: Array<Consultation>): Array<Consultation> {
        return consultations.map((cons: any) => {
            cons.day = moment(cons.start).format('YYYY-MM-DD');
            return cons;
        });
    }

    public getConsultation(type, consultationId) {
        return this.http.get(this._makeUrl(`/${type}/${consultationId}`)).pipe(
            map((res: any) => {
                return new Consultation(res.data);
            })
        );
    }

    public clearConsultations() {
        this.consultationsDataChange.next([]);
        this.upcommingDataChange.next([]);
        this.pastDataChange.next([]);
    }

    public updateConsultations(data: Array<any> = []) {
        this.consultationsDataChange.next(data);
    }

    /**
     * Merge data by day
     *
     * @param data
     * @param consultationsByDay
     */
    private _mergeData(data, consultationsByDay = []) {
        data.forEach((a) => {
            if (!consultationsByDay[a.day]) {
                consultationsByDay[a.day] = { day: a.day, consultations: [] };
            }
            consultationsByDay[a.day].consultations.push(a);
        });

        return consultationsByDay;
    }
}
