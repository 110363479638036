import { AppInjector } from '@app/app.module';
import { AddressPipe } from '@app/pipes/address.pipe';
import { EscapePipe } from '@app/pipes/escape.pipe';
import { ENV } from '@app/tokens/env.token';
import { cloneDeep } from 'lodash';

export class Clinic {
    env: any = AppInjector.get(ENV);

    id: number;
    name: string;
    alias: null;
    phone_number: string;
    email?: string;
    website?: string;
    how_to?: string;
    what_to?: string;
    custom_phone?: string;
    address: any;
    main?: number | boolean;
    in_profile?: number | boolean;

    constructor(obj) {
        Object.assign(this, cloneDeep(obj));
    }

    get mapSearch(): string {
        return `https://www.google.com/maps/search/${new AddressPipe().transform(this.address, ' ')}`;
    }

    /**
     * Generate the Google Maps Url.
     *
     * @param width
     * @param height
     * @return string
     */
    mapUrl(width: number = 520, height: number = 595): string {
        const address: string = new EscapePipe().transform(Object.values(this.address).join(' '));
        return `https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=14&size=${width}x${height}&markers=size:mid%7Ccolor:red%7C${address}&key=${this.env.GOOGLE_MAPS_STATIC_KEY}`;
    }
}
