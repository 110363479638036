import { UntypedFormGroup, AbstractControl, ValidatorFn, AbstractControlOptions, AsyncValidatorFn } from '@angular/forms';

export class FmFormGroup extends UntypedFormGroup {

    private submittingTimeout;

    constructor(controls: {
        [key: string]: AbstractControl;
    }, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super(controls, validatorOrOpts, asyncValidator);
    }

    invalidControls(): Array<any> {
        const invalid = [];
        const controls = this.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    validateFormGroup(callback = null): void {
        this.markAllAsTouched();
        const controls = this.controls;
        // tslint:disable-next-line: forin
        for (const name in controls) {
            controls[name].markAsDirty();
            controls[name].updateValueAndValidity({ emitEvent: false });
        }

        if (this.invalid) {
            this.scrollIntoInvalidControl();
        }

        if (callback) {
            this._waitAndSubmit(callback);
        }
    }

    private _waitAndSubmit = (callback) => {
        if (this.submittingTimeout) {
            clearTimeout(this.submittingTimeout);
        }
        if (this.pending) {
            this.submittingTimeout = setTimeout(this._waitAndSubmit.bind(this, callback), 50);
        } else {
            callback.call(this);
        }
    }

    private scrollIntoInvalidControl(): void {
        const allInputs: NodeList = document.querySelectorAll('input');

        if (allInputs.length) {
            const invalidInput: any = Array.from(allInputs).find((node: any) => node.className.includes('ng-invalid'));

            if (invalidInput) {
                invalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }
}
