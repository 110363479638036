import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as env } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class AppointmentsService {
    private alreadyChoosedPatientDataChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private selectedPaymentMethodDataChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private readonly http: HttpClient) {}

    get alreadyChoosePatient(): boolean {
        return this.alreadyChoosedPatientDataChange.value;
    }

    set alreadyChoosePatient(decision: boolean) {
        this.alreadyChoosedPatientDataChange.next(decision);
    }

    get selectedPaymentMethod(): any {
        return this.selectedPaymentMethodDataChange.value;
    }

    set selectedPaymentMethod(payment) {
        this.selectedPaymentMethodDataChange.next(payment);
    }

    /**
     * Make the url.
     *
     * @param uri
     */
    private _makeUrl(uri: string = ''): string {
        return `${env.API_URL}appointments${uri}`;
    }

    /**
     * @link api/v1/patient-portal/appointments/{user}/create
     * @param userId
     * @param data
     * @returns
     */
    createAppointment(userId: string, data): Observable<any> {
        return this.http.post(this._makeUrl(`/${userId}/create`), data);
    }

    /**
     * @link api/v1/patient-portal/appointments/{appointmentTokenWithoutExpire}/resend/sms
     * @param appointmentTokenWithoutExpire
     * @returns
     */
    public resendAppointmentSms(appointmentTokenWithoutExpire: string): Observable<any> {
        return this.http.get(this._makeUrl(`/${appointmentTokenWithoutExpire}/resend/sms`));
    }

    /**
     * @link api/v1/patient-portal/appointments/{appointmentTokenWithoutExpire}/resend/email
     * @param appointmentTokenWithoutExpire
     * @returns
     */
    public resendAppointmentEmail(appointmentTokenWithoutExpire: string): Observable<any> {
        return this.http.get(this._makeUrl(`/${appointmentTokenWithoutExpire}/resend/email`));
    }

    /**
     * Download assessment.
     *
     * @link api/v1/patient-portal/appointments/{appointment}download/assessment/{assessment}
     * @param appointmentId
     * @param fileId
     * @returns Observable<any>
     */
    downloadAssessment(appointmentId, fileId: any): Observable<any> {
        return this.http.get(this._makeUrl(`/${appointmentId}/download/assessment/${fileId}`));
    }

    /**
     * @link api/v1/patient-portal/appointments/{appointment}/assessment/update
     * @param appointmentId
     * @param data
     * @returns
     */
    public updatePreAssessmentFileAppointment(appointmentId: any, data: any): Observable<any> {
        return this.http.post(this._makeUrl(`/${appointmentId}/assessment/update`), data);
    }

    /**
     * @link api/v1/patient-portal/appointments/{appointment}/payment-method/update
     * @param appointmentId
     * @param data
     * @returns
     */
    public updateAppointmentPaymentMethod(appointmentId: any, data: any): Observable<any> {
        return this.http.post(this._makeUrl(`/${appointmentId}/payment-method/update`), data);
    }

    /** ONBOARDING */

    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/validate
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/validate
     * @param token
     * @returns
     */
    public validateAppointment(onboardingUrl: string): Observable<any> {
        return this.http.get(this._makeUrl(`${onboardingUrl}/validate`));
    }

    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/set-sharing-options
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/set-sharing-options
     * @param onboardingUrl
     * @param data
     * @returns
     */
    public setSharingOptions(onboardingUrl, data) {
        return this.http.post(this._makeUrl(`${onboardingUrl}/set-sharing-options`), data);
    }

    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/set-sharing-options
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/set-sharing-options
     * @param onboardingUrl
     * @param data
     * @returns
     */
    public setPaymentMethods(onboardingUrl, data) {
        return this.http.post(this._makeUrl(`${onboardingUrl}/set-payment-methods`), data);
    }

    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/accept
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/accept
     * @param onboardingUrl
     * @param data
     * @returns
     */
    public acceptAppointment(onboardingUrl, data) {
        return this.http.post(this._makeUrl(`${onboardingUrl}/accept`), data);
    }

    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/set-password
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/set-password
     * @param onboardingUrl
     * @param data
     * @returns
     */
     public setPassword(onboardingUrl, data) {
        return this.http.post(this._makeUrl(`${onboardingUrl}/set-password`), data);
    }

    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/patient-insurance/save
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/patient-insurance/save
     * @param onboardingUrl
     * @param data
     * @returns
     */
    public savePatientInsurance(onboardingUrl, data) {
        return this.http.post(this._makeUrl(`${onboardingUrl}/patient-insurance/save`), data);
    }

    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/assessment/upload
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/assessment/upload
     * @param onboardingUrl
     * @param type
     * @param file_name
     * @returns
     */
    public uploadPreAssessmentFile(onboardingUrl, type: string, file_name: string): Observable<any> {
        return this.http.post(this._makeUrl(`${onboardingUrl}/assessment/upload`), { type, file_name });
    }

    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/intent
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/intent
     * @param onboardingUrl
     * @param data
     * @returns
     */
    public patientIntent(onboardingUrl) {
        return this.http.get(this._makeUrl(`${onboardingUrl}/intent`));
    }


    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/methods
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/methods
     * @param token
     * @returns
     */
    public patientMethods(onboardingUrl): Observable<any> {
        return this.http.get(this._makeUrl(`${onboardingUrl}/methods`));
    }

    /**
     * @link api/v1/patient-portal/appointments/tokenonboarding/{appointmentToken}/methods/remove/{methodId}
     * @link api/v1/patient-portal/appointments/onboarding/{appointment}/methods/remove/{methodId}
     * @param token
     * @param method
     * @returns
     */
    public removePaymentMethod(onboardingUrl: string, method: any): Observable<any> {
        return this.http.get(this._makeUrl(`${onboardingUrl}/methods/remove/${method.id}`));
    }
}
