import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-sessions-expired',
    templateUrl: './sessions-expired.component.html',
    styleUrls: ['./sessions-expired.component.scss']
})
export class SessionsExpiredComponent {

    constructor(
        public readonly dialog: MatDialogRef<SessionsExpiredComponent>,
    ) { }

    login(): void {
        localStorage.removeItem('access_token');
        this.dialog.close(true);
    }
}
