import { AbstractControl } from '@angular/forms';

// export const PASSWORD_PATTERN = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[:;,_<>{}!@#\$%\^&\*\+\.\(\)\-\?\~\|\/\"\'\`\\]\\[])|(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[:;,_<>{}!@#\$%\^&\*\+\.\(\)\-\?\~\|\/\"\'\`\\]\\[])|(?=.*[A-Z])(?=.*[:;,_<>{}!@#\$%\^&\*\+\.\(\)\-\?\~\|\/\"\'\`\\]\\[]))(?=.{8,})/;
export const PASSWORD_PATTERN = /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;

export function strongPasswordValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && !PASSWORD_PATTERN.test(control.value)) {
        return { weakpassword: true };
    }
    return null;
}
