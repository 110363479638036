<div class="fm-centered-popup-backdrop"></div>
<div class="fm-centered-popup">
    <div class="row flex-column align-items-center justify-content-center">
        <div class="col-auto mb-3">
            <h1 i18n="Page title|Session expired page@@common.session-expired.your session has expired">
                Your session has expired.
            </h1>
        </div>
        <div class="w100"></div>
        <div class="col-auto">
            <button
                mat-raised-button
                (click)="login()"
                i18n="Link to login page|Session expired page@@common.session-expired.please log in"
            >
                please, log in
            </button>
        </div>
    </div>
</div>
