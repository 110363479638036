import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private readonly _http: HttpClient) {}

    private _makeUrl(uri: string = ''): string {
        return `${env.API_URL}me${uri}`;
    }

    /**
     * @link api/v1/patient-portal/me/avatar
     * @returns
     */
    avatar(): Observable<any> {
        return this._http.get(this._makeUrl(`/avatar`));
    }

    /**
     * @link api/v1/patient-portal/me/avatar
     * @returns
     */
    updateAvatar(image: any): Observable<any> {
        return this._http.post(this._makeUrl(`/avatar`), { image: image });
    }

    /**
     *
     * @param image
     * @returns
     */
    updateCover(image: any): Observable<any> {
        return this._http.post(this._makeUrl(`/cover`), { image: image });
    }

    updateLogo(image: any): Observable<any> {
        return this._http.post(this._makeUrl(`/logo`), { image: image });
    }
}
