<div class="row align-items-center mt-5">
    <h1 class="col-12 mt-4"
        i18n="site header|Title of the page@@auth.login.welcome to patient portal or recover password">
        {isRecovering, select, true {Recover password} false {Welcome to patient portal}}
    </h1>

    <div class="col-12 col-md-6 px-0" [formGroup]="loginForm">
        <ng-container [ngSwitch]="step">
            <ng-container *ngIf="authData && isStepAbleToSeeInfo">
                <!-- Phone number was used for the login -->
                <ng-container *ngIf="authMethod === 'phone'">
                    <div class="col-12 mb-2 step-link" (click)="step = 'phone'; isRecovering = false">
                        {{ authData.phone }}
                    </div>
                </ng-container>

                <!-- Email was used for the login -->
                <ng-container *ngIf="authMethod === 'email'">
                    <div class="col-12 mb-2 step-link" (click)="step = 'email'; isRecovering = false">
                        {{ authData.email }}
                    </div>
                </ng-container>
            </ng-container>

            <!-- Email step -->
            <ng-container *ngSwitchCase="'email'">
                <ng-container *ngIf="isRecovering && obfuscatedData.email">
                    <div class="col-12 mt-2 mb-4"
                        i18n="Recovery e-mail|login form@@auth.login.your recovery e-mail address">
                        Your recovery e-mail address is {{ obfuscatedData.email }}
                    </div>
                </ng-container>

                <mat-form-field class="col-12">
                    <input [autofocus] type="email" matInput
                        i18n-placeholder="E-mail input placeholder|login form@@auth.login.e-mail account"
                        placeholder="e-mail account" formControlName="email" (keyup.enter)="pressEnter($event)" />
                    <mat-error *ngIf="loginForm.get('email').hasError('required')"
                        i18n="E-mail required error|login form@@general.e-mail is required">
                        e-mail is
                        <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('email').hasError('email')"
                        i18n="E-mail invalid error|login form@@general.e-mail is invalid">
                        e-mail is
                        <strong>invalid</strong>
                    </mat-error>

                    <!-- @TODO: Translate in backend -->
                    <mat-error *ngIf="loginForm.get('email').hasError('server')">
                        {{ loginForm.get('email').getError('server').message }}
                    </mat-error>
                </mat-form-field>

                <div class="col-12 mt-1">
                    <span class="link" (click)="step = 'phone'; authMethod = 'phone'"
                        i18n="Switch to phone number input|login form@@auth.login.or you can use your phone">
                        or you can use your phone
                    </span>
                </div>
            </ng-container>

            <!-- Phone step -->
            <ng-container *ngSwitchCase="'phone'">
                <ng-container *ngIf="isRecovering && shouldDisplayRecoveryPhone && obfuscatedData.phone">
                    <div class="col-12 mt-2 mb-4" i18n="Verify login|login form@@auth.login.send a verification code">
                        Send a verification code to your phone number {{ obfuscatedData.phone }}
                    </div>
                </ng-container>

                <div class="col-12">
                    <app-country-phone #countryPhone [country]="loginForm.get('country_phone')"
                        [phone]="loginForm.get('phone')" [autofocus]="true">
                    </app-country-phone>
                </div>
                <div class="col pr-0">
                    <span class="link" (click)="step = 'email'; authMethod = 'email'"
                        i18n="Switch to e-mail input|login form@@auth.login.or you can use your e-mail">
                        or you can use your e-mail
                    </span>
                </div>
            </ng-container>

            <!-- Password step -->
            <ng-container *ngSwitchCase="'password'">
                <mat-form-field class="col-12 my-2">
                    <input [autofocus] [type]="hidePassword ? 'password' : 'text'" matInput placeholder="password"
                        i18n-placeholder="Password input placeholder|login form@@general.password"
                        formControlName="password" (keyup.enter)="pressEnter($event)" />

                    <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
                        <mat-icon>
                            {{ hidePassword ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                    </button>

                    <mat-error *ngIf="loginForm.get('password').hasError('required')"
                        i18n="Password required error|login form@@general.password is required">
                        password is
                        <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('password').hasError('server')">
                        {{ loginForm.get('password').getError('server').message }}
                    </mat-error>
                </mat-form-field>

                <div class="col-12 mt-1">
                    <span class="link" (click)="forgotPassword()"
                        i18n="Link to recover password|login form@@auth.login.forgot your password">
                        Forgot your password?
                    </span>
                </div>
            </ng-container>

            <!-- Two step authentication -->
            <ng-container *ngSwitchCase="'code'">
                <ng-container *ngIf="otpMethod === 'sms' && obfuscatedData.phone; else otpQrCode">
                    <div class="col-12 mt-2 mb-2 text-xsmall"
                        i18n="Message about verification code|login form@@auth.login.we sent a verification code">
                        We sent a verification code to your phone number {{ obfuscatedData.phone }}
                    </div>
                </ng-container>

                <ng-template #otpQrCode>
                    <div class="col-12 mt-2 mb-2 text-xsmall"
                        i18n="
                            Message about authentication app|login form@@auth.login.please check your authenticator app">
                        Please check your authenticator app in order to view your authentication code
                    </div>
                </ng-template>

                <mat-form-field class="col-12 my-2">
                    <input [autofocus] type="text" matInput placeholder="two step authentication code" i18n-placeholder="
                            Two step authentication code input placeholder|login form@@auth.login.two step
                            authentication code" formControlName="code" (keyup.enter)="pressEnter($event)" />
                    <mat-error *ngIf="loginForm.get('code').hasError('required')" i18n="
                            Authentication code required error|login form@@auth.login.two step authentication code is
                            required">
                        two step authentication code is
                        <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('code').hasError('server')">
                        {{ loginForm.get('code').getError('server').message }}
                    </mat-error>
                </mat-form-field>

                <div class="col-12 mt-1">
                    <span *ngIf="otpMethod === 'sms'" class="link" (click)="resendValidationCode()"
                        i18n="Link to resend validation code|login form@@auth.login.resend validation code">
                        Resend validation code
                    </span>
                </div>
            </ng-container>

            <!-- Recover password message -->
            <ng-container *ngSwitchCase="'recover-password-message'">
                <div class="col-12 mt-4"
                    i18n="Message about password reset e-mail|login form@@auth.login.password reset message was sent">
                    A password reset message was sent to your e-mail address. If you do not receive the message shortly,
                    please check your spam folder or contact us at support@freddiemed.com.
                </div>

                <div class="col-12 mt-4">
                    <span class="link" (click)="isRecovering = false; step = authMethod"
                        i18n="Link to switch to login page|login form@@auth.login.go to login page">
                        Go to login page
                    </span>
                </div>
            </ng-container>

            <!-- Missing platform documents -->
            <!-- <ng-container *ngSwitchCase="'missing-platform-documents'">
                <app-legal-documents [importedComponent]="true"
                    (finishedAccepting)="doneMissingInfoStep('missing-platform-documents')">
                </app-legal-documents>
            </ng-container> -->

            <!-- Missing validate contacts -->
            <ng-container *ngSwitchCase="'missing-validate-contacts'">
                <form [formGroup]="validateContactsForm">
                    <div class="col-12 mt-4 mb-4"
                        i18n="Message to verify contacts|login form@@general.please verify your contacts">
                        Please verify your contacts
                    </div>

                    <!-- Email confirmation -->
                    <ng-container *ngIf="
                            loginResponse &&
                            loginResponse.missing_validate_contacts.email &&
                            !loginResponse.missing_validate_contacts.phone
                        ">
                        <div class="col-12 mt-2 mb-4"
                            i18n="Message about confirmation e-mail|login form@@general.sent confirmation e-mail to">
                            We sent an confirmation e-mail to:
                            <strong>{{ missing_validate_contacts_email }}</strong>

                            <ng-container *ngTemplateOutlet="wrong_contact"></ng-container>
                        </div>

                        <mat-form-field class="col-12">
                            <input matInput placeholder="e-mail confirmation code" i18n-placeholder="
                                    E-mail confirmation code input placeholder|login
                                    form@@general.e-mail_confirmation_code" formControlName="email_token"
                                (keyup.enter)="pressEnter($event)" />
                            <mat-error *ngIf="validateContactsForm.get('email_token').hasError('required')"
                                i18n="
                                    E-mail confirmation code required error|login form@@general.e-mail_confirmation_code_required">
                                e-mail confirmation code is
                                <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="validateContactsForm.get('email_token').hasError('server')">
                                {{ validateContactsForm.get('email_token').getError('server').message }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>

                    <!-- Phone confirmation -->
                    <ng-container *ngIf="loginResponse && loginResponse.missing_validate_contacts.phone">
                        <div class="col-12 mt-2 mb-4"
                            i18n="Message about confirmation sms|login form@@auth.login.we sent an confirmation sms">
                            We sent an confirmation sms to:
                            <strong>
                                {{ loginResponse.missing_validate_contacts.phone.phone_number }}
                            </strong>

                            <ng-container *ngTemplateOutlet="wrong_contact"></ng-container>
                        </div>

                        <mat-form-field class="col-12">
                            <input matInput placeholder="phone confirmation code" i18n-placeholder="
                                    Phone confirmation code input placeholder|login form@@general.phone confirmation
                                    code" formControlName="phone_token" (keyup.enter)="pressEnter($event)" />
                            <mat-error *ngIf="validateContactsForm.get('phone_token').hasError('required')" i18n="
                                    Phone confirmation code required error|login form@@general.phone confirmation code
                                    is required">
                                phone confirmation code is
                                <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="validateContactsForm.get('phone_token').hasError('server')">
                                {{ validateContactsForm.get('phone_token').getError('server').message }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>

                    <div class="col-12 mt-2 mb-4" *ngIf="loginResponse && displayResendCodesBtn">
                        <a href="#"
                            (click)="resendContactTokens($event, loginResponse.missing_validate_contacts?.phone ? 'phone' : 'email')"
                            i18n="link to resend code|login form@@general.didnt_get_the_code">
                            Didn't get the code? Click here to resend.
                        </a>
                    </div>

                    <ng-template #wrong_contact>
                        <ng-container *ngIf="shouldRedirect; else skipOption">
                            <p>
                                <a href="#" (click)="update_contact($event)" class="wrong_contact"
                                    i18n="link to update contact|login form@@general.dont_recognize_contact">
                                    Don't recognize this contact? Click here to update.</a>
                            </p>
                        </ng-container>

                        <ng-template #skipOption>
                            <p>
                                <a href="#" (click)="skipStep($event)" class="wrong_contact"
                                    i18n="skip this step for now|login form@@general.skip_this_step_confirm_later">
                                    Skip this step for now and confirm later.</a>
                            </p>
                        </ng-template>                        
                    </ng-template>


                    <!-- Validate button -->
                    <div class="d-flex justify-content-end mt-3">
                        <button type="button" class="mat-button action-button" mat-raised-button
                            [disabled]="validateContactsForm.invalid" (click)="validateMissingContacts()"
                            i18n="Validate button|login form@@general.validate">
                            validate
                        </button>
                    </div>
                </form>
            </ng-container>
        </ng-container>

        <!-- Next button -->
        <div class="d-flex justify-content-end mt-4" *ngIf="isStepAbleToSeeInfo">
            <button type="button" class="mat-button action-button" mat-raised-button [disabled]="isStepInvalid"
                (click)="nextStep()" i18n="Action buttons|next button text@@next">
                next
            </button>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <!-- Illustration -->
        <img class="illustration" alt="Freddie Med Illustration"
            src="./assets/img/illustrations/Doctor_Computer.svg" />
    </div>
</div>