<button *ngIf="!isDefault" id="close-btn" (click)="close()">
    <mat-icon>close</mat-icon>
</button>
<h2 mat-dialog-title>
    <ng-container i18n="Page title|Switch user@@switch-user.title"
        >Select {isDefault, select, true {default } false { }}patient</ng-container
    >

    <ng-container *ngIf="isDefault">
        <br />
        <small i18n="update message|switch user@@switch-user.this can be updated">
            this can be updated in your account settings
        </small>
    </ng-container>
</h2>

<mat-dialog-content class="row no-gutters">
    <div class="col-12 row align-items-center mb-3 user" *ngFor="let user of users" (click)="selectUser(user.id)">
        <div class="col-auto px-0">
            <app-thumbnail [size]="'small'" [user]="user"></app-thumbnail>
        </div>
        <div class="col name">
            <span>{{ user.title | title }}</span>
            <br *ngIf="user.title" />
            <span>{{ user | name: false }}</span>
        </div>
    </div>
</mat-dialog-content>
