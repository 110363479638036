<h1 class="col-12 mt-4" i18n="Page title|Reset password@@auth.reset-password.reset password">Reset password.</h1>

<!-- Token is set in the Url but it's invalid -->
<ng-container *ngIf="token && invalidToken">
    <div class="col-12" i18n="Invalid code message|Reset password@@auth.reset-password.the provided code is being used">
        The provided code is being used or expired.
    </div>
</ng-container>

<div *ngIf="!invalidToken" [formGroup]="resetForm">
    <!-- Token -->
    <div class="row">
        <mat-form-field *ngIf="!token" class="col-12">
            <input
                matInput
                placeholder="verification code sent via SMS"
                i18n-placeholder="
                    Verification code input placeholder|Reset password@@auth.reset-password.verification code sent via
                    SMS"
                formControlName="token"
                required
            />
            <mat-error
                *ngIf="resetForm.controls.token.hasError('required')"
                i18n="
                    Verification code input required error|Reset password@@auth.reset-password.verification code is
                    required"
            >
                verification code is
                <strong>required</strong>
            </mat-error>
            <mat-error
                *ngIf="resetForm.controls.token.hasError('minlength')"
                i18n="
                    Verification code input invalid length error|Reset password@@auth.reset-password.please insert the
                    code sent to you"
            >
                please insert the
                <strong>6 digit</strong>
                code sent to you via SMS
            </mat-error>
            <mat-error *ngIf="resetForm.controls.token.hasError('server')">
                {{ resetForm.controls.token.getError('server').message }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- New password -->
    <div class="row">
        <mat-form-field class="col-12">
            <input
                matInput
                placeholder="new password"
                i18n-placeholder="New password input placeholder|Reset password@@auth.reset-password.new password"
                type="password"
                formControlName="password"
                required
            />

            <mat-error
                *ngIf="resetForm.controls.password.hasError('weakpassword')"
                i18n="New password input weak error|Reset password@@general.weak password error"
            >
                password must contain 3 of 4 (lowercase char, uppercase char, number, special char)
            </mat-error>

            <mat-error
                *ngIf="resetForm.controls.password.hasError('required')"
                i18n="New password input required error|Reset password@@general.password is required"
            >
                password is
                <strong>required</strong>
            </mat-error>

            <mat-error
                *ngIf="resetForm.controls.password.hasError('minlength')"
                i18n="
                    New password input invalid length error|Reset
                    password@@general.password_must_have_at_least_x_characters"
            >
                password must have <strong>at least {{ minLength }}</strong> characters
            </mat-error>

            <mat-error *ngIf="resetForm.controls.password.hasError('server')">
                {{ resetForm.controls.password.getError('server').message }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Confirm new password -->
    <div class="row">
        <mat-form-field class="col-12">
            <input
                matInput
                placeholder="confirm new password"
                i18n-placeholder="Confirm new password input|Reset password@@auth.reset-password.confirm new password"
                type="password"
                formControlName="password_confirmation"
                [errorStateMatcher]="passwordMatcher"
                required
            />
            <mat-error
                *ngIf="resetForm.controls.password_confirmation.hasError('required')"
                i18n="
                    Confirm new password input required error|Reset password@@auth.reset-password.password confirmation
                    is required"
            >
                password confirmation is
                <strong>required</strong>
            </mat-error>
            <mat-error
                *ngIf="resetForm.controls.password_confirmation.hasError('minlength')"
                i18n="
                    Confirm new password input invalid length error|Reset
                    password@@general.password_must_have_at_least_x_characters"
            >
                password must have <strong>at least {{ minLength }}</strong> characters
            </mat-error>
            <mat-error
                *ngIf="resetForm.hasError('passwordConfirmation')"
                i18n="
                    Confirm new password input different passwords error|Reset password@@general.passwords do not match"
            >
                passwords
                <strong>do not match</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Reset button -->
    <div class="row">
        <div class="col-auto ml-auto">
            <button
                mat-button
                [disabled]="resetForm.invalid"
                (click)="submit()"
                i18n="Confirm button|Reset password@@auth.reset-password.reset password button"
            >
                reset password
            </button>
        </div>
    </div>
</div>
