import { User } from '@app/models/User';

export class Addendum {
    id: number;
    editor: User;
    form: number;
    message: string;
    created_at: Date;
    updated_at: Date;

    constructor(obj: any) {
        Object.assign(this, obj);
        this.editor = this.editor ? new User(this.editor) : null;
    }
}
