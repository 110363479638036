import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { StripeCard } from '@app/models/StripeCard';
import { ENV } from '@app/tokens/env.token';
import { environment } from '@env/environment';

declare var Stripe: (stripePublicKey: string) => void;

@Injectable({
    providedIn: 'root',
})
export class StripeService {
    env = inject(ENV);

    constructor(private readonly _http: HttpClient) {}

    init(): void {
        (window as any).Stripe = Stripe(this.env.STRIPE_PUBLIC_KEY);
    }

    /**
     * Get payment intent.
     *
     * @link
     * @return {Observable<any>}
     */
    getIntent(): Observable<any> {
        return this._http.get(this._makeUrl('/intent')).pipe(take(1));
    }

    /**
     * List credit cards.
     * 
     * @link 
     * @return {Observable<any>}
     */
    getCreditCards(): Observable<any> {
        return this._http.get(this._makeUrl('/payment-methods')).pipe(map((res: any) => res.data));
    }

    /**
     * Delete card.
     * 
     * @link
     * @param {StripeCard} card 
     * @return {Observable<any>}
     */
    deleteCreditCard(card: StripeCard): Observable<any> {
        return this._http.post(this._makeUrl(`/remove-card/${card.id}`), card).pipe(take(1));
    }

    private _makeUrl(uri: string = ''): string {
        return `${environment.API_URL}stripe${uri}`;
    }
}
