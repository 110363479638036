import { inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';
import { Router } from '@angular/router';
import { Credential } from '@app/models/Credential';
import { Jwt } from '@app/models/Jwt';
import { User } from '@app/models';
import { SessionsExpiredComponent } from '@app/modules/common/sessions-expired/sessions-expired.component';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from './layout.service';
import { map, switchMap, take } from 'rxjs/operators';
import { DobWarningComponent } from '@app/components/dob-warning/dob-warning.component';
import { DemographicsService } from './demographics.service';
import { AlertService } from './alert-service.service';
import { NamePipe } from '@app/pipes/name.pipe';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _demographicsService = inject(DemographicsService);

    popedUp = false;
    loggedIn = new BehaviorSubject<boolean>(this.hasToken());
    userDataChange: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    missingValidateContacts = new BehaviorSubject<any>(null);
    missingValidateContactsObservable = this.missingValidateContacts.asObservable();

    availableUsers: Array<User> = [];
    default_user_id = null;

    constructor(
        private dialogModal: MatDialog,
        private readonly _http: HttpClient,
        private readonly _router: Router,
        private readonly _layoutService: LayoutService,
        private readonly _alertService: AlertService
    ) {}

    updateDateOfBirthDialog(): void {
        const dialog = this.dialogModal.open(DobWarningComponent, {
            panelClass: 'freddie-dialog',
            minHeight: '260px',
            width: '510px',
            disableClose: true,
            data: {
                username: new NamePipe().transform(this.user),
            },
        });

        dialog
            .afterClosed()
            .pipe(
                switchMap((res: any) =>
                    this._demographicsService.updateDemographics(this.user.id, { date_of_birth: res })
                )
            )
            .subscribe(() => this._alertService.openSnack('Date of birth updated successfully'));
    }

    get user(): User {
        return this.userDataChange.value;
    }

    get default_user(): User {
        return this.availableUsers?.find((user) => user.id === this.default_user_id);
    }

    setUser(user: any): void {
        this.userDataChange.next(new User(user));
    }

    authenticate() {
        return this._http.get(env.API_URL + 'me');
    }

    /**
     * @link api/v1/patient-portal/auth/newpatient/{doctor}
     * @param professionalId
     * @param data
     * @returns
     */
    addPatient(professionalId: string, data: any): Observable<any> {
        return this._http.post(`${env.API_URL}auth/newpatient/${professionalId}`, data);
    }

    /**
     * @link api/v1/patient-portal/auth/add-dependent/{doctor}
     * @param professionalId
     * @param data
     * @returns
     */
    addDependent(professionalId: string, data: any): Observable<any> {
        return this._http.post(`${env.API_URL}auth/add-dependent/${professionalId}`, data);
    }

    /**
     * @link api/v1/patient-portal/auth/resend-validation-contacts/{user}
     * @param patientId
     * @param type
     * @returns
     */
    resendContactTokens(patientId: string, type: 'email' | 'phone'): Observable<any> {
        return this._http.post(`${env.API_URL}auth/resend-validation-contacts/${patientId}`, { type }).pipe(take(1));
    }

    /**
     * @link api/v1/patient-portal/auth/validate-contacts/{user}
     * @param data
     * @param patientId
     * @returns
     */
    validateContactTokens(data, patientId: string): Observable<any> {
        return this._http.post(`${env.API_URL}auth/validate-contacts/${patientId}`, data);
    }

    /**
     * Check if token exists.
     *
     * @return {bool}
     */
    hasToken(): boolean {
        const token = this.getAccessToken();

        return token !== null && token !== undefined;
    }

    /**
     * Retrieve access token from storage.
     *
     * @return {string|null}
     */
    getAccessToken(): string | null {
        return localStorage.getItem('access_token');
    }

    /**
     * Verify if account is valid.
     *
     * @link api/v1/patient-portal/auth/verify-account
     * @param Credential data Email or phone
     * @return {Observable<any>}
     */
    verifyAccount(data: Credential): Observable<any> {
        return this._http.post(`${env.API_URL}auth/verify-account`, data);
    }

    /**
     * @link api/v1/patient-portal/auth/login
     * @param {Credential} credentials
     * @return {Observable<any>}
     */
    login(credentials: Credential): Observable<any> {
        return this._http.post(`${env.API_URL}auth/login`, credentials);
    }

    /**
     * Log out.
     *
     * @link api/v1/patient-portal/auth/logout
     * @param withRedirect
     * @return {Observable<any>}
     */
    logout(withRedirect = true): void {
        this._http.post(`${env.API_URL}auth/logout`, null).subscribe({
            next: () => this.callback(withRedirect),
            error: () => this.callback(withRedirect),
        });
    }

    /**
     * Refresh JWT token.
     *
     * @link api/v1/patient-portal/auth/refresh
     * @return {Observable<any>}
     */
    refresh(): Observable<any> {
        return this._http.post(env.API_URL + 'auth/refresh', null);
    }

    /**
     * Send request to reset password.
     *
     * @link api/v1/patient-portal/auth/password-request
     * @param {any} data Email or phone { login_email?, login_phone? }
     * @return {Observable<any>}
     */
    sendPasswordReset(data: any): Observable<any> {
        return this._http.post(`${env.API_URL}auth/password-request?scope=patient_portal`, data);
    }

    /**
     * Google 2Step QrCode
     *
     * @link api/v1/auth/qrcode
     * @returns Observable<any>
     */
    google2StepQrCode(): Observable<any> {
        return this._http.get(`${env.API_URL}auth/qrcode`).pipe(take(1));
    }

    /**
     * Send code via sms two step verification.
     *
     * @link api/v1/auth/qrcode/sms
     * @param password
     * @param code
     * @returns Observable<any>
     */
    sendCodeViaSms2StepVerification(password, code): Observable<any> {
        return this._http.post(`${env.API_URL}auth/qrcode/sms`, { password, code }).pipe(take(1));
    }

    /**
     * Enable two step verification.
     *
     * @link api/v1/auth/qrcode/enable
     * @param data
     * @returns Observable<any>
     */
    enable2StepVerification(data: any): Observable<any> {
        return this._http.post(`${env.API_URL}auth/qrcode/enable`, data).pipe(take(1));
    }

    /**
     * Disable two step verification.
     *
     * @link api/v1/auth/qrcode/disable
     * @param password
     * @returns Observable<any>
     */
    disable2StepVerification(password: string): Observable<any> {
        return this._http.post(`${env.API_URL}auth/qrcode/disable`, { password });
    }

    /**
     * Remove Avatar.
     *
     * @link api/v1/patient-portal/auth/remove-avatar
     * @return {Observable<any>}
     */
    removeAvatar(): Observable<any> {
        return this._http.post(`${env.API_URL}auth/remove-avatar`, null);
    }

    /**
     * Add contact.
     * @link api/v1/patient-portal/auth/add-contact
     * @param payload
     * @return {Observable<any>}
     */
    addContact(payload: any): Observable<any> {
        return this._http.post(`${env.API_URL}auth/add-contact`, payload);
    }

    /**
     * Validate new email.
     * @link api/v1/patient-portal/auth/email/validate
     * @param token
     * @param is_main
     * @return {Observable<any>}
     */
    validateNewEmail(token: string, is_main: boolean): Observable<any> {
        return this._http.post(`${env.API_URL}auth/email/validate`, { token, is_main });
    }

    /**
     * Update contacts.
     *
     * @link api/v1/patient-portal/auth/delete-contacts
     * @param contacts
     * @return {Observable<any>}
     */
    deleteContacts(contacts: Array<any>): Observable<any> {
        return this._http.post(`${env.API_URL}auth/delete-contacts`, contacts).pipe(take(1));
    }

    /**
     * Set main contact.
     *
     * @link api/v1/patient-portal/auth/update-contacts
     * @param contacts
     * @return {Observable<any>}
     */
    updateContacts(contact: any): Observable<any> {
        return this._http.post(`${env.API_URL}auth/update-contacts`, contact).pipe(take(1));
    }

    /**
     * Validate phone.
     * @link api/v1/patient-portal/auth/phone/validate
     * @param code
     * @return {Observable<any>}
     */
    validatePhone(code: string): Observable<any> {
        return this._http.post(`${env.API_URL}auth/phone/validate`, { code });
    }

    /**
     * Resend the two step authentication code.
     *
     * @link api/v1/patient-portal/auth/resend-two-step
     * @param data
     * @return Observable<any>
     */
    resendValidationCode(data: any): Observable<any> {
        return this._http.post(`${env.API_URL}auth/resend-two-step`, data);
    }

    /**
     * Send request to validate the reset password token.
     *
     * @link api/v1/patient-portal/auth/reset/validate/{token}
     * @param token
     * @return Observable<any>
     */
    validateResetToken(token: string): Observable<any> {
        return this._http.get(`${env.API_URL}auth/reset/validate/${token}`);
    }

    /**
     * Send the request to set the new password.
     *
     * @link api/v1/patient-portal/auth/reset/submit
     * @param data
     * @return Observable<any>
     */
    resetPassword(data: any): Observable<any> {
        return this._http.post(`${env.API_URL}auth/reset/submit`, data);
    }

    /**
     * Set session on local storage.
     *
     * @param {Jwt} res
     */
    setSession({ access_token }: Jwt): void {
        localStorage.setItem('access_token', access_token);
    }

    /**
     * Clear session in local storage.
     *
     * @return {void}
     */
    clearSessionStorage(): void {
        try {
            this.userDataChange.next(null);
            this.loggedIn.next(false);
            localStorage.removeItem('access_token');
        } catch (e) {
            console.log('CLEAR SESSION CATCH: ', e);
        }
    }

    openSessionExpiredDialog(): void {
        this.dialogModal.closeAll();
        this.clearSessionStorage();

        // open session expired warning
        const dialog = this.dialogModal.open(SessionsExpiredComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: 'none',
        });

        // after user clicked send to login
        dialog.afterClosed().subscribe((res) => {
            if (res) {
                this.logout();
            }
        });
    }

    /**
     * Verify login password.
     * @link api/v1/patient-portal/auth/validate/password
     * @param string Current password
     * @return {Observable<any>}
     */
    verifyPassword(password: string): Observable<any> {
        return this._http.post(`${env.API_URL}auth/validate/password`, { password });
    }

    /**
     * Update login password.
     * @link api/v1/patient-portal/auth/change/password
     * @param object Credentials
     * @return {Observable<any>}
     */
    updatePassword(passwords: { old_password: string; password: string }): Observable<any> {
        return this._http.post(`${env.API_URL}auth/change/password`, passwords).pipe(
            map((response: any) => {
                this.setSession(response);
                return response;
            })
        );
    }

    /**
     * @link api/v1/patient-portal/auth/{user}/request-token
     * @param user_id
     * @param data
     * @return {Observable<any>}
     */
    switchAccount(user_id, data): Observable<any> {
        return this._http.post(`${env.API_URL}auth/${user_id}/request-token`, data);
    }

    /**
     * AWS upload link token.
     *
     * @link api/v1/patient-portal/auth/uploadLink
     * @return {Observable<any>}
     */
    uploadLink(type: string, file_name: string): Observable<any> {
        return this._http.post(`${env.API_URL}auth/uploadLink`, { type, file_name });
    }

    /**
     * Clear session vars, localstorage and redirect to login.
     *
     * @param withRedirect
     * @return {void}
     */
    private callback(withRedirect = true): void {
        this.clearSessionStorage();

        if (withRedirect) {
            this._layoutService.isLoading(false);
            this._router.navigate(['/login']);
        }
    }

    /**
     * Getter for logged in observable.
     */
    get isLoggedIn(): Observable<boolean> {
        return this.loggedIn.asObservable();
    }

    /**
     * Is logged value
     *
     * @return boolean
     */
    get logged(): boolean {
        return this.loggedIn.value;
    }

    /**
     * Update preferences.
     *
     * @link api/v1/patient-portal/update-preferences
     * @param {any} data
     * @return {Observable<any>}
     */
    updateUserPreferencesObject(data: any): Observable<any> {
        return this._http.post(`${env.API_URL}update-preferences`, data);
    }

    /**
     * Get aws upload private link.
     *
     * @param type string
     * @param file_name string
     * @param isPdf boolean
     * @returns Observable<Array<string>>
     */
    uploadPrivateLink(type: string, file_name: string, isPdf: boolean = false): Observable<any> {
        return this._http.post(`${env.API_URL}me/uploadPrivateLink`, { type, file_name, isPdf });
    }

    get isDependent() {
        return this.user?.is_dependent ?? false;
    }
}
