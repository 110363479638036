<div *ngIf="config && config.visible" class="actions_section">
    <ng-container *ngIf="!config.stepperButtons; else stepper">
        <div class="mr-auto px-3 px-sm-0" *ngIf="config.hasCancel">
            <button mat-button class="primary" (click)="buttonClicked = 'cancel'">
                {{ config.cancelText }}
            </button>
        </div>

        <button class="primary" mat-button [disabled]="config.submitDisabled" (click)="buttonClicked = 'submit'">
            <ng-container *ngIf="config.submitText; else change">
                {{ config.submitText }}
            </ng-container>
            <ng-template #change i18n="Action buttons|change button text@@change"> change </ng-template>
        </button>
    </ng-container>

    <!-- Mat Stepper -->
    <ng-template #stepper>
        <!-- Previous button -->
        <ng-container *ngIf="!config.disablePrevious">
            <ng-container *ngIf="isStepperBtnAvailable('previous')">
                <div class="mr-auto">
                    <button
                        class="primary"
                        mat-button
                        (click)="buttonClicked = 'previous'"
                        i18n="Action buttons|previous button text@@previous"
                    >
                        previous
                    </button>
                </div>
            </ng-container>
        </ng-container>

        <!-- Skip button -->
        <ng-container *ngIf="isStepperBtnAvailable('skip')">
            <button
                class="primary mr-4"
                mat-button
                (click)="buttonClicked = 'skip'"
                i18n="Action buttons|skip button text@@skip"
            >
                skip
            </button>
        </ng-container>

        <ng-container *ngIf="isStepperBtnAvailable('find')">
            <button
                class="primary mr-4"
                mat-button
                debounceClick
                (debounceClick)="buttonClicked = 'find'"
                i18n="Action buttons|find button text@@find"
            >
                find next available
            </button>
        </ng-container>

        <!-- Next button -->
        <ng-container *ngIf="isStepperBtnAvailable('next')">
            <button class="primary" mat-button [disabled]="config.submitDisabled" (click)="buttonClicked = 'next'">
                <ng-container *ngIf="config.submitText; else next">
                    {{ config.submitText }}
                </ng-container>
                <ng-template #next i18n="Action buttons|next button text@@next"> next </ng-template>
            </button>
        </ng-container>
    </ng-template>
</div>
