<div class="fm-thumbnail fm-thumbnail-user {{ size }} letter-{{ user ? user.first_name[0].toLowerCase() : '' }}-{{ user ? user.last_name[0].toLowerCase() : '' }}">
    <!-- REVIEWING USER IMAGE -->
    <img
        alt="avatar"
        [src]="user.avatar"
        *ngIf="user && user.avatar && user.avatar != '' && showImages; else noAvatar;"
        (error)="imageError()"
    >
    <ng-template #noAvatar>
        <span *ngIf="user && user.first_name">{{ user.first_name[0].toUpperCase() }}</span>
        <span *ngIf="user && !user.first_name && user.email">{{ user.email[0].toUpperCase() }}</span>
    </ng-template>
</div>
