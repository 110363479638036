<p
    class="step-1--title mt-2"
    i18n="2 step authentication step 1|Title@@general.step 1">
    step 1
</p>
<p
    class="step-1--subtitle"
    i18n="2 step authentication step 1|subtitle@@step1.component.subtitle">
    Choose how to get your authentication codes.
</p>

<div class="row align-items-center no-gutters">
    <div class="col-12" [formGroup]="typeForm">
        <mat-radio-group class="d-flex justify-content-between align-items-center row"
            formControlName="type">

            <!-- Left card -->
            <div
                *ngIf="userHasMainEmail"
                class="row no-gutters col-12 col-md-6 step-1__card"
                [ngClass]="isTextSelected(1)"
                (click)="checkOption(1)">

                <p class="step-1__card--title" i18n="2 step authentication step 1|Paragraphs@@step1.component.left-card-p-1">
                    Authenticator app
                </p>
                <p class="w-100 step-1__card--subtitle" i18n="2 step authentication step 1|Paragraphs@@step1.component.left-card-p-2">
                    recommended
                </p>
                <p
                    class="step-1__card--text"
                    [ngClass]="isCardSelected(1)"
                    i18n="2 step authentication step 1|Paragraphs@@step1.component.left-card-p-3">
                    Free and available at app stores. It generates
                    time-sensitive authentication codes on your phone.
                </p>
                <div class="col-auto mx-auto step-1__card--checkbox">
                    <mat-radio-button value="1" #radioButton></mat-radio-button>
                </div>
            </div>

            <!--Right card -->
            <div
                *ngIf="userHasMainPhone"
                class="col-12 col-md-6 row no-gutters step-1__card"
                [ngClass]="isTextSelected(2)"
                (click)="checkOption(2)">
                <p class="step-1__card--title" i18n="2 step authentication step 1|Paragraphs@@step1.component.right-card-p-1">
                    Text message (SMS)
                </p>
                <p class="step-1__card--subtitle" i18n="2 step authentication step 1|Paragraphs@@step1.component.right-card-p-2"></p>
                <p
                    class="step-1__card--text"
                    [ngClass]="isCardSelected(2)"
                    i18n="2 step authentication step 1|Paragraphs@@step1.component.right-card-p-3">
                    Receive your time-sensitive authentication codes through
                    short message system on your phone.
                </p>
                <div class="col-auto mx-auto step-1__card--checkbox">
                    <mat-radio-button value="2" #radioButtonSms></mat-radio-button>
                </div>
            </div>
        </mat-radio-group>
    </div>

    <!-- controls -->
    <div class="col-auto ml-auto mt-4">
        <button
            mat-raised-button
            class="primary"
            matStepperNext
            (click)="changeStep(3)"
            i18n="2 step authentication step 1|button@@next">
            next
        </button>
    </div>
</div>
