import { RRuleSet, rrulestr } from 'rrule';
import moment from 'moment';

export class Slot {
    id: number;
    isDetachedAppointment: boolean;
    medical_group_id: number;
    user_id: number;
    clinic_id: number;
    title: string;
    clinic: any;
    duration: number;
    editable: number;
    rrule: RRuleSet = null;
    start: Date | string;
    end: Date | string;
    status: number;
    types: any;
    visibility: number;
    color: object;
    appointments: any = [];
    blocked: boolean;
    is_slot = true;

    constructor(data: any = {}) {

        Object.assign(this, data);

        if (this.rrule && typeof this.rrule === 'string') {
            this.rrule = (<RRuleSet>rrulestr(this.rrule, { forceset: true }));
        }
        this.start = moment(this.start).toDate();
        this.end = moment(this.start).add(this.duration, 'minutes').toDate();
    }
}
