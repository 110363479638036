import { cloneDeep as _cloneDeep } from 'lodash';
import { User, Patient, MedicalGroup, SnomedItem, Attachement, Vital, Addendum, Appointment} from '@app/models';

export class PatientFile {
    id: number;
    medical_group_id: number;
    user_id: number;
    user: any;
    patient: any;
    appointment: any;
    addendums?: Array<Addendum> = [];
    documents?: Array<any>;
    billings?: Array<any>;
    reports?: Array<any>;
    files?: Array<any>;
    summary?: any;
    prescriptions?: Array<any>;
    immunisations?: Array<any>;
    screenings?: Array<any>;
    medical_histories?: Array<any>;
    examinations?: Array<any>;
    vitals?: Vital;
    referral: any;
    referrals?: Array<any>;
    works: Array<any> = [];
    authorized_at: any;
    medical_history_at: any;
    examination_at: any;
    vitals_at: any;
    expires_at: any;
    items?: Array<SnomedItem> | null;
    itemTree?: Array<SnomedItem> | null;
    attachments?: Array<Attachement> | null;
    accepted_terms?: boolean | null;
    medical_group?: MedicalGroup;
    meta: any;
    checked: boolean;

    constructor(data, meta = null) {
        Object.assign(this, _cloneDeep(data));
        
        this.medical_group = this.medical_group ? new MedicalGroup(this.medical_group) : null;
        this.user = this.user ? new User(this.user) : null;
        this.patient = this.patient ? new Patient(this.patient) : null;
        this.addendums = this.addendums ? this.addendums.map(a => new Addendum(a)) : [];
        this.appointment = this.appointment ? new Appointment(this.appointment) : null;

        if (this.referral) {
            this.referral.patient_file = new PatientFile(this.referral.patient_file);
        }
        this.meta = meta;
    }

    get hasDocuments(): boolean {
        return this.reports?.length > 0 || this.referrals?.length > 0 ||
            this.prescriptions?.length > 0 || this.documents?.length > 0 ||
            this.billings?.length > 0 || this.files?.length > 0 || 
            this.screenings?.length> 0 || this.immunisations?.length > 0;
    }

    find(snomed_id) {
        return this.itemTree.find(i => +i.snomed_id === +snomed_id);
    }

    cleanData(snomed_id) {
        let currentItem;
        if ((currentItem = this.itemTree.find(it => +it.snomed_id === +snomed_id))) {
            currentItem.value = '';
            currentItem.description = '';
            currentItem.items = [];
        }
    }

    setPrevData(snomed_id, data) {
        let prevItem;
        if (data.itemTree) {
            this.itemTree = this.itemTree.filter(it => +it.snomed_id !== +snomed_id);
            if ((prevItem = data.itemTree.find(it => +it.snomed_id === +snomed_id))) {
                this.itemTree.push(this.cleanItem(prevItem));
            }
        }
    }

    private cleanItem(item): SnomedItem {
        item.id = null;
        item.patient_file_item_id = null;
        if (item.items) {
            item.items.forEach(i => { this.cleanItem(i); });
        }
        return item;
    }
}
