import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalVariable } from '@app/global';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    constructor(private readonly snackBar: MatSnackBar) {}

    openSnack(message: string = '', actionName: string = null, callback = null): void {
        const snack = this.snackBar.open(message, actionName, {
            duration: actionName ? GlobalVariable.undo_snack_time : GlobalVariable.alert_snack_time,
            panelClass: 'info-snack',
        });

        if (callback) {
            snack.onAction().subscribe(callback);
        }
    }

    openSnackError(message: string = ''): void {
        this.snackBar.open(message, ' ', {
            panelClass: 'error-snack',
        });
    }
}
