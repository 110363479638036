import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { RouterEvent, NavigationStart, NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    /* Control FM menu sidebar  */
    private _drawerFmMenuState = new BehaviorSubject<boolean>(false);
    drawerFmMenuState$ = this._drawerFmMenuState.asObservable();

    /* Control User menu sidebar  */
    private _drawerUserMenuState = new BehaviorSubject<boolean>(false);
    drawerUserMenuState$ = this._drawerUserMenuState.asObservable();

    /* Control loading spinner during user resolver  */
    private _loadingState = new BehaviorSubject<boolean>(false);
    loaded$ = this._loadingState.asObservable();

    constructor(private readonly _router: Router) { }

    isNavigationPending$: Observable<boolean> = this._router.events.pipe(
        filter((event: RouterEvent) => this.isConsideredEvent(event)),
        map((event: RouterEvent) => this.isNavigationStart(event)),
        distinctUntilChanged()
    );

    updateDrawerFmMenuState(state: boolean): void {
        this._drawerFmMenuState.next(state);
    }

    updateDrawerUserMenuState(state: boolean): void {
        this._drawerUserMenuState.next(state);
    }

    isLoading(state: boolean): void {
        this._loadingState.next(state);
    }

    private isConsideredEvent(event: RouterEvent): boolean {
        return this.isNavigationStart(event) || this.isNavigationEnd(event);
    }

    private isNavigationStart(event: RouterEvent): boolean {
        return event instanceof NavigationStart;
    }

    private isNavigationEnd(event: RouterEvent): boolean {
        return event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError;
    }
}
