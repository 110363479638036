export interface TestingVar {
    email: string;
    phone: string;
    password: string;
    newPassword: string;
}

export interface GlobalVar {
    services: Array<string>;
    date_format: string;
    date_pipe_format: string;
    calendar_name: string;
    appointment_limit: number;
    undo_snack_time: number;
    alert_snack_time: number;
    default_country: string;
    UPCOMING_APPOINTMENTS_INTERVAL: number;
    DECIMAL_NUMERATOR: number;
    appointment_reasons: Array<number>;
    testing?: TestingVar;
    aws_pre_assessment_bucket: string;
}

export const GlobalVariable = Object.freeze({
    services: [
        $localize`:clinical|appointment type:clinical`,
        $localize`:telephone|appointment type:telephone`,
        $localize`:video|appointment type:video`
    ],
    durations: [15, 30, 45, 60],
    date_format: 'MMMM D, YYYY',
    date_pipe_format: 'mediumDate',
    calendar_name: 'Personal',
    appointment_limit: 10,
    undo_snack_time: 4000,
    alert_snack_time: 2000,
    default_country: 'gb',
    UPCOMING_APPOINTMENTS_INTERVAL: 12,
    DECIMAL_NUMERATOR: 100000,
    PASSWORD_LENGTH: 8,
    freddiemed_logo_cinza_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAAVCAYAAACaLLqfAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4goKCgMooVqWAwAABIJJREFUaN7tmm1v2koQhR+vXwDjGEoaY5CSNKlStZWq/v//UalR1aSQ0ioBQsDgFwK274fKKwiGUl3dq9teny9Y3mU9M2fmzK5BSdM0pcBvD1GE4M+Atu/EbreL53m5Y+12m8PDwyKavwORi8WC5XKZO/b4+FhEspDWAgWRBQoi/xc98vv37xv9MIqirYt4nsdisUAIgWVZ1Ov1rXOn0ynX19esnnra7TaO4wDQ6XQYj8c/skwIDMOg2Wzy7NkzOf/Tp08EQbC27suXLzk4OADgw4cPLJdLFEVB13Vs28Z1XTTth7uXl5dEUSTHLcui1Wqh6zoAo9GIm5sb3rx5Q6lUWru3ilqtxtnZ2YaPmX2O49But6XfV1dXALx79w5VVddsWcXFxQXVapXxeEyn05GxKJVKOI6zFoudRN7d3f1SNgRBQBAEaJpGvV7n4eEh92EAy+WSJElwXRfDMAAwTXNj3snJCXEcMxwO6Xa7HBwcSCKSJMEwDEk+QLlcltdJkmCaJo1Gg/l8znA4xPd9Xr16haIoJElCuVzm6OhIjs9mM16/fo0QgjRNeXq8VhQFx3EQQkg7MvufIkkS0jRlMBjgOA6apnF7e0vekd22bSzLWrM/WzezI0vCyWRCp9MhSZKNU4L2T5S5rus7yczIywjMsnM1aI1GQ+6W7+7uWC6XMoDZd54GYBWGYfD8+XNJ8s3NDb7vY1mWtDELhmmafPnyBc/zctUkSRKEENi2LZNk1ZbcniUESZIwGAywbZvZbCbvZYjjmGq1ShzHVCoVKpVK7lq2bVOtVjk6OuLy8pJ+v78fkdmCu44c26qt1+tJGdhF5PX1tbw+Pz+nVqutBa7X6xHHMZ7noev6BmFBEPDx40fev3+PELtbfZYw8/lcErmKarUqx7cdr7JkE0LgeZ5MtG2oVCqyKn3fRwhBo9FgOBxKH8MwRFVVVFUljuO9YmyaJg8PD/tJa7PZRNd1Hh8f6Xa7v1SNYRjKqtqF4+NjSU5eJoZhSBAEpGnK27dvcx06PT39KYkZ6buk0Pd9ANkP86p7Pp+jqipJkmDb9k+fqSgKrutydXXFdDqVsrxasZVKhdlsJity3zaWZ2cukbVajfF4/FMy/g5WpTVPli4uLhiNRnS7XW5vbzk5OVmbE8cxvu9LEizLWnMw633Zp2macjOUqc39/T1RFHF/f0+5XN5KUCaJWWUtFgsp77s2dpkkhmFIs9lkMBisjauqiu/7sqdmCffUl8lkQhAETCYTwjDk9PR0PyKFENTrdUajkTR0NpttldlyubwhfZlc5WWqoijselefJVCj0WA6nTIajajValJ+hRBEUcTXr1/XNkeZ80IIwjDk27dvGIbB4eEhruuu+RdFEb1eD13XqdfrtFotWTGZjatI05R+v7+RjHlErlbe8fExi8UCTdNy1/U8b2PX+uLFC0qlkpzf7/dRVZVSqcTZ2VnuM5V9f/34/Pkz0+k0d8x1XVqtVnGYK14IFCiILFAQ+ce/otu2Bd92CN62rS/w70Ep/upRSGuB/xD+AlvFBcJr0vreAAAAAElFTkSuQmCC',
    appointment_reasons: [4, 3, 1],
    aws_pre_assessment_bucket: 'pre_assessment_documents',
});

export type FmDocumentType = 'terms-and-conditions' | 'privacy-policy' | 'cookie-policy';
export const FM_DOCUMENT_OPTIONS = ['terms-and-conditions', 'privacy-policy', 'cookie-policy'];
export const TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const PRIVACY_POLICY = 'privacy-policy';
export const COOKIE_POLICY = 'cookie-policy';
