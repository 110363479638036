import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryPhoneComponent } from './country-phone.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule,
        MatSelectModule,
        MatInputModule,
    ],
    declarations: [
        CountryPhoneComponent
    ],
    exports: [
        CountryPhoneComponent
    ]
})
export class CountryPhoneModule { }
