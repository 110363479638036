import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '@env/environment';
import { map, take } from 'rxjs/operators';
import { User } from '@app/models';
import { FmDocumentType } from '@app/global';

@Injectable({
    providedIn: 'root',
})
export class LegalDocumentsService {
    constructor(private readonly _http: HttpClient) {}

    private _makeUrl(uri: string = ''): string {
        return env.API_URL + uri;
    }

    getMedicalGroupLegalDocument(documentId, medicalGroupId): Observable<any> {
        return this._http.get(`${env.API_URL}legal-documents/${documentId}/medical-group/${medicalGroupId}`);
    }

    /**
     * Get legal documents about the authenticated user.
     *
     * @return Observable<any>
     */
    getLegalDocuments(): Observable<any> {
        return this._http.get(env.API_URL + 'legal-documents').pipe(take(1));
    }

    /**
     * Get platform documents.
     *
     * @link api/v1/patient-portal/legal-documents/platform-documents
     * @returns Observable<any>
     */
    getPlatformDocuments(): Observable<any> {
        return this._http.get(env.API_URL + 'legal-documents/platform-documents');
    }

    /**
     * @link api/v1/patient-portal/legal-documents/document/{document}
     * @param type
     * @returns
     */
    getPrivacyPolice(type: FmDocumentType): Observable<any> {
        return this._http.get(this._makeUrl(`legal-documents/document/${type}?patient_portal=true`)).pipe(
            take(1),
            map((res: any) => res.content)
        );
    }

    /**
     * Update sharing options.
     *
     * @link api/v1/patient-portal/legal-documents/sharing-options/{doctor}/update
     * @param doctor
     * @param data
     * @return Observable<any>
     */
    updateSharingOptions(doctor: User, data: any): Observable<any> {
        return this._http
            .post(this._makeUrl(`legal-documents/sharing-options/${doctor.id}/update`), data)
            .pipe(take(1));
    }

    /**
     * Accept legal document
     *
     * @link api/v1/patient-portal/legal-documents/{document}/accept
     * @param legalDocumentId
     * @returns Observable<any>
     */
    acceptLegalDocument(legalDocumentId: number): Observable<any> {
        return this._http.get(this._makeUrl(`legal-documents/${legalDocumentId}/accept`)).pipe(take(1));
    }

    /**
     * Accept platform legal document.
     *
     * @link api/v1/patient-portal/legal-documents/{document}/platform/accept
     * @param legalDocumentId
     * @returns Observable<any>
     */
    acceptPlatformDocument(legalDocumentId): Observable<any> {
        return this._http.get(this._makeUrl(`legal-documents/${legalDocumentId}/platform/accept`)).pipe(take(1));
    }
}
