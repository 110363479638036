import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AuthModule } from './modules/auth/auth.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { JwtModule } from '@auth0/angular-jwt';
import { SharedModule } from './shared/shared.module';
import { SessionsExpiredInterceptor } from './classes/interceptors/sessionsExpiredInterceptor';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingModule } from './modules/common/loading/loading.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LayoutModule } from '@angular/cdk/layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { LayoutModule as FMLayoutModule } from './modules/layout/layout.module';
import { SessionsExpiredModule } from './modules/common/sessions-expired/sessions-expired.module';

import { DataService, startupServiceFactory } from './services/data.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { SetLanguageInterceptor } from './classes/interceptors/set-language.interceptor';

import localeEn from '@angular/common/locales/en';
import localeEnGbExtra from '@angular/common/locales/extra/en-GB';
import localePt from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt-PT';
import localeUk from '@angular/common/locales/uk';
import localeExtraUK from '@angular/common/locales/extra/uk';
import localeRu from '@angular/common/locales/ru';
import localeExtraRU from '@angular/common/locales/extra/ru';
import { TwoFactorAuthModule } from './modules/two-factor-auth/two-factor-auth.module';
import { FmApps, FmErrorHandlerModule } from 'fm-core';

import { environment as env } from '@env/environment';

registerLocaleData(localeEn, 'en-GB', localeEnGbExtra);
registerLocaleData(localePt, 'pt-PT', localeExtraPT);
registerLocaleData(localeUk, 'uk', localeExtraUK);
registerLocaleData(localeRu, 'ru', localeExtraRU);

export function getToken() {
    return localStorage.getItem('access_token');
}

export let AppInjector: Injector;

export const CUSTOM_DATE_FORMAT = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        SessionsExpiredModule,
        CommonModule,
        SharedModule.forRoot(),
        BrowserModule,
        HammerModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        AuthModule,
        LoadingModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: getToken,
                whitelistedDomains: [
                    'api.freddiemed.com',
                    'apitst.freddiemed.com',
                    'apipprd.freddiemed.com',
                    'apidemo.freddiemed.com',
                    'fm-backend-tst.eu-west-1.elasticbeanstalk.com',
                    'test.local',
                    'devh.ideavity.com',
                    '127.0.0.1:8000',
                    'test.local.192.168.1.68.xip.io',
                    'test.local.192.168.1.166.xip.io',
                    'test.local.192.168.1.76.xip.io',
                    '192.168.1.182',
                    'localhost:8000',
                    'freddiemed-api.test',
                    'freddiemed-api.local',
                ],
                blacklistedRoutes: ['/api/v1/account/doctor/*', '/api/v1/terminal/*'],
            },
        }),
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatSidenavModule,
        LayoutModule,
        MatDialogModule,
        FMLayoutModule.forRoot(),
        TwoFactorAuthModule,

        /**
         * Custom FM error handler
         */
        FmErrorHandlerModule.forRoot({
            production: env.PRODUCTION,
            apiUrl: env.API_URL.replace('patient-portal/', '') + 'logger',
            appName: FmApps.PATIENT_PORTAL,
            // versionVerUrl: env.APP_URI,
            versionVerUrl: window.location.origin,
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [DataService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionsExpiredInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SetLanguageInterceptor,
            multi: true,
        },

        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMAT,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}
