<div class="row align-items-center no-gutters">
    <div
        id="text-explain"
        class="col-12 col-md-4 mr-5 pr-3">
        <span i18n="2 factor auth|step 0 headers@@step0.header">
            We highly recommend securing your account with two-factor
            authentication. Each time you log in to Freddie Med, you will be
            asked to provide a security code provided by your authenticator app.
        </span>
    </div>
    <div class="col row no-gutters align-items-center justify-content-center">
        <div class="col-12">
            <h4 i18n="2 factor auth|step 0 headers@@step0.subheader">
                How it works
            </h4>
        </div>

        <div class="col-xs-12 col-4 mt-4">
            <img
                class="img-fluid"
                alt="step 1"
                i18n-alt="2 factor auth|step 0 alts@@general.step 1"
                [src]="'assets/img/two-step/two-step-1.svg'">
        </div>
        <div class="col-12 col-sm-8">
            <p i18n="2 factor auth|step 0 paragraphs@@step0.paragraphs.1">
                Download the free authenticator app. We recommend the Google, Microsoft or DUO.
            </p>
        </div>

        <div class="col-xs-12 col-4 mt-4">
            <img
                class="img-fluid"
                alt="step 2"
                i18n-alt="2 factor auth|step 0 alts@@general.step 2"
                [src]="'assets/img/two-step/two-step-2.svg'">
        </div>
        <div class="col-12 col-sm-8 mt-4">
            <p i18n="2 factor auth|step 0 paragraphs@@step0.paragraphs.2">
                With the app, click “+” to scan the QR code provided by Freddie Med during setup.
            </p>
        </div>

        <div class="col-xs-12 col-4 mt-4">
            <img
                class="img-fluid"
                alt="step 3"
                i18n-alt="2 factor auth|step 0 alts@@general.step 3"
                [src]="'assets/img/two-step/two-step-3.svg'">
        </div>
        <div class="col-12 col-sm-8 mt-4">
            <p i18n="2 factor auth|step 0 paragraphs@@step0.paragraphs.3">
                Log in to your Freddie Med account as usual. At the prompt, enter the code provided by your authenticator app.
            </p>
        </div>
    </div>
    <div class="w-100"></div>
    <div class="col-auto ml-auto mt-4 step-0-actions">
        <button
            mat-button
            class="primary"
            (click)="close()"
            i18n="2 factor auth|step 0 actions@@step0.actions.1">
            no, thank you
        </button>
        <button
            class="ml-2 primary"
            mat-raised-button
            matStepperNext
            (click)="changeStep(1)"
            i18n="2 factor auth|step 0 actions@@step0.actions.2">
            please, secure my account
        </button>
    </div>
</div>
