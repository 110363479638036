<div
    class="row d-flex align-items-center justify-content-center warning-permissions"
    *ngIf="!openVideo; else jitsiMeet"
>
    <div class="col-12 d-flex flex-column align-items-center justify-content-center my-4">
        <img src="./assets/img/logos/FreddieMed_logo_blue.svg" alt="logo" />

        <div class="flex-row my-4">
            <i class="icon-fm-settings"></i>
            <i class="icon-fm-mais"></i>
            <i class="icon-freddiemed-icon_video-call"></i>
        </div>

        <strong
            class="text-center"
            i18n="Access to devices|Website requirements@@shared.patient-file-ios-check.please grant camera access"
        >
            Please grant camera and microphone access.
        </strong>
        <p
            class="text-center requirements"
            i18n="Preferences|Website requirements@@shared.patient-file-ios-check.if you cant join the appointment"
        >
            If you can’t join the appointment, please review the Settings preferences in your device.
        </p>

        <div class="warning-permissions__btn d-flex justify-content-center mt-5">
            <button
                mat-button
                class="mat-button soft-blue"
                *ngIf="isVisible"
                (click)="proceed()"
                i18n="link to proceed|Website requirements@@general.proceed"
            >
                proceed
            </button>
        </div>
    </div>
</div>

<ng-template #jitsiMeet>
    <div id="meet" *ngIf="openVideo"></div>
</ng-template>
