<div class="fm-main">
    <div class="header">
        <button
            id="close-btn"
            *ngIf="displayCloseBtn"
            (click)="close(false)">
            <mat-icon>close</mat-icon>
        </button>
        <h2 mat-dialog-title>
            {{ headerText }}
        </h2>
    </div>
    
    <div class="fm-content">
        <div class="row">
            <div class="col-12 pt-3 px-3 text-center">
                <p>{{ bodyText }}</p>
            </div>

            <div class="col-12 d-flex justify-content-end w-100 mt-4">
                <button 
                  mat-raised-button
                  class="fm-button-invert mr-2"
                  (click)="close(true)">
                  {{ btn1 }}
                </button>
    
                <button 
                  mat-raised-button
                  class="fm-button-regular mr-4"
                  (click)="close(false)">
                  {{ btn2 }}
                </button>
            </div>
        </div>
    </div>
</div>
