import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment as env } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class DemographicsService {
    insurancesDataChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private readonly _http: HttpClient) {}

    getDemographics(): Observable<any> {
        return this._http.get(`${env.API_URL}demographics`);
    }

    /**
     * Update demographic.
     *
     * @link api/v1/patient-portal/demographics/{user}/update
     * @param {any} user
     * @param {any} data
     * @returns
     */
    updateDemographics(user: any, data: any): Observable<any> {
        return this._http.post(`${env.API_URL}demographics/${user}/update`, data);
    }

    checkNhsNumber(nhsNumber, patientId): Observable<any> {
        return this._http.get(`${env.API_URL}demographics/nhs_check/${nhsNumber}/${patientId}`);
    }

    /**
     * Get insurances.
     *
     * @link api/v1/configs/insurances
     * @returns Observable<any>
     */
    getInsurances(): Observable<any> {
        if (this.insurancesDataChange.value !== null) {
            return this.insurancesDataChange.asObservable();
        }
        return this._http.get(`${env.API_URL}configs/insurances`).pipe(
            map((res: any) => {
                this.insurancesDataChange.next(res);
                return res;
            })
        );
    }

    removeInsurance(insuranceId: string | number): Observable<any> {
        return this._http.get(`${env.API_URL}demographics/insurances/remove/${insuranceId}`);
    }
}
