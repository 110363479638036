import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ExtRouterService {

    private previousUrlDataChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor() {}

    get previousUrl() {
        return this.previousUrlDataChanged.value;
    }

    set previousUrl(url: string) {
        this.previousUrlDataChanged.next(url);
    }
}
