import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TwoFactorAuthComponent } from './two-factor-auth.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { Step0Component } from './components/step0/step0.component';
import { MatButtonModule } from '@angular/material/button';
import { Step1Component } from './components/step1/step1.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Step2Component } from './components/step2/step2.component';
import { MatRadioModule } from '@angular/material/radio';
import { Step3Component } from './components/step3/step3.component';

@NgModule({
    declarations: [
        TwoFactorAuthComponent,
        Step0Component,
        Step1Component,
        Step2Component,
        Step3Component,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatStepperModule,
        MatInputModule,
        MatRadioModule,
    ],
})
export class TwoFactorAuthModule {}
