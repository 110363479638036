import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, ExtRouterService, PatientFileService } from '@app/services';
import { BaseComponent } from '../base/base.component';
import { detect } from 'detect-browser';
import { ScriptHelper } from '@app/classes/script-helper';
import { ENV } from '@app/tokens/env.token';

const browser = detect();

@Component({
    selector: 'app-patient-file-ios-check',
    templateUrl: './patient-file-ios-check.component.html',
    styleUrls: ['./patient-file-ios-check.component.scss'],
})
export class PatientFileIosCheckComponent extends BaseComponent implements OnInit, AfterViewInit {
    jitsiApp: JitsiMeetExternalAPI;
    TOOLBAR_BUTTONS: Array<string> = ['microphone', 'camera', /* 'settings', */ 'hangup', /* 'videoquality',  */'tileview'];
    openVideo: boolean = false;
    isVisible: boolean = false;
    videoId: string;

    env = inject(ENV);

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _extRouter: ExtRouterService,
        private readonly _patientFileService: PatientFileService,
        private readonly _authService: AuthService
    ) {
        super();

        this.subscriptions.add(this._route.params.subscribe((params: any) => (this.videoId = params['video-id'])));
    }

    ngOnInit(): void {
        if (this._isIOS) {
            this._extRouter.previousUrl = `${window.location.origin}/verify/${this.videoId}`;
            // this._extRouter.previousUrl = `${this.env.APP_URI}/verify/${this.videoId}`;
            this._router.navigateByUrl('/safari');
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.isVisible = true, 2000);
    }

    proceed(): void {
        this.subscriptions.add(
            this._patientFileService.getVideoLink(this.videoId).subscribe((res: any) => {
                if (res && res.zoom) {
                    window.location.href = res.zoom;
                } else if (res && res.link) {
                    this.openVideo = true;
                    const url = new URL(res.link);
                    setTimeout(() => {
                        const callback = () => this._initJitsi(url.host + url.pathname, res.displayName, res.avatarUrl);
                        ScriptHelper.loadExternalScript(
                            `${this.env.VC_URL}/external_api.js`,
                            'jitsi-meet',
                            callback
                        );
                    }, 0);
                }
            })
        );
    }

    private get _isIOS(): boolean {
        return browser && browser.name !== 'safari' && browser.name !== 'ios' && browser.os === 'iOS';
    }

    private _initJitsi(domain: string, displayName: string, avatarUrl: string): void {
        const options = {
            roomName: 'Freddiemed',
            width: '100%',
            height: '100%',
            parentNode: document.querySelector('#meet'),
            configOverwrite: {
                // disableTileView: true,
                disableDeepLinking: true, // IOS fix
                disableThirdPartyRequests: false,
                enableNoisyMicDetection: false,
                enableClosePage: false,
            },
            interfaceConfigOverwrite: {
                TOOLBAR_BUTTONS: this.TOOLBAR_BUTTONS,
                SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                CLOSE_PAGE_GUEST_HINT: false,
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                DISABLE_FOCUS_INDICATOR: true,
            },
            userInfo: { displayName, avatarUrl },
        };
        this.jitsiApp = new JitsiMeetExternalAPI(domain, options);

        this.jitsiApp.on('videoConferenceLeft', () => {
            if (this._authService.isLoggedIn) {
                this._router.navigateByUrl('/');
                // setTimeout(() => this._router.navigateByUrl('/'), 2000);
            }
        });
    }
}
