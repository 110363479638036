import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopUpService } from '@app/services';

export interface WarningDialogData {
    displayCloseBtn?: boolean;
    headerText?: string;
    bodyText?: string;
    btn1?: string;
    btn2?: string;
}

@Component({
    selector: 'app-warning-dialog',
    templateUrl: './warning-dialog.component.html',
    styleUrls: ['./warning-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningDialogComponent {
    displayCloseBtn: boolean = false;
    headerText: string = $localize`:warning|Warning dialog title:Unsaved changes`;
    bodyText: string = $localize`:warning|Warning dialog body:You have unsaved changes. Do you want to leave?`;
    btn1: string = $localize`:warning|Warning dialog btn yes@@yes:yes`;
    btn2: string = $localize`:warning|Warning dialog btn no@@no:no`;

    constructor(
        private readonly _dialogRef: MatDialogRef<Component>,
        private readonly _popupService: PopUpService,
        @Inject(MAT_DIALOG_DATA) public data: WarningDialogData
    ) {
        Object.assign(this, this.data);
    }

    close(res: any = null): void {
        this._popupService.navigateAwaySelection$.next(res);
        this._dialogRef.close(res);
    }
}
