<div class="row align-items-center no-gutters">
    <div class="col-auto pr-3 flag">
        <mat-select [class.readonly]="readonly" [formControl]="country">
            <mat-select-trigger>
                <div class="iti-flag d-inline-block" *ngIf="country.value" [ngClass]="country.value.flagClass"></div>
            </mat-select-trigger>
            <mat-option
                *ngFor="let country of allCountries"
                title="{{ country.name }} +{{ country.dialCode }}"
                [value]="country"
            >
                <div class="iti-flag d-inline-block" [ngClass]="country.flagClass"></div>
                <div class="flag-name">{{ country.name }} +{{ country.dialCode }}</div>
            </mat-option>
        </mat-select>
    </div>
    <div class="col-auto input">
        <mat-form-field class="w-100" [ngClass]="{ 'effect-fadein': fadeIn }">
            <input
                [autofocus]="autofocus"
                type="tel"
                matInput
                [ngClass]="{ readonly: readonly }"
                [readonly]="readonly"
                i18n-placeholder="country and phone input|Input placeholder@@general.phone number"
                placeholder="phone number"
                [formControl]="phone"
            />
            <mat-error
                *ngIf="phone.hasError('required') && !requiredMessage"
                i18n="country and phone input|Input required error@@common.country-phone.phone number is required"
            >
                phone number is
                <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="phone.hasError('required') && requiredMessage">{{ requiredMessage }}</mat-error>
            <mat-error
                *ngIf="phone.hasError('invalidPhone')"
                i18n="
                    country and phone input|Input phone number invalid error@@common.country-phone.phone number is
                    invalid"
            >
                phone number is
                <strong>invalid</strong>
                , please verify
            </mat-error>
            <mat-error *ngIf="phone.hasError('server')">{{ phone.getError('server').message }}</mat-error>
            <mat-error
                *ngIf="phone.hasError('phoneTaken')"
                i18n="
                    country and phone input|Input phone already taken error@@common.country-phone.this phone is already
                    taken"
                >this phone is already taken</mat-error
            >
            <mat-error
                *ngIf="phone.hasError('requiredIfNot')"
                i18n="
                    country and phone input|Input email or phone required error@@common.country-phone.e-mail or phone is
                    required"
            >
                e-mail or phone is
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
</div>
