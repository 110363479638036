import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService, AuthService } from '@app/services';

@Component({
    selector: 'app-switch-user',
    templateUrl: './switch-user.component.html',
    styleUrls: ['./switch-user.component.scss'],
})
export class SwitchUserComponent {
    users = [];
    isDefault = false;

    someError: string = $localize`:Alert|Messages@@unknown error:An error has occurred, please try again`;

    constructor(
        private readonly _dialogRef: MatDialogRef<SwitchUserComponent>,
        private readonly _authService: AuthService,
        private readonly _alertService: AlertService,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.isDefault = !!data?.select_default;
        if (!this.isDefault && !data?.addMySelfOnList) {
            this.users = this._authService.availableUsers.filter((user) => user.id !== this._authService.user.id);
        } else {
            this.users = this._authService.availableUsers;
        }
    }

    close(): void {
        this._dialogRef.close();
    }

    selectUser(user_id: string): void {
        this._authService
            .switchAccount(user_id, { default_user_id: this.isDefault })
            .pipe(take(1))
            .subscribe({
                next: (res) => {
                    if (res.data && res.access_token) {
                        this._authService.setUser(res.data);
                        this._authService.setSession(res);
                        this._cdr.detectChanges();
                        this.close();

                        if (this.data && this.data.hasOwnProperty('switchCallback')) {
                            this.data.switchCallback.call();
                        }

                        if (this._authService.default_user_id && !this._authService.user.date_of_birth) {
                            setTimeout(() => this._authService.updateDateOfBirthDialog(), 500);
                        }

                        if (this.data && this.data.previousUrl) {
                            this._router.navigateByUrl(this.data.previousUrl);
                        } else {
                            this._router.navigateByUrl('');
                        }
                    } else {
                        this._alertService.openSnackError(this.someError);
                    }
                },
                error: (res) => {
                    if (res.status === 403) {
                        this._alertService.openSnackError(res.error.message);
                    } else {
                        this._alertService.openSnackError(this.someError);
                    }
                },
            });
    }
}
