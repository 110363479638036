<!-- Logo -->
<div class="container py-3">
    <div class="row align-items-center">
        <div class="col-auto">
            <a (click)="goToLogin()">
                <img src="./assets/img/logos/FreddieMed_logo_blue@2x-8.png" alt="Freddie Med logo" />
            </a>
        </div>
        <div class="col-auto ml-auto">
            <app-select-language></app-select-language>
        </div>
    </div>
</div>

<div class="sub-header">
    <div class="container mx-auto row no-gutters flex-column d-flex justify-content-center">
        <div class="col d-flex align-items-center">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>

        <div class="col-auto">
            <!-- Footer -->
            <footer class="align-items-center pb-4 row w-100 justify-content-center">
                <!-- Terms of use -->
                <div class="col-12 col-sm-auto mt-4 mt-sm-0">
                    <a href="https://www.freddiemed.com/about" target="_blank" rel="noopener noreferrer"
                        i18n="site footer|Link to read more about site@@auth.about freddie med">
                        About Freddie Med
                    </a>
                </div>

                <!-- Contact -->
                <div class="col-12 col-sm-auto mt-4 mt-sm-0">
                    <a href="https://www.freddiemed.com/contact" target="_blank" rel="noopener noreferrer"
                        i18n="site footer|Link to contact@@auth.contact">
                        Contact
                    </a>
                </div>

                <!-- Terms -->
                <div class="col-12 col-sm-auto mt-4 mt-sm-0">
                    <a [routerLink]="'/terms/terms-and-conditions'" target="_blank"
                        i18n="site footer|Link to terms@@auth.terms">
                        Terms
                    </a>
                </div>

                <!-- Privacy policy -->
                <div class="col-12 col-sm-auto mt-4 mt-sm-0">
                    <a [routerLink]="'/terms/privacy-policy'" target="_blank"
                        i18n="site footer|Link to privacy@@auth.privacy policy">
                        Privacy policy
                    </a>
                </div>

                <!-- Freddie Med -->
                <div class="col-12 col-sm-auto mt-4 mt-sm-0 ml-sm-4">
                    <a class="freddiemed float-right">&copy; Freddie Med {{ current_year }}</a>
                </div>
            </footer>
        </div>
    </div>
</div>