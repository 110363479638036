import { Pipe, PipeTransform } from '@angular/core';
import { AppInjector } from '@app/app.module';
import { DataService } from '@app/services';

@Pipe({
    name: 'title',
})
export class TitlePipe implements PipeTransform {
    private _dataService;

    constructor() {
        this._dataService = AppInjector.get(DataService);
    }

    transform(key: any): any {
        const v =
            this._dataService && this._dataService.titles && this._dataService.titles.find((t) => +t.key === +key);
        return v ? v.value : '';
    }
}
