import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'step0',
    templateUrl: './step0.component.html',
    styleUrls: ['./step0.component.scss'],
})
export class Step0Component {
    @Output() stepChange = new EventEmitter<number>();
    @Output() closeWindow = new EventEmitter<number>();

    constructor() {}

    changeStep(index: number): void {
        this.stepChange.emit(index);
    }

    close(): void {
        this.closeWindow.emit();
    }
}
