import { parsePhoneNumber, isValidNumber } from 'libphonenumber-js';
import { NamePipe } from './pipes/name.pipe';
import moment from 'moment';

export class Utils {

    static formatPhone(value: any, country: any = null) {
        if (value == null || value.length === 0) {
            return '';
        }
        value = value.replace(/\s|-|\(|\)/g, '');

        if (value.indexOf('00') === 0) {
            value = '+' + value.substring(2);
        }

        const number = (value.indexOf('+') === 0) ? value : ('+' + ((country) ? country.dialCode : '') + value);

        return isValidNumber(number) ? parsePhoneNumber(number).number + '' : '';
    }

    static responseHandler(response, success, error) {
        return (response.errors) ? error(response) : success(response);
    }

    /**
	 * Remove country code from phone number if it exists.
	 * @return string phone number without country code.
	 */
    static removeDialCode(doctorPhone: string, dialCode: string): string {
        if (dialCode === undefined) {
            return doctorPhone;
        }
        if (/^\+|00/.test(doctorPhone)) {
            doctorPhone = doctorPhone.split(`+${dialCode}`)[1];
        }
        return doctorPhone;
    }

    static enumToObject(object): Array<object> | null {
        return Object.entries(object)
            .filter(item => typeof item[1] === 'number')
            .map(item => ({ key: item[1], value: item[0] }));
    }

    /**
     * Generate a char pattern based on num of sets and set length.
     *
     * @param numOfSets
     * @param setLength
     * @param char
     * @return string
     */
    static genCharSets(numOfSets: number = 3, setLength: number = 4, char: string = '*'): string {

        let charSet = '';

        for (let i = 0; i < numOfSets; i++) {

            charSet += char.repeat(setLength);

            if (i !== numOfSets) charSet += ' ';
        }
        return charSet;
    }

    static base64ToBlob(base64, mime) {
        mime = mime || '';
        const sliceSize = 1024;
        const byteChars = window.atob(base64);
        const byteArrays = [];

        for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
            const slice = byteChars.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mime });
    }

    static isDateRangeIn(startA: Date, endA: Date, startB: Date, endB: Date) {
        return startB >= startA && endB <= endA;
    }

    static isDateRangeInInclusive(startA: Date, endA: Date, startB: Date, endB: Date) {
        return ((startA <= startB && startB < endA) || (startA < endB && endB <= endA) || (startB < startA && endA < endB));
    }

    static copyToClipboard(event: MouseEvent, callback: Function): void {

        const val: any = (<any>event.target).innerHTML;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        if (callback) {
            callback();
        }
    }

    static signature(data: any): string {
        const img = data.signature ? `<img crossorigin="anonymous" style="display: block;" src="${data.signature + '?v=' + Math.random()}" width="120" /><br>` : '';
        const qualifications_abreviation = data.qualifications_abreviation && data.qualifications_abreviation.length > 0 ?
            `<span><br><small>${data.qualifications_abreviation}</small></span>` : '';
        return `${img}${new NamePipe().transform(data)}${qualifications_abreviation}`;
    }

    static getFileName(file, idx) {
        const today = moment().format('YYYY-MM-DD');
        const fileNameArray = file.split('.');
        const extension = fileNameArray.pop();
        const name = fileNameArray.join('.');
        return `${today}-${name}-${idx}.${extension}`;
    }

    static get currentLang() {
        return window.location.href.split('/')[3];
    }
}
