import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './components/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FutureLoadingComponent } from './components/future-loading/future-loading.component';

@NgModule({
  declarations: [
    LoadingComponent,
    FutureLoadingComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    LoadingComponent,
    FutureLoadingComponent,
  ]
})
export class LoadingModule {}
