import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class SnomedService {
    cachedData = {};

    constructor(private readonly http: HttpClient) {}

    private _makeUrl(uri: string = ''): string {
        return `${env.API_URL}snomed${uri}`;
    }

    keywords(query): Observable<any> {
        return this.http.post(this._makeUrl('/keywords'), { query });
    }

    complaints(query, user_id): Observable<any> {
        return this.http.post(this._makeUrl('/complaints'), { query, user_id });
    }

    /**
     * @link api/v1/patient-portal/snomed/occupations
     * @param query
     * @returns
     */
    occupation(query): Observable<any> {
        return this.http.post(this._makeUrl('/occupations'), { query });
    }

    allergies(query, user_id): Observable<any> {
        return this.http.post(this._makeUrl('/allergies'), { query, user_id });
    }

    manifestations(query): Observable<any> {
        return this.http.post(this._makeUrl('/manifestations'), { query });
    }

    screenings(query, user_id): Observable<any> {
        return this.http.post(this._makeUrl('/screenings'), { query, user_id });
    }

    immunisations(query, user_id): Observable<any> {
        return this.http.post(this._makeUrl('/immunisations'), { query, user_id });
    }

    systems(query, trees = null): Observable<any> {
        return this.http.post(this._makeUrl('/systems'), { query, trees });
    }

    clinicalFindings(query, user_id): Observable<any> {
        return this.http.post(this._makeUrl('/clinical-findings'), { query, user_id });
    }

    bodyParts(query): Observable<any> {
        return this.http.post(this._makeUrl('/body-parts'), { query });
    }

    bodyStructure(query): Observable<any> {
        return this.http.post(this._makeUrl('/body-parts'), { query });
    }

    relatives(): Observable<any> {
        return this.http.post(this._makeUrl('/relatives'), {});
    }

    surgicalProcedures(): Observable<any> {
        return this.http.post(this._makeUrl('/surgical-procedures'), {});
    }

    procedures(query): Observable<any> {
        return this.http.post(this._makeUrl('/procedures'), { query });
    }

    drugs(query, user_id): Observable<any> {
        return this.http.post(this._makeUrl('/drugs'), { query, user_id });
    }

    findSpeciality(speciality) {
        return this.http.post(`${env.API_URL}/speciality`, speciality);
    }

    relationships(query: string): Observable<any> {
        return this.http.post(this._makeUrl('/relationships'), { query });
    }
}
