import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@app/services';
import { GlobalVariable } from '@app/global';
import { AppInjector } from '@app/app.module';

@Pipe({
    name: 'fm_currency',
})
export class CurrencyPipe implements PipeTransform {
    transform(value: number, currency: string = null): string {
        const result = currency
            ? (AppInjector.get(DataService).getCurrencySymbol(currency) + ' ')
            : '';

        return result + Number(value / GlobalVariable.DECIMAL_NUMERATOR).toFixed(2);
    }
}
