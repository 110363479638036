export class DomList {

    static checkValue(value: any, isVitals = false): any {
        if ((/(\d|true|false)/i.test(value) && !isVitals) || (!value && isVitals)) {
            return '';
        }
        return `: <b>${value}</b>`;
    }

    static createProcedures(data: Array<any>): string {

        const title = '<h2>' + $localize`:Section title|Procedures:PROCEDURES` + '</h2>';

        let list: string = title;
        data.forEach(item => {
            list += `<strong>${item.procedure.snomed_term}</strong> <em>(snomed id: ${item.procedure.snomed_id})</em><br>`;
            list += `<div class="pre-line_white_space">${item.justification}</div> <br>`;
        });

        return list;
    }

    static createList(data: any, title: string = '', isVitals = false): string {
        if (!data.items.length) {
            return null;
        }

        if (title.length > 0) {
            title = `<h2>${title}</h2>`;
        }
        let list: string = title + '<ul>';

        data.items.forEach((item: any) => {
            let colon = '';
            const itemHasChildren = item.items && item.items.length;

            if (itemHasChildren) {
                colon = ':';
            }

            list += `<li>${item.snomed_term}${colon}${DomList.checkValue(item.value, isVitals)}</li>`;

            if (itemHasChildren) {
                list += DomList.createList(item, '', isVitals);
            }

        });

        list += '</ul>';

        return list;
    }
}
