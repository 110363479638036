import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as env } from '@env/environment';
import { TitlePipe } from '@app/pipes/title.pipe';
import { GlobalVariable } from '@app/global';
import * as moment from 'moment';
import { DomList } from '@app/classes/DomList';

@Injectable({
    providedIn: 'root',
})
export class PatientFileService {
    private defaultTags = [
        'title',
        'first_name',
        'last_name',
        'title_recipient',
        'first_name_recipient',
        'last_name_recipient',
        'clinic',
        'appointment_date',
        'document_date',
    ];

    private documents: Array<any> = [
        {
            tag: 'appointment_notes',
            value: $localize`:Appointment notes section|Patient file documents:Appointment notes`,
            snomed_id: 398007000,
        },
        {
            tag: 'diagnosis',
            value: $localize`:Diagonosis section|Patient file documents:Diagonosis`,
            snomed_id: 439401001,
        },
        {
            tag: 'examination',
            value: $localize`:General practice examination section|Patient file documents:General practice examination`,
            examination: true,
        },
        {
            tag: 'legacy_diagnosis',
            value: $localize`:Legacy diagnosis section|Patient file documents:Legacy diagnosis`,
            snomed_id: 48318009,
        },
        { tag: 'vitals', value: $localize`:Vitals section|Patient file documents:Vitals`, snomed_id: 118222006 },
        {
            tag: 'care_plan',
            value: $localize`:Care plan section|Patient file documents:Care plan`,
            snomed_id: 734163000,
        },
    ];

    constructor(private http: HttpClient) {}

    private makeUrl(uri = ''): string {
        return env.API_URL + 'patient-files/' + uri;
    }

    private recipientDataChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    set recipient(recipient: any) {
        this.recipientDataChange.next(recipient);
    }

    get recipient() {
        return this.recipientDataChange.value;
    }
    /**
     * Download authorized document.
     *
     * @link api/v1/patient-portal/patient-files/{patient_file}/download/document/{file}
     * @param patientFileId
     * @param documentId
     * @returns Observable<any>
     */
    downloadAuthorizedDocument(patientFileId, documentId): Observable<any> {
        return this.http.get(this.makeUrl(`${patientFileId}/download/document/${documentId}`));
    }

    /**
     * Download invoice.
     *
     * @link api/v1/patient-portal/patient-files/{patient_file}/download/invoice/{file}
     * @param patientFileId
     * @param documentId
     * @returns
     */
    downloadBillingDocument(patientFileId, documentId): Observable<any> {
        return this.http.get(this.makeUrl(`${patientFileId}/download/invoice/${documentId}`));
    }

    /**
     * Download prescription.
     *
     * @link api/v1/patient-portal/patient-files/{patient_file}/download/prescription/{file}
     * @param patientFileId
     * @param documentId
     * @returns Observable<any>
     */
    downloadPrescriptionDocument(patientFileId, documentId): Observable<any> {
        return this.http.get(this.makeUrl(`${patientFileId}/download/prescription/${documentId}`));
    }

    /**
     * Download payment receipt.
     *
     * @link api/v1/patient-portal/patient-files/{patient_file}/download/payments/{file}
     * @param patientFileId
     * @param documentId
     * @returns Observable<any>
     */
    downloadPaymentReceipt(patientFileId: number, fileId: string): Observable<any> {
        return this.http.get(this.makeUrl(`${patientFileId}/download/payments/${fileId}`));
    }

    /**
     * Download document.
     *
     * @link api/v1/patient-portal/patient-files/download/document/{file}
     * @param patientFileId
     * @param documentId
     * @returns Observable<any>
     */
    downloadFile(patientFileId: any, fileId: string): Observable<any> {
        return this.http.get(this.makeUrl(`${patientFileId}/download/document/${fileId}`));
    }

    /**
     * Get video link.
     *
     * @link api/v1/patient-portal/video-link/{token}
     * @param videoId
     * @returns Observable<any>
     */
    getVideoLink(videoId: string): Observable<any> {
        return this.http.post(`${env.API_URL}video-link/${videoId}`, null);
    }

    public generateChunks(array, size: number = 6): Array<any> {
        const chunked_arr = [];
        const copied = [...array];
        const numOfChild = Math.ceil(copied.length / size);

        for (let i = 0; i < numOfChild; i++) {
            chunked_arr.push(copied.splice(0, size));
        }

        return chunked_arr;
    }

    /**
     * Render tags
     *
     * @param tagName
     * @param returnMessage
     */
    public getTagHtml(patientFile, tagName, returnMessage = true): string {
        let html = null;
        if (this.defaultTags.indexOf(tagName) !== -1) {
            switch (tagName) {
                case 'first_name':
                    html = patientFile.patient.first_name;
                    break;
                case 'last_name':
                    html = patientFile.patient.last_name;
                    break;
                case 'title':
                    html = new TitlePipe().transform(patientFile.patient?.title);
                    break;

                case 'first_name_recipient':
                    html = this.recipient?.first_name;
                    break;
                case 'last_name_recipient':
                    html = this.recipient?.last_name;
                    break;
                case 'title_recipient':
                    html = new TitlePipe().transform(this.recipient?.title);
                    break;

                case 'clinic':
                    html = patientFile.clinic ? patientFile.clinic.name : '';
                    break;
                case 'appointment_date':
                    html = moment(patientFile.consultation_date).format(GlobalVariable.date_format);
                    break;
                case 'document_date':
                    html = moment().format(GlobalVariable.date_format);
                    break;
            }
        } else {
            const document = this.documents.find((d) => d.tag === tagName);

            if (tagName === 'examination') {
                html = this._getComponentFromView(document);
            } else if (document) {
                html = this._renderData(patientFile, document);
            }
        }
        return html ?? (returnMessage ? `${tagName.replace('_', ' ')} is empty` : null);
    }

    private _renderData(patientFile, document): string {
        const data = patientFile.find(document.snomed_id) || null;
        if (
            (document.snomed_id === 118222006 || document.snomed_id === 439401001) &&
            data &&
            data.items &&
            data.items.length
        ) {
            return DomList.createList(data, document.value, document.snomed_id === 118222006);
        } else if (data) {
            const value = /\r|\n/.exec(data.value)
                ? `<div class='pre-line_white_space'>${data.value}</div>`
                : data.value;
            return this._setTitle(document.value) + value;
        }
    }
    private _getComponentFromView(target_doc): string {
        const tmpContainer: HTMLElement = document.querySelector('#pf-view-container');
        if (tmpContainer) {
            const pasteContent = this._setTitle(target_doc.value) + tmpContainer.innerHTML;
            const doc = new DOMParser().parseFromString(pasteContent, 'text/html');
            this._cleanHtml(doc.body, Array.from(doc.body.childNodes));
            return doc.body.innerHTML;
        } else {
            return null;
        }
    }

    private _cleanHtml(parent: ChildNode, nodes: Array<any>) {
        for (const node of nodes) {
            this._cleanHtml(node, Array.from(node.childNodes));
            if (node.nodeType === node.ELEMENT_NODE) {
                const elem = document.createElement(
                    node.nodeName.indexOf('APP-') !== -1 || node.nodeName.indexOf('-VIEW') !== -1
                        ? 'div'
                        : node.nodeName.toLocaleLowerCase()
                );
                if (node.parentNode.classList.contains('drug') && node.classList) {
                    elem.className = node.classList.value;
                }
                if (node.classList && node.classList.contains('pre-line_white_space')) {
                    elem.classList.add('pre-line_white_space');
                }
                if (node.classList && node.classList.contains('drug')) {
                    elem.classList.add('row', 'drug');
                }
                for (const child of Array.from(node.childNodes)) {
                    elem.appendChild(child);
                }
                parent.replaceChild(elem, node);
            } else if (node.nodeType === node.TEXT_NODE) {
                // NOTHING
            } else if (node.nodeType === node.COMMENT_NODE || node.nodeType === node.ATTRIBUTE_NODE) {
                node.remove();
            }
        }
    }

    private _setTitle(title: string = ''): string {
        return `<h2>${title}</h2>`;
    }
}
