import { Component } from '@angular/core';
import { AlertService, ExtRouterService } from '@app/services';

@Component({
    selector: 'app-safari-optimized',
    templateUrl: './safari-optimized.component.html',
    styleUrls: ['./safari-optimized.component.scss']
})
export class SafariOptimizedComponent {

    constructor(
        private readonly _extRouterService: ExtRouterService,
        private readonly _alertService: AlertService
    ) { }

    copy_link(): void {
        const url = this._extRouterService.previousUrl;
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this._alertService.openSnack($localize`:Alert|Messages@@shared.safari-optimized.link copied:Link copied!`);
    }
}
