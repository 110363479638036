import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[autofocus]',
})
export class AutofocusDirective {
    @Input()
    public autofocus = true;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        if (this.autofocus) {
            this.el.nativeElement.focus();
        }
    }
}
