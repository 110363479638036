import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BaseComponent } from '../base/base.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SwitchUserComponent } from '../switch-user/switch-user.component';
import { AuthService, HeaderService, LayoutService } from '@app/services';

@Component({
    selector: 'app-general-header',
    templateUrl: './general-header.component.html',
    styleUrls: ['./general-header.component.scss'],
})
export class GeneralHeaderComponent extends BaseComponent implements OnInit {
    desktopLayout = false;
    breakpoints: Array<string> = ['(max-width: 991px)'];
    isTermsPage = false;

    headerData = {
        text: '',
        icon: '',
        thumbnail: true,
        goBackCallback: null,
    };

    constructor(
        public readonly authService: AuthService,
        private readonly _layoutService: LayoutService,
        private readonly _breakpointObserver: BreakpointObserver,
        private readonly _dialog: MatDialog,
        private readonly _router: Router,
        private readonly _headerService: HeaderService
    ) {
        super();
        this._checkTermsPage();

        this.subscriptions.add(
            this._headerService.headerDataChanged.subscribe((data) => {
                this.headerData = Object.assign(this.headerData, data);
            })
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this._breakpointObserver
                .observe(this.breakpoints)
                .subscribe((result: any) => (this.desktopLayout = !result.matches))
        );
    }

    toogleDrawer(): void {
        this._layoutService.updateDrawerFmMenuState(true);
    }

    toogleAppUserMenu(): void {
        this._layoutService.updateDrawerUserMenuState(true);
    }

    goTo(uri: string = '/'): void {
        this._router.navigateByUrl(uri);
    }

    logout(): void {
        this.authService.logout();
    }

    switchUser(): void {
        this._dialog.open(SwitchUserComponent, {
            width: '300px',
            maxWidth: '100vw',
            panelClass: 'switch-user-dialog',
        });
    }

    login(): void {
        localStorage.setItem('previousUrl', this._router.url);
        this._router.navigateByUrl('login');
    }

    private _checkTermsPage(): void {
        const segments: Array<string> = this._router.url.split('/');

        if (!this.authService.user && segments.length && segments.includes('terms')) {
            this.isTermsPage = true;
        }
    }
}
