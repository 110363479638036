import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, DoCheck } from '@angular/core';
import { BaseComponent } from './shared/base/base.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { Router, NavigationStart, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import {
    ActionButtonsService,
    CookieConsentService,
    GoogleAnalyticsService,
    AuthService,
    StripeService,
    LayoutService,
} from './services';
/* import { ActivityService } from './services/activity.service'; */

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit, DoCheck {
    isResolved = true;
    desktopLayout = false;
    mode: MatDrawerMode = 'over';
    breakpoints: Array<string> = ['(max-width: 991px)'];
    isInLogin = true;
    delay: number = 1000;
    throttled: boolean = false;

    enableActionButtons = false;

    @ViewChild('fmMenu', { static: false }) fmMenu: MatDrawer;
    @ViewChild('userMenu', { static: false }) userMenu: MatDrawer;

    @HostListener('window:resize', ['$event'])
    onResize() {
        //document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
        setTimeout(() => {
            this.resizeDebounce();
        }, 5000);
    }

    constructor(
        private readonly _stripeService: StripeService,
        public readonly layoutService: LayoutService,
        private readonly _breakpointObserver: BreakpointObserver,
        public readonly authService: AuthService,
        private readonly _router: Router,
        private actionButtonsService: ActionButtonsService,
        private cookieConsentService: CookieConsentService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private cdr: ChangeDetectorRef // ,
    ) // private readonly activity: ActivityService
    {
        super();

        this._router.routeReuseStrategy.shouldReuseRoute = () => false;

        (window as any).ga =
            (window as any).ga ||
            function () {
                ((window as any).ga.q = (window as any).ga.q || []).push(arguments);
            };
        (window as any).ga.l = +new Date();

        this.subscriptions.add(
            this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: any) => {
                // remove nav/aside bar for these routes
                const segments: Array<string> = ['login', 'verify'];

                if (segments.includes(event.url.split('/')[1])) {
                    this.isInLogin = true;
                } else {
                    this.isInLogin = false;
                }
            })
        );

        this.subscriptions.add(this._router.events.subscribe((event: any) => this.navigationInterceptor(event)));

        this.cookieConsentService.init();
        this.subscriptions.add(
            this.cookieConsentService.cookieConsentDataChange.subscribe((option: string) => {
                if (option === 'all') {
                    this.googleAnalyticsService.init();
                    this.cookieConsentService.log('Init Google analytics service');
                } else if (option === 'necessary') {
                    this.googleAnalyticsService.destroy();
                }
            })
        );

        this._stripeService.init();
        this.onResize();
    }

    ngDoCheck(): void {
        this.cdr.detectChanges();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.actionButtonsService.actionButtonsDataChanged.subscribe((data) => {
                this.enableActionButtons = data && data.visible;
                this.cdr.detectChanges();
            })
        );

        this.subscriptions.add(
            this._breakpointObserver.observe(this.breakpoints).subscribe((result: any) => {
                this.desktopLayout = !result.matches;
                this.mode = this.desktopLayout ? 'push' : 'over';
            })
        );
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.layoutService.drawerFmMenuState$.subscribe((state: boolean) => {
                if (state && this.fmMenu && !this.desktopLayout) {
                    this.fmMenu.toggle();
                }
            })
        );

        this.subscriptions.add(
            this.layoutService.drawerUserMenuState$.subscribe((state: boolean) => {
                if (state && this.userMenu) {
                    this.userMenu.toggle();
                }
            })
        );
    }

    /**
     * Navigation interceptor.
     *
     * @param {RouterEvent} event
     * @return void
     */
    private navigationInterceptor(event: RouterEvent): void {
        const exceptions = ['/settings/legal-documents', '/settings/freddiemed-terms', '/login'];

        if (event instanceof NavigationStart && !exceptions.some((url) => event.url.includes(url))) {
            localStorage.setItem('previousUrl', event.url);
        }

        if (event instanceof NavigationEnd) {
            (window as any).ga('set', 'page', event.urlAfterRedirects);
            (window as any).ga('send', 'pageview');
        }
    }

    private resizeDebounce(): void {
        this.throttled = true;
    }

    /* @HostListener('window:mousemove')
    refreshUserState(): void {
        this.activity.initCounter();
    } */
}
