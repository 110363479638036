import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Utils } from '@app/utils';

@Injectable()
export class SetLanguageInterceptor implements HttpInterceptor {

    lang: string;
    constructor() {
        this.lang = Utils.currentLang.replace('-', '_');
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const clonedRequest = request.clone({ setHeaders: { 'FreddieMed-Language': this.lang } });
        return next.handle(clonedRequest);
    }
}
