import { Injectable } from '@angular/core';
import { Calendar } from '@app/models/Calendar';
import { Slot } from '@app/models/Slot';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    private _bookingChange$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor() {}

    get currentChange$(): Observable<any> {
        return this._bookingChange$.asObservable();
    }

    get current() {
        return this._bookingChange$.value;
    }

    set current(data) {
        this._bookingChange$.next(data);
    }

    updateCurrent(data) {
        this.current = { ...this.current, ...data };
    }

    clearCurrent() {
        this._bookingChange$.next(null);
    }

    availableServices(useFilters = true) {
        const current = this.current;

        if (current.booking_type === 'workspace') {
            return current.medicalGroup.getServices(
                current.profession?.id,
                current.profession?.speciality ? current.profession.speciality.id : null
            );
        } else {
            return useFilters
                ? Calendar.getAllAvailableServices(current.user, current.type, current.clinic)
                : Calendar.getAllAvailableServices(current.user);
        }
    }

    /**
     * Get available slots.
     *
     * @return Array<any>
     */
    availableSlotsByType(): Array<any> {
        const current = this.current;

        let slots = [];

        if (current.booking_type === 'workspace') {
            let servicesBySpeciality = current.medicalGroup.getServicesBySpeciality(current.profession);

            //If patient selected a specific doctor gets slots for the doctor
            if (current.user) {
                //Filter slots by user's speciality workspace services
                slots = current.user.calendar.slots.filter((slot: Slot) => {
                    return servicesBySpeciality.find((service: any) => {
                        return slot.types.includes(service.type);
                    });
                });

                // Remove old slots
                slots = slots.filter((slot: Slot) => {
                    return (
                        (slot.rrule !== null && slot.rrule.after(moment().startOf('day').toDate(), true)) ||
                        (slot.rrule === null && moment(slot.start).isAfter(moment().startOf('day')))
                    );
                });
            }
            //If patient only selected a speciality gets slots for that speciality
            else {
                // Get all slots of workspace by selected speciality
                let specialitySlots = current.medicalGroup.getSlotsBySpeciality(current.profession);

                //Filter slots by selected speciality workspace services
                specialitySlots = specialitySlots.filter((slot: Slot) => {
                    return servicesBySpeciality.find((service: any) => {
                        return slot.types.includes(service.type);
                    });
                });

                //Remove old slots
                specialitySlots = specialitySlots.filter((slot: Slot) => {
                    return (
                        (slot.rrule !== null && slot.rrule.after(moment().startOf('day').toDate(), true)) ||
                        (slot.rrule === null && moment(slot.start).isAfter(moment().startOf('day')))
                    );
                });

                slots = specialitySlots;
            }
        } else {
            // Booking appointment with doctor by pp

            // Get all slots of doctor in all workspaces
            let userSlots = _.cloneDeep(current.user.calendar.slots);

            const medicalGroup = (id: number) => {
                return this.current.user.medical_groups.find((workspace: any) => workspace.id === id);
            };

            //Filter slots by workspace services
            userSlots = userSlots
                .map((slot: Slot) => {
                    const services =
                        medicalGroup(slot.medical_group_id) &&
                        medicalGroup(slot.medical_group_id).getServicesBySpeciality(current.user.profession);

                    if (services) {
                        const types = [];
                        slot.types.forEach((type: number) => {
                            if (services.find((s) => s.type === type)) {
                                types.push(type);
                            }
                        });
                        slot.types = _.uniq(types);

                        return slot;
                    }
                })
                .filter((s) => s);

            userSlots = userSlots.filter((slot: Slot) => {
                return (
                    (slot.rrule !== null && slot.rrule.after(moment().startOf('day').toDate(), true)) ||
                    (slot.rrule === null && moment(slot.start).isAfter(moment().startOf('day')))
                );
            });

            slots = userSlots;
        }
        return slots;
    }
}
