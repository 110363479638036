import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SessionsExpiredComponent } from './sessions-expired.component';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule,
        MatDialogModule,
        MatButtonModule,
    ],
    declarations: [
        SessionsExpiredComponent
    ],
    exports: [
        SessionsExpiredComponent
    ]
})
export class SessionsExpiredModule {}
