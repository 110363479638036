<div class="header">
    <div class="container row align-items-center h-100 px-0">
        <ng-container *ngIf="!isTermsPage; else terms_page">
            <div class="col-auto d-lg-none" (click)="toogleDrawer()">
                <i class="icon-fm-hamburger-menu" aria-hidden="true"></i>
            </div>
            <div class="col-auto logo-font h-100">
                <img routerLink="/" class="h-100 w-100" src="./assets/img/logos/FreddieMed_logo_blue.svg"
                    alt="Freddie Med logo" i18n-alt="image alt|general header@@general_alt_for_images" />
            </div>
        </ng-container>
        <ng-template #terms_page>
            <div class="col-auto d-block ml-1 logo-font h-100">
                <img routerLink="/" class="h-100 w-100" src="./assets/img/logos/FreddieMed_logo_blue.svg"
                    alt="Freddie Med logo" i18n-alt="image alt|general header@@general_alt_for_images" />
            </div>
        </ng-template>

        <div class="col-auto px-0 left-content row align-items-center no-gutters">
            <div class="col-auto" [ngClass]="{ 'px-3': headerData.icon && headerData.icon != '' }"
                *ngIf="headerData.thumbnail">
                <div class="icon">
                    <i [class]="headerData.icon" aria-hidden="true"></i>
                </div>
            </div>
            <div class="col-auto px-3" *ngIf="!!headerData.goBackCallback">
                <mat-icon (click)="headerData.goBackCallback()"> keyboard_arrow_left </mat-icon>
            </div>
            <div class="col-auto" *ngIf="!headerData.thumbnail">
                <span class="text">{{ headerData.text }}</span>
            </div>
        </div>

        <ng-container *ngIf="authService?.user && headerData.thumbnail">
            <div class="col-auto ml-auto name px-0 text-right d-none d-lg-block">
                <span>{{ authService?.user?.title | title }}</span>
                <br *ngIf="authService?.user?.title" />
                <span>{{ authService.user | name: false }}</span>
            </div>
            <div class="col-auto ml-auto ml-lg-0" (click)="toogleAppUserMenu()">
                <!-- Mobile/tablet mode -->
                <app-thumbnail *ngIf="!desktopLayout; else desktop" [size]="'small'"> </app-thumbnail>

                <!-- Desktop mode -->
                <ng-template #desktop>
                    <app-thumbnail [size]="'small'" [matMenuTriggerFor]="menu"> </app-thumbnail>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="switchUser()" *ngIf="authService.availableUsers.length"
                            i18n="switch user|general header@@shared.switch user">
                            switch user
                        </button>
                        <button mat-menu-item (click)="goTo('/settings')" i18n="account|general header@@shared.account">
                            account
                        </button>
                        <button mat-menu-item (click)="goTo('/settings/payment-details')"
                            i18n="payments|general header@@shared.payments">
                            payments
                        </button>
                        <button mat-menu-item (click)="goTo('/settings/legal-documents')"
                            i18n="legal documents|general header@@general.legal documents">
                            legal documents
                        </button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item (click)="logout()" i18n="logout|general header@@shared.logout">
                            logout
                        </button>
                    </mat-menu>
                </ng-template>
            </div>
        </ng-container>

        <ng-container *ngIf="!authService?.user && headerData.thumbnail">

            <div class="col-auto ml-auto mr-2">
                <app-select-language [general_header]="true"></app-select-language>
            </div>

            <div class="col-auto login px-0 text-right row no-gutters align-items-center" (click)="login()">
                <div class="col-auto">
                    <i class="icon-user-profile" aria-hidden="true"></i>
                </div>
                <span class="col-auto" i18n="login|general header@@shared.general-header.login">Login</span>
            </div>
        </ng-container>
    </div>
</div>