import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatRadioButton } from '@angular/material/radio';
import { AuthService } from '@app/services';

@Component({
    selector: 'step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.scss'],
})
export class Step2Component implements OnInit {
    @ViewChild('radioButton', { static: false }) radioButton: MatRadioButton;
    @ViewChild('radioButtonSms', { static: false }) radioButtonSms: MatRadioButton;

    @Input() password: string;
    @Input() key: string;

    @Output() typeChange = new EventEmitter<number>();
    @Output() stepChange = new EventEmitter<number>();

    typeForm: UntypedFormGroup;
    userHasMainEmail: boolean = false;
    userHasMainPhone: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private readonly _authService: AuthService,
        private readonly _ngZone: NgZone
    ) {
        this.initForm();

        setTimeout(() => {
            this.radioButton.checked = true;
            this.typeForm.get('type').setValue('1');
        }, 500);

        this.userHasMainEmail = !!this._authService.user.email;
        this.userHasMainPhone = !!this._authService.user.phone;
    }

    ngOnInit(): void {
        this.typeForm.valueChanges.subscribe((fields: any) => {
            this.typeChange.emit(+fields.type);
        });
    }

    changeStep(index: number): void {
        this._viaSms();
        this.stepChange.emit(index);
    }

    private _viaSms(): void {
        if (this.type === 2) {
            this._ngZone.runOutsideAngular(() => {
                this._authService.sendCodeViaSms2StepVerification(this.password, this.key).subscribe();
            });
        }
    }

    private initForm(): void {
        this.typeForm = this.fb.group({
            type: new UntypedFormControl(null, [Validators.required]),
        });
    }

    isSelected(type: number): boolean {
        return this.type === type;
    }

    isTextSelected(type: number): object {
        const isSelected = this.isSelected(+type);

        return {
            'step-1__card--selected': isSelected,
            'step-1__card--non-selected': !isSelected,
        };
    }

    isCardSelected(type: number) {
        return { 'step-1__card--text-selected': this.isSelected(type) };
    }

    checkOption(option: number): void {
        const isOption1: boolean = option === 1 ? true : false;
        this.radioButton.checked = isOption1;
        this.radioButtonSms.checked = !isOption1;
        this.typeForm.get('type').setValue(String(option));
    }

    get type(): number {
        return +this.typeForm.get('type').value;
    }
}
