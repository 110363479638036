import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[debounceClick]',
})
export class DebounceClickPreventerDirective implements OnInit, OnDestroy {
    @Input() debounceTime = 500;
    @Output() debounceClick = new EventEmitter();

    private _clicks = new Subject();
    private _subscription: Subscription;

    constructor() {}

    ngOnInit(): void {
        this._subscription = this._clicks
            .pipe(debounceTime(this.debounceTime))
            .subscribe((e: any) => this.debounceClick.emit(e));
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    @HostListener('click', ['$event'])
    clickEvent(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this._clicks.next(event);
    }
}
