import {  Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { PatientAttachment } from '@app/models/PatientAttachment';
import { environment as env } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class AttachmentsService {
    constructor(private readonly http: HttpClient) {}

    /**
     * Make attachments base url.
     *
     * @param uri
     * @returns string
     */
    private makeUrl(uri: string = ''): string {
        return env.API_URL + 'attachments' + uri;
    }

    /**
     * Get all tags.
     *
     * @link api/v1/patient-portal/attachments/tags
     * @returns Observable<any>
     */
    tags(): Observable<any> {
        return this.http.get(this.makeUrl('/tags')).pipe(
            take(1),
            map((res: any) => res.data)
        );
    }

    /**
     * Get all attachments.
     *
     * @param page
     *
     * @link api/v1/patient-portal/attachments
     * @returns Observable<any>
     */
    get(page: any): Observable<any> {
        return this.http.post(this.makeUrl(''), { page }).pipe(
            map((res: any) => {
                return { data: res.data.map((a: any) => new PatientAttachment(a)), meta: res.meta };
            })
        );
    }

    /**
     * Delete an attachment.
     *
     * @param attachment
     *
     * @link api/v1/patient-portal/attachments/{attachment}/delete
     * @returns Observable<any>
     */
    delete(attachment: PatientAttachment): Observable<any> {
        return this.http.delete(this.makeUrl(`/${attachment.id}/delete`));
    }

    /**
     * Edit an attachment.
     *
     * @param userId
     * @param attachments
     *
     * @link api/v1/patient-portal/attachments/{user}/upload
     * @returns Observable<any>
     */
    upload(userId: string, attachments: Array<PatientAttachment>): Observable<any> {
        return this.http.post(this.makeUrl(`/${userId}/upload`), { attachments });
    }

    /**
     * Download attachments.
     *
     * @link api/v1/patient-portal/attachments/{user}/download/{attachment}
     * @param userId
     * @param attachment
     * @returns Observable<any>
     */
    download(userId: string, attachment: PatientAttachment): Observable<any> {
        return this.http.get(this.makeUrl(`/${userId}/download/${attachment.id}`));
    }
}
