<button mat-button class="mat-dialog-close-btn" (click)="close(false)">
    <mat-icon>close</mat-icon>
</button>

<h3 mat-dialog-title i18n="2 factor auth|Dialog header@@two.factor.auth.header">
    Enable an extra layer of security
</h3>

<mat-dialog-content>
    <mat-horizontal-stepper linear="true" #stepper>

        <!-- step 0 -->
        <mat-step>
            <step0
                *ngIf="stepper?.selectedIndex === 0"
                (stepChange)="onStepChange($event)"
                (closeWindow)="close(false)">
            </step0>
        </mat-step>
        <!-- /step 0 -->

        <!-- step 1 (login) -->
        <mat-step>
            <step1
                *ngIf="stepper?.selectedIndex === 1"
                (onloggedin)="onStepChange($event)"
                (onpassword)="setPassword($event)">
            </step1>
        </mat-step>

        <!-- step 2 -->
        <mat-step [editable]="!passwordValidated">
            <step2
                *ngIf="stepper?.selectedIndex === 2"
                [password]="password"
                [key]="key"
                (typeChange)="typeChange($event)"
                (stepChange)="onStepChange($event)">
            </step2>
        </mat-step>
        <!-- /step 1 -->

        <!-- step 3 -->
        <mat-step>
            <step3
                *ngIf="stepper?.selectedIndex === 3"
                [type]="type"
                [qrCode]="qrCode"
                [key]="key"
                [password]="password"
                (outClose)="close($event)">
            </step3>
        </mat-step>

    </mat-horizontal-stepper>
</mat-dialog-content>