import { Component } from '@angular/core';
import { ActionButtonsService } from '@app/services';
import { BaseComponent } from '@app/shared/base/base.component';

@Component({
    selector: 'app-action-buttons',
    templateUrl: './action-buttons.component.html',
    styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent extends BaseComponent {
    public config: any;

    constructor(private actionButtonsService: ActionButtonsService) {
        super();

        this.config = this.actionButtonsService.buttonsData;

        this.subscriptions.add(
            this.actionButtonsService.actionButtonsDataChanged.subscribe(
                (data) => (this.config = Object.assign(this.config, data))
            )
        );
    }

    set buttonClicked(button: string) {
        this.actionButtonsService.buttonClicked = button;

        if (this.config.callbacks[`${button}CallBack`]) {
            this.config.callbacks[`${button}CallBack`].call();
        }
    }

    /**
     * Verify if the stepper has the action button.
     *
     * @param button (Next/Skip/Previous)
     * @return boolean
     */
    isStepperBtnAvailable(button: string): boolean {
        return this.config.stepperButtons.indexOf(button) !== -1;
    }
}
