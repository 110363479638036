import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SwitchUserComponent } from '../switch-user/switch-user.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, LayoutService } from '@app/services';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent extends BaseComponent {
    desktopLayout = false;
    breakpoints: Array<string> = ['(max-width: 991px)'];

    constructor(
        public readonly authService: AuthService,
        private readonly _router: Router,
        private readonly _layoutService: LayoutService,
        private readonly _breakpointObserver: BreakpointObserver,
        private readonly _dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this._breakpointObserver
                .observe(this.breakpoints)
                .subscribe((result: any) => (this.desktopLayout = !result.matches))
        );
    }

    logout(): void {
        this.toogleMenu();
        this.authService.logout();
    }

    goTo(uri: string = '/'): void {
        this.toogleMenu();
        this._router.navigateByUrl(uri);
    }

    toogleMenu(): void {
        if (this.desktopLayout) {
            return;
        }
        this._layoutService.updateDrawerUserMenuState(true);
    }

    switchUser(): void {
        this.toogleMenu();

        this._dialog.open(SwitchUserComponent, {
            width: '300px',
            maxWidth: '100vw',
            panelClass: 'switch-user-dialog',
        });
    }
}
