<div class="row no-gutters align-items-center justify-content-center">
    <div class="col-auto">
        <form
            class="step-content row no-gutters"
            [formGroup]="twoStepFormGroup">
            <h4
                class="col-12"
                i18n="Two factor auth|Step 1 header@@ste1p.header">
                Insert your password
            </h4>
            <mat-form-field class="col-12">
                <input
                    formControlName="password"
                    #passwordElem
                    matInput
                    [errorStateMatcher]="matcher"
                    type="password"
                    placeholder="password">
                <mat-error
                    *ngIf="twoStepFormGroup.controls.password.hasError('required')"
                    i18n="Two factor auth|Step 1 errors@@general.password is required">
                    password is <strong>required</strong>
                </mat-error>
                <mat-error
                    *ngIf="twoStepFormGroup.controls.password.hasError('server')"
                    i18n="Two factor auth|Step 1 errors@@invalid credentials">
                    Invalid credentials
                </mat-error>
            </mat-form-field>
        </form>
    </div>

    <div class="w-100 my-5 py-5"></div>
    
    <div class="col-auto ml-auto mt-4">
        <button
            mat-raised-button
            class="primary"
            [disabled]="twoStepFormGroup.invalid"
            (click)="validatePassword()"
            i18n="Two factor auth|Step 1 actions@@general.validate">
            validate
        </button>
    </div>
</div>
