import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarningDialogComponent } from './warning-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [WarningDialogComponent],
    imports: [CommonModule, MatButtonModule, MatIconModule],
    exports: [WarningDialogComponent],
})
export class WarningDialogModule {}
