import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { detect } from 'detect-browser';
const browser = detect();

@Injectable({
    providedIn: 'root',
})
export class IosGuard implements CanActivate {
    constructor(private readonly _router: Router) { }

    canActivate(next: ActivatedRouteSnapshot): boolean {
        if (next.data.iosOnly) {
            if (this._isIOS) {
                return true;
            } else {
                this._router.navigate(['/']);
                return false;
            }
        }
    }

    private get _isIOS(): boolean {
        return browser && browser.name !== 'safari' && browser.name !== 'ios' && browser.os === 'iOS';
    }
}
