import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FmFormGroup } from '@app/classes/form/fm-form-group';
import { User } from '@app/models/User';
import { AlertService, AuthService } from '@app/services';
import { Utils } from '@app/utils';
import { ValidatePhone } from '@app/validators/phone.validator';
import { pickBy } from 'lodash';
import { finalize, take } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { CountryPhoneComponent } from '@app/modules/common/country-phone/country-phone.component';
import { BaseComponent } from '@app/shared/base/base.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements DoCheck {
    @Input() shouldRedirect: boolean = true;
    @Output() private loggedSuccessfully: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChildren('countryPhone') private countryPhoneComponent: QueryList<CountryPhoneComponent>;

    loginForm: FmFormGroup = new FmFormGroup({
        email: new UntypedFormControl(null, [Validators.required, Validators.email]),
        country_phone: new UntypedFormControl(null, []),
        phone: new UntypedFormControl(null, [Validators.required, ValidatePhone]),
        password: new UntypedFormControl(null, [Validators.required]),
        code: new UntypedFormControl(null, [Validators.required]),
    });

    validateContactsForm: FmFormGroup = new FmFormGroup({
        email_token: new UntypedFormControl(null),
        phone_token: new UntypedFormControl(null),
    });

    step = 'email';
    otpMethod: 'qrcode' | 'sms';
    authMethod: any;
    authData: any;
    loginResponse: any;
    obfuscatedData: any;
    isRecovering = false;
    shouldDisplayRecoveryPhone = false;
    hidePassword = true;
    displayResendCodesBtn: boolean = false;
    timerToDisplayResendCodesBtn: any;
    missing_validate_contacts_email: any;

    private notAbleToSeeInfoSteps: Array<string> = ['recover-password-message', 'missing-validate-contacts'];

    private doneMissingInfoSteps: Array<string> = [];

    constructor(
        private router: Router,
        private alertService: AlertService,
        private authService: AuthService,
        private readonly _cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngDoCheck(): void {
        this._cdr.detectChanges();
    }

    get isStepInvalid(): boolean {
        if (this.loginForm.get(this.step)) {
            return this.loginForm.get(this.step).invalid;
        }
        return true;
    }

    get isStepAbleToSeeInfo(): boolean {
        return !this.notAbleToSeeInfoSteps.includes(this.step);
    }

    get getData() {
        const data = this.loginForm.value;
        data.phone = Utils.formatPhone(this.loginForm.get('phone').value, this.loginForm.get('country_phone').value);

        if ((this.authMethod === 'phone' && !this.isRecovering) || this.loginForm.get('email').invalid) {
            delete data.email;
        } else if (this.authMethod === 'email' && !this.isRecovering) {
            delete data.phone;
        }
        return pickBy(data);
    }

    nextStep(): void {
        if (this.isRecovering) {
            this.recoverPassword();
        } else if (this.step === 'email' || this.step === 'phone') {
            this.verifyAccount();
        } else {
            this.submitLogin();
        }
    }

    pressEnter(e): void {
        e.preventDefault();
        e.target.blur();
        if (!this.isStepInvalid) {
            this.nextStep();
        }
    }

    forgotPassword(): void {
        this.isRecovering = true;
        this.step = this.authMethod;

        if (this.step === 'phone') {
            this.subscriptions.add(
                this.countryPhoneComponent.changes.subscribe((comps: QueryList<CountryPhoneComponent>) => {
                    if (comps.first) {
                        comps.first.setPhone(this.authData.phone);
                    }
                })
            );
        } else {
            this.shouldDisplayRecoveryPhone = true;
        }
    }

    recoverPassword(): void {
        const data = this.getData;

        if (this.authMethod === 'email') {
            data.login_email = this.authData.email ?? data.email;
            delete data.phone;
        }

        this.authService
            .sendPasswordReset(data)
            .pipe(take(1))
            .subscribe((res: any) => {
                if (res.status === 203 && res.message) {
                    this.alertService.openSnackError(
                        $localize`:Alert|Messages@@contact technical support:${
                            res.message + '\n'
                        }Please contact the technical support`
                    );
                } else if (!res.sent) {
                    this.alertService.openSnackError(
                        $localize`:Alert|Messages@@recover error:Couldn't recover your account. Please verify your input or try another method`
                    );
                } else if (this.authMethod === 'phone') {
                    if (this.shouldRedirect) {
                        this.router.navigate(['login', 'password', 'reset']);
                    } else {
                        this.alertService.openSnack(
                            $localize`:Alert|Messages@@send-to-the-recover-page:We will send you to the recover page, and after that you can close the window and proceed with your booking`,
                            'Ok'
                        );
                        setTimeout(() => window.open('login/password/reset', '_blank'), 4500);
                        this.isRecovering = false;
                    }
                } else {
                    this.step = 'recover-password-message';
                }
            });
    }

    resendValidationCode(): void {
        this.authService
            .resendValidationCode(this.getData)
            .pipe(take(1))
            .subscribe(() =>
                this.alertService.openSnack(
                    $localize`:Alert|Messages@@successfull code sent:Authentication code was successfully sent`
                )
            );
    }

    startCounterToResetdCodes(): void {
        clearTimeout(this.timerToDisplayResendCodesBtn);
        this.displayResendCodesBtn = false;
        this.timerToDisplayResendCodesBtn = setTimeout(() => (this.displayResendCodesBtn = true), 10000);
    }

    update_contact(e: MouseEvent): void {
        e.preventDefault();
        this.router.navigate(['settings']);
    }

    skipStep(e: MouseEvent) {
        e.preventDefault();

        if (!this.shouldRedirect) {
            this.authenticateUser();
        }
    }

    resendContactTokens(e: MouseEvent, type: 'email' | 'phone'): void {
        e.preventDefault();
        this.startCounterToResetdCodes();

        this.authService.resendContactTokens(this._jwt.sub, type).subscribe((res: any) => {
            if (res && res.status) {
                this.alertService.openSnack(
                    $localize`:Alert|Messages@@code sent to contacts:Code was sent to your contacts`
                );
            } else {
                this.alertService.openSnackError(
                    $localize`:Alert|Messages@@too many attempts:Too many attempts, please try again later`
                );
            }
        });
    }

    validateMissingContacts(): void {
        this.authService
            .validateContactTokens(pickBy(this.validateContactsForm.value), this._jwt.sub)
            .pipe(
                take(1),
                finalize(() => this.startCounterToResetdCodes())
            )
            .subscribe(
                (res: any) => {
                    if (res.phone === 0) {
                        this.validateContactsForm.removeControl('email_token');
                        this.loginResponse.missing_validate_contacts.email = false;
                        this.loginResponse.missing_validate_contacts.phone = true;
                    }

                    if (res.email === 0) {
                        this.validateContactsForm.removeControl('phone_token');
                        this.validateContactsForm.get('email_token').setValidators([Validators.required]);
                        this.loginResponse.missing_validate_contacts.phone = false;
                        this.loginResponse.missing_validate_contacts.email = true;
                    }

                    if (res.phone && res.email) {
                        this.loginResponse.missing_validate_contacts.phone = false;
                        this.loginResponse.missing_validate_contacts.email = false;
                        this.alertService.openSnack(
                            $localize`:Alert|Messages@@contacts validated:Contacts were successfully validated`
                        );
                        this.doneMissingInfoStep('missing-validate-contacts');
                    }
                },
                (err: HttpErrorResponse) => {
                    Object.entries(err.error).forEach((error) => {
                        this.validateContactsForm
                            .get(error[0])
                            .setErrors({ server: { value: true, message: error[1] } });
                    });
                }
            );
    }

    doneMissingInfoStep(step: string): void {
        this.doneMissingInfoSteps.push(step);
        this.handleLoginResponse();
    }

    private verifyAccount(): void {
        const success = (res) => {
            this.obfuscatedData = res;
            this.authMethod = this.step;
            this.authData = this.getData;
            this.step = 'password';
        };

        const error = (res) => {
            Object.entries(res.errors).forEach((e) => {
                if (e[1][0] === 'reset-password-by-phone') {
                    if (this.shouldRedirect) {
                        this.router.navigate(['login', 'password', 'reset']);
                    } else {
                        this.alertService.openSnack(
                            $localize`:Alert|Messages@@recover code sms sent:We've sent you an sms with a code to reset your password, and after that you can close the window and proceed with your booking`,
                            'Ok'
                        );
                        setTimeout(() => window.open('login/password/reset', '_blank'), 4500);
                    }
                } else if (e[1][0] === 'reset-password-by-email') {
                    this.alertService.openSnackError(
                        $localize`:Alert|Messages@@recover code email sent:We've sent you an e-mail with a link to reset your password, please follow the included instructions`
                    );
                } else {
                    this.loginForm.get(e[0]).setErrors({ server: { value: true, message: e[1] } });
                }
            });
        };

        this.authService
            .verifyAccount(this.getData)
            .pipe(take(1))
            .subscribe({
                next: (res: any) => Utils.responseHandler(res, success, error),
                error: (res: HttpErrorResponse) => {
                    this.alertService.openSnackError(
                        res?.error?.message || $localize`:Alert|Messages@@unknown error:An error has occurred, please try again`
                    );
                },
            });
    }

    private submitLogin(): void {
        const logged = (res) => {
            this.missing_validate_contacts_email = res.missing_validate_contacts?.email?.email;
            this.loginResponse = res;
            this.authService.setSession(this.loginResponse);
            this.authService.loggedIn.next(true);
            this.authService.popedUp = false;
            this.handleLoginResponse();
        };

        const error = (res) => {
            if (res.errors && res.errors.otp) {
                this.step = 'code';
                this.otpMethod = res.errors.otp;
            } else {
                Object.entries(res.errors).forEach((e) => {
                    this.loginForm.get(e[0]).setErrors({ server: { value: true, message: e[1] } });
                });
            }
        };

        this.authService
            .login(this.getData)
            .pipe(take(1))
            .subscribe({
                next: (res: any) => Utils.responseHandler(res, logged, error),
                error: (res: HttpErrorResponse) => this.alertService.openSnackError(res.error.message),
            });
    }

    private handleLoginResponse(): void {
        if (this.loginResponse.missing_platform_documents) {
            this.router.navigate(['settings', 'freddiemed-terms']);
        } else if (
            this.loginResponse.missing_validate_contacts &&
            !this.doneMissingInfoSteps.includes('missing-validate-contacts')
        ) {
            if (this.loginResponse.missing_validate_contacts.phone) {
                this.validateContactsForm.get('phone_token').setValidators([Validators.required]);
            } else if (this.loginResponse.missing_validate_contacts.email) {
                this.validateContactsForm.get('email_token').setValidators([Validators.required]);
            }
            this.step = 'missing-validate-contacts';
            setTimeout(() => (this.displayResendCodesBtn = true), 10000);
        } else if (!this.shouldRedirect) {
            this.authenticateUser();
        } else {
            const previousUrl = localStorage.getItem('previousUrl');

            if (previousUrl) {
                this.router.navigate([previousUrl]);
                localStorage.removeItem('previousUrl');
            } else {
                this.router.navigate(['/']);
            }
        }
    }

    private authenticateUser(): void {
        this.authService
            .authenticate()
            .pipe(take(1))
            .subscribe({
                next: (res: any) => {
                    this.authService.availableUsers = res.related_users.map((ru) => new User(ru));
                    this.authService.default_user_id = res.app_settings?.default_user_id;
                    this.authService.setUser(res.data);
                    this.loggedSuccessfully.emit(true);
                },
                error: () => {
                    this.alertService.openSnackError(
                        $localize`:Alert|Messages@@unknown error:An error has occurred, please try again`
                    );
                },
            });
    }

    private get _jwt(): any {
        const decodedJtw: Record<string, any> = jwt_decode(this.loginResponse.access_token);
        return decodedJtw;
    }
}
