import { cloneDeep } from 'lodash';
import { User } from '.';

export class PatientAttachment {
    id: number;
    user_id: string | null;
    user?: User;

    document_name: string;

    can_edit: boolean;
    can_delete: boolean;
    can_authorize: boolean;

    shared_patient: boolean;
    shared_practitioners: boolean;

    user_appointment_id: number;
    patient_file_id: number;

    tag: any;
    tag_id: number | null;

    creation_date: Date;
    created_at: Date;

    constructor(data) {
        Object.assign(this, cloneDeep(data));
        this.user = this.user ? new User(this.user) : null;
    }

    get consultationLink(): string | void {
        if (this.user_appointment_id || this.patient_file_id) {
            return this.user_appointment_id
                ? `/consultation/1/${this.user_appointment_id}`
                : `/consultation/2/${this.patient_file_id}`;
        }
    }
}
