import * as _ from 'lodash';
import { AppSettings } from './AppSettings';
import { MedicalGroup } from './MedicalGroup';
import { GlobalVariable } from '@app/global';
import { Slot } from './Slot';
import { professions, specialities } from '@app/services';

export class User {
    id: string;
    title: number;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    date_of_birth: string;
    phone: string;
    avatar: string;
    logo: string;
    cover: string;
    about: null;
    qualifications: any[] = [];
    qualifications_abreviation: any = [];
    insurances?: any = [];
    legal_documents?: any = [];
    language: number;
    gender: any;
    has2step: number;
    emails: any[] = [];
    phones: any[] = [];
    fm_number?: string;
    online: any;
    gmc_number?: string;
    account_status?: string | number | null;
    isPatient: boolean;
    isProfessional: boolean;
    isAdministrative: boolean;
    country?: string;
    created_at: any;
    is_premium: boolean;
    calendar: any = {};
    notification_status: any;
    visible = true;
    accepted_terms_at: boolean;
    preferences: any = {};
    app_settings?: AppSettings;
    responsibles?: any;
    dependents?: any;
    clinics: any = [];
    services: any = [];
    languages: any = [];
    medical_groups: any = [];
    timezone?: string;
    is_dependent?: boolean;

    profession_id: number;
    speciality_id: number;
    professions: any = [];
    relationship?: any;

    constructor(obj) {
        Object.assign(this, _.cloneDeep(obj));
        this.medical_groups = this.medical_groups.map((m) => new MedicalGroup(m));

        this.responsibles = this.responsibles ? this.responsibles.map((r) => new User(r)) : null;

        let mainEmail;
        this.email = this.emails && (mainEmail = this.emails.find((e) => +e.main === 1)) ? mainEmail.email : this.email;

        let mainPhone;
        this.phone =
            this.phones && (mainPhone = this.phones.find((e) => +e.main === 1)) ? mainPhone.phone_number : this.phone;

        if (!this.calendar) {
            this.calendar = {};
        }
        this.calendar.name = GlobalVariable.calendar_name;
        this.calendar.slots = this.calendar.slots ? this.calendar.slots.map((s) => new Slot(s)) : [];

        this.updateProfession();
    }

    updateProfession() {
        if (this.profession_id != null) {
            const profession = Object.create(professions.find(p => p.id === this.profession_id));
            if (this.speciality_id != null) {
                const speciality = specialities.find(s => s.id === this.speciality_id);
                profession.speciality = speciality ? Object.create(speciality) : { id: this.speciality_id, snomed_term: 'N/A', name: 'N/A', external_id: null, main: 0 };
            }
            this.professions = [profession];
        }
    }

    findMedicalGroup(groupId) {
        return this.medical_groups.find((cc) => +cc.id === +groupId);
    }

    get correspondenceEmail() {
        let correspondenceEmail;
        return this.phones &&
            (correspondenceEmail = this.emails.find((e) => +e.correspondence === 1 && +e.status === 1))
            ? correspondenceEmail.email
            : this.email;
    }

    get mainAndPublicPhone() {
        let mainPhone;
        return this.phones && (mainPhone = this.phones.find((e) => +e.main === 1 && +e.public === 1 && +e.status === 1))
            ? mainPhone.phone_number
            : null;
    }

    get profession() {
        return this.professions ? this.professions[0] : null;
    }

    get specialities() {
        return [this.speciality];
    }

    get speciality() {
        return this.profession.speciality ?? null;
    }

    get thumbnail() {
        return (this.first_name && this.last_name) || this.email
            ? 'letter-' +
            (this.first_name ? this.first_name : this.email)[0].toLowerCase() +
            '-' +
            (this.last_name ? this.last_name : this.email)[0].toLowerCase()
            : '';
    }

    get currency(): string {
        return '£';
        /*
        return this.app_settings && this.app_settings.currency ?
            currencies.find(c => c.code === this.app_settings.currency).symbol : '£';
        */
    }

    get servicesByProfession() {
        return this.services.filter(
            (s) =>
                +s.profession_id === +this.profession.id &&
                (!this.profession.speciality || this.profession.speciality.id === s.speciality_id)
        );
    }

    /**
     * Check if an user has access to another user.
     *
     * If the provided user is himself
     * Responsible > Dependent
     * Dependent > Dependent
     *
     * @param User user
     * @returns boolean
     */
    hasAccessTo(user: User): boolean {
        return (
            this.id === user.id ||
            (this.dependents?.length && this.dependents.some((d) => d.id === user.id)) ||
            (user.hasOwnProperty('responsibles') &&
                user.responsibles.length &&
                user.responsibles.some(
                    (ur) => user.responsibles.length && user.responsibles.some((tr) => tr.id === ur.id)
                ))
        );
    }
}
