import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@app/shared/base/base.component';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent extends BaseComponent {

    current_year = new Date().getFullYear();
    constructor(
        private readonly _router: Router
    ) {
        super();
    }

    goToLogin(): void {
        this._router.navigate(['']);
    }
}
