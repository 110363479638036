<div class="user_menu flex-column row no-gutters active" (swiperight)="toogleMenu()">
    <!-- Mobile menu -->
    <ng-container *ngIf="!desktopLayout">
        <div class="col-auto logo row align-items-center d-lg-none">
            <div class="col-auto ml-auto row px-0 align-items-center">
                <div class="col-auto ml-auto name px-0 text-right">
                    <span>{{ authService?.user?.title | title }}</span>
                    <br />
                    <span>{{ authService?.user | name: false }}</span>
                </div>
                <div class="col-auto ml-auto">
                    <app-thumbnail [user]="authService.user" [size]="'small'" (click)="toogleMenu()"> </app-thumbnail>
                </div>
            </div>
        </div>
        <div class="col col-lg-auto">
            <ul class="links">
                <li *ngIf="authService.availableUsers.length">
                    <a class="switch" (click)="switchUser()">
                        <ng-container i18n="switch user|user-menu@@shared.switch user"> switch user</ng-container>
                        <i class="icon-fm-switch-user" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a
                        routerLinkActive="active"
                        [routerLink]="['/settings']"
                        (click)="toogleMenu()"
                        [routerLinkActiveOptions]="{ exact: true }"
                        i18n="account|user-menu@@shared.account"
                    >
                        account
                    </a>
                </li>
                <li>
                    <a
                        routerLinkActive="active"
                        [routerLink]="['/settings/payment-details']"
                        (click)="toogleMenu()"
                        i18n="payments|user-menu@@shared.payments"
                    >
                        payments
                    </a>
                </li>
                <li class="mb-0">
                    <a
                        routerLinkActive="active"
                        [routerLink]="['/settings/legal-documents']"
                        (click)="toogleMenu()"
                        i18n="legal documents|user-menu@@general.legal documents"
                    >
                        legal documents
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-auto">
            <ul class="links">
                <li class="logout mt-lg-0">
                    <a (click)="logout()" i18n="logout|user-menu@@shared.logout">logout</a>
                </li>
            </ul>
        </div>
    </ng-container>
</div>
