import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FmFormGroup } from '@app/classes/form/fm-form-group';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PasswordConfirmation } from '@app/validators/password-confirmation';
import { FmPasswordMatcher } from '@app/error-state-matchers/fm-password-matcher';
import { Router } from '@angular/router';
import { GlobalVariable } from '@app/global';
import { AlertService, AuthService } from '@app/services';
import { strongPasswordValidator } from '@app/validators/strong-password';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
    passwordMatcher: FmPasswordMatcher = new FmPasswordMatcher();
    resetForm: FmFormGroup;
    token: string = null;
    invalidToken = false;
    minLength = 0;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private alertService: AlertService
    ) {
        this.resetForm = new FmFormGroup(
            {
                token: new UntypedFormControl(null, [Validators.required, Validators.minLength(2)]),
                password: new UntypedFormControl(null, [
                    Validators.required,
                    Validators.minLength(GlobalVariable.PASSWORD_LENGTH),
                    strongPasswordValidator,
                ]),
                password_confirmation: new UntypedFormControl(null, [
                    Validators.required,
                    Validators.minLength(GlobalVariable.PASSWORD_LENGTH),
                ]),
            },
            PasswordConfirmation()
        );

        this.token = this.route.snapshot.paramMap.get('token');

        if (this.token) {
            this._verifyToken(this.token);
        }
        this.minLength = GlobalVariable.PASSWORD_LENGTH;
    }

    submit(): void {
        this.authService.resetPassword(this.resetForm.value).subscribe((res: any) => {
            if (res.errors) {
                Object.entries(res.errors).forEach((error) => {
                    this.alertService.openSnackError(error[1][0]);
                });
            } else {
                this.alertService.openSnack($localize`:Alert|Messages@@password updated:Password updated successfully`);
                this.router.navigateByUrl('/login');
            }
        });
    }

    private _verifyToken(token: string): void {
        this.authService.validateResetToken(token).subscribe((res: any) => {
            if (res.status) {
                this.resetForm.controls.token.setValue(token);
            } else {
                this.invalidToken = true;
            }
        });
    }
}
