import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FmErrorMatcher } from '@app/error-state-matchers/fm-error-matcher';
import { AlertService, AuthService } from '@app/services';

@Component({
    selector: 'step3',
    templateUrl: './step3.component.html',
    styleUrls: ['./step3.component.scss'],
})
export class Step3Component implements OnChanges {
    @Input() type = 1;
    @Input() key: string;
    @Input() qrCode: string;
    @Input() password: string;

    @Output() outClose = new EventEmitter<boolean>();
    @Output() outBack = new EventEmitter<number>();

    private codeMinLength = 6;

    matcher = new FmErrorMatcher();
    code = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);
    userPhone: string;
    resendDisabled = false;
    timer = null;

    seconds: string = $localize`:@@2 factor auth Step 3 time measure:seconds`;
    twoStepActivated: string = $localize`:@@2 step activated message:2 step authentication activated`;

    constructor(private authService: AuthService, private readonly _alertService: AlertService) {}

    ngOnChanges(): void {
        if (this.type === undefined) {
            this.type = 1;
        }

        if (this.type === 2) {
            this.userPhone = this.authService.user.phone;
        }
    }

    codeKeyUp(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.validate();
            return;
        }

        if (!/\d+/.test(event.key)) {
            this.code.setValue(this.code.value.substr(0, this.code.value.length - 1), { emitEvent: false });
        }

        if (this.code.value.length > 6) {
            this.code.setValue(this.code.value.substr(0, 6), { emitEvent: false });
        }
    }

    validate(): void {
        if (this.code.value.length < this.codeMinLength) {
            this.code.setErrors({ server: { value: true } });
        }
        if (this.code.valid) {
            this.authService
                .enable2StepVerification({
                    password: this.password,
                    code: this.code.value,
                    key: this.key,
                    type: this.parsedType,
                })

                .subscribe((res: any) => {
                    if (res.status) {
                        this._alertService.openSnack(this.twoStepActivated);
                        this.close(true);
                    } else {
                        this.code.setValue('', { emitEvent: false });
                        this.code.setErrors({ server: { value: true } });
                    }
                });
        }
    }

    back(stepperIndex: number): void {
        this.outBack.emit(stepperIndex);
    }

    resendOTP(): void {
        if (this.timer !== null) {
            return;
        }

        this.authService
            .sendCodeViaSms2StepVerification(this.password, this.key)
            .subscribe(() => {
                this.resendDisabled = true;
                this.timer = 30;

                const localTimer = setInterval(() => {
                    this.timer--;

                    if (this.timer === 0) {
                        clearInterval(localTimer);
                        this.resendDisabled = false;
                        this.timer = null;
                    }
                }, 1000);
            });
    }

    private close(enabled: boolean): void {
        this.outClose.emit(enabled);
    }

    get codeInvalid() {
        return this.code.hasError('server');
    }

    get disabledResend(): object {
        return { 'step-2__card--resend-btn--disabled': this.resendDisabled };
    }

    get timerLeft(): string {
        return this.timer !== null ? `${this.timer} ${this.seconds}` : '';
    }

    get parsedType(): string {
        return +this.type === 1 ? 'qrcode' : 'sms';
    }
}
