import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@app/models';

@Pipe({
    name: 'mainSpeciality',
})
export class MainSpecialityPipe implements PipeTransform {
    transform(user: User, defaultValue: string = 'N/A'): string {
        if (!user || !user.profession) {
            return defaultValue;
        }
        return user.profession.speciality ? user.profession.speciality.snomed_term : user.profession.snomed_term;
    }
}
