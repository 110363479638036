import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UntypedFormControl, AbstractControl } from '@angular/forms';
import { ProcessedCountryCode } from '@app/countries_processed';
import { distinctUntilChanged } from 'rxjs/operators';
import { Utils } from '@app/utils';
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
import { GlobalVariable } from '@app/global';
import { BaseComponent } from '@app/shared/base/base.component';

@Component({
    selector: 'app-country-phone',
    templateUrl: './country-phone.component.html',
    styleUrls: ['./country-phone.component.scss'],
    providers: [ProcessedCountryCode]
})
export class CountryPhoneComponent extends BaseComponent implements OnInit, AfterViewInit {

    @Input() country: AbstractControl | UntypedFormControl = new UntypedFormControl();
    @Input() phone: AbstractControl | UntypedFormControl = new UntypedFormControl();
    @Input() defaultCountry = GlobalVariable.default_country;
    @Input() fadeIn = false;
    @Input() readonly = false;
    @Input() requiredMessage = null;
    @Input() autofocus = false;

    oldDialCode;
    allCountries: Array<any> = [];

    constructor(
        private readonly countryCodeData: ProcessedCountryCode,
        private readonly cdr: ChangeDetectorRef,
    ) {
        super();
        this.allCountries = this.countryCodeData.allCountries;
    }

    ngOnInit() {
        this.reset();
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.country.valueChanges
                .pipe(distinctUntilChanged())
                .subscribe(() => {
                    if (this.phone.value) {
                        this.country.updateValueAndValidity();
                        let value = this.phone.value;
                        if (value.indexOf('00') === 0) {
                            value = value.substring(2);
                        }
                        if (value.indexOf('+') === 0) {
                            value = value.replace('+', '');
                        }
                        if (value.indexOf(this.oldDialCode) === 0) {
                            this.phone.setValue(value.replace(this.oldDialCode, ''));
                        }
                        this.checkValue();
                        this.oldDialCode = this.country.value.dialCode;
                    }
                })
        );
        this.subscriptions.add(
            this.phone.valueChanges
                .pipe(distinctUntilChanged())
                .subscribe(() => {
                    this.checkValue();
                })
        );
    }

    checkValue(number = this.phone.value): any {
        if (number && this.country.value) {
            let replaced = false;

            if (number.indexOf('00') === 0) {
                number = number.substring(2);
                replaced = true;
            }

            if (number.indexOf('+') === 0) {
                number = number.replace('+', '');
                replaced = true;
            }

            if (replaced) {
                const country = this.allCountries.find(c => number.indexOf(c.dialCode) === 0 && c.priority === 0);
                if (country) {
                    this.country.setValue(country);
                }
            }
            const phoneNumber = Utils.formatPhone(this.phone.value, this.country.value);
            if (!phoneNumber) {
                this.phone.setErrors({ invalidPhone: true });
            } else {
                this.phone.setValue(phoneNumber);
                this.phone.setErrors({ invalidPhone: null });
                this.phone.updateValueAndValidity();
            }
            return phoneNumber;
        }
        return null;
    }

    setValue(countryCode): void {
        this.country.setValue(this.allCountries.find(c => countryCode.indexOf(c.dialCode) === 0 && c.priority === 0));
    }

    setValueByIso(isoCode): void {
        this.country.setValue(this.allCountries.find(c => c.iso2.indexOf(isoCode) !== -1));
    }

    setPhone(phone) {
        if (phone) {
            const newPhone = isValidNumber(phone) ? parsePhoneNumber(phone) : null;
            if (newPhone) {
                this.setValue(newPhone.countryCallingCode);
                this.phone.setValue(newPhone.nationalNumber);
                this.cdr.detectChanges();
            }
        }
    }

    reset(): void {
        this.country.setValue(this.allCountries.find(c => c.iso2 === this.defaultCountry));
        this.phone.setValue(null);
        this.oldDialCode = this.country.value.dialCode;
    }
}
