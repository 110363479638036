import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    headerDataChanged = new BehaviorSubject<any>(null);
    loadedListComponentChanged = new BehaviorSubject<any>(null);

    constructor() { }

    set headerData({ text = '', icon = '', thumbnail = true, goBackCallback = null }) {
        this.headerDataChanged.next({ text, icon, thumbnail, goBackCallback });
    }

    get headerData() {
        return this.headerDataChanged.value;
    }

    set loadedList(data) {
        this.loadedListComponentChanged.next(data);
    }

    get loadedList() {
        return this.loadedListComponentChanged.value;
    }
}
