import { Injectable } from "@angular/core";
@Injectable()
export class ProcessedCountryCode {
  public allCountries = [{
    'name': 'Afghanistan (‫افغانستان‬‎)',
    'iso2': 'af',
    'dialCode': '93',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'af'
  }, {
    'name': 'Albania (Shqipëri)',
    'iso2': 'al',
    'dialCode': '355',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'al'
  }, {
    'name': 'Algeria (‫الجزائر‬‎)',
    'iso2': 'dz',
    'dialCode': '213',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'dz'
  }, {
    'name': 'American Samoa',
    'iso2': 'as',
    'dialCode': '1684',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'as'
  }, {
    'name': 'Andorra',
    'iso2': 'ad',
    'dialCode': '376',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ad'
  }, {
    'name': 'Angola',
    'iso2': 'ao',
    'dialCode': '244',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ao'
  }, {
    'name': 'Anguilla',
    'iso2': 'ai',
    'dialCode': '1264',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ai'
  }, {
    'name': 'Antigua and Barbuda',
    'iso2': 'ag',
    'dialCode': '1268',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ag'
  }, {
    'name': 'Argentina',
    'iso2': 'ar',
    'dialCode': '54',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ar'
  }, {
    'name': 'Armenia (Հայաստան)',
    'iso2': 'am',
    'dialCode': '374',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'am'
  }, {
    'name': 'Aruba',
    'iso2': 'aw',
    'dialCode': '297',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'aw'
  }, {
    'name': 'Australia',
    'iso2': 'au',
    'dialCode': '61',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'au'
  }, {
    'name': 'Austria (Österreich)',
    'iso2': 'at',
    'dialCode': '43',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'at'
  }, {
    'name': 'Azerbaijan (Azərbaycan)',
    'iso2': 'az',
    'dialCode': '994',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'az'
  }, {
    'name': 'Bahamas',
    'iso2': 'bs',
    'dialCode': '1242',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bs'
  }, {
    'name': 'Bahrain (‫البحرين‬‎)',
    'iso2': 'bh',
    'dialCode': '973',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bh'
  }, {
    'name': 'Bangladesh (বাংলাদেশ)',
    'iso2': 'bd',
    'dialCode': '880',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bd'
  }, {
    'name': 'Barbados',
    'iso2': 'bb',
    'dialCode': '1246',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bb'
  }, {
    'name': 'Belarus (Беларусь)',
    'iso2': 'by',
    'dialCode': '375',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'by'
  }, {
    'name': 'Belgium (België)',
    'iso2': 'be',
    'dialCode': '32',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'be'
  }, {
    'name': 'Belize',
    'iso2': 'bz',
    'dialCode': '501',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bz'
  }, {
    'name': 'Benin (Bénin)',
    'iso2': 'bj',
    'dialCode': '229',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bj'
  }, {
    'name': 'Bermuda',
    'iso2': 'bm',
    'dialCode': '1441',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bm'
  }, {
    'name': 'Bhutan (འབྲུག)',
    'iso2': 'bt',
    'dialCode': '975',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bt'
  }, {
    'name': 'Bolivia',
    'iso2': 'bo',
    'dialCode': '591',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bo'
  }, {
    'name': 'Bosnia and Herzegovina (Босна и Херцеговина)',
    'iso2': 'ba',
    'dialCode': '387',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ba'
  }, {
    'name': 'Botswana',
    'iso2': 'bw',
    'dialCode': '267',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bw'
  }, {
    'name': 'Brazil (Brasil)',
    'iso2': 'br',
    'dialCode': '55',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'br'
  }, {
    'name': 'British Indian Ocean Territory',
    'iso2': 'io',
    'dialCode': '246',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'io'
  }, {
    'name': 'British Virgin Islands',
    'iso2': 'vg',
    'dialCode': '1284',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'vg'
  }, {
    'name': 'Brunei',
    'iso2': 'bn',
    'dialCode': '673',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bn'
  }, {
    'name': 'Bulgaria (България)',
    'iso2': 'bg',
    'dialCode': '359',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bg'
  }, {
    'name': 'Burkina Faso',
    'iso2': 'bf',
    'dialCode': '226',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bf'
  }, {
    'name': 'Burundi (Uburundi)',
    'iso2': 'bi',
    'dialCode': '257',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'bi'
  }, {
    'name': 'Cambodia (កម្ពុជា)',
    'iso2': 'kh',
    'dialCode': '855',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'kh'
  }, {
    'name': 'Cameroon (Cameroun)',
    'iso2': 'cm',
    'dialCode': '237',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cm'
  }, {
    'name': 'Canada',
    'iso2': 'ca',
    'dialCode': '1',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'ca'
  }, {
    'name': 'Cape Verde (Kabu Verdi)',
    'iso2': 'cv',
    'dialCode': '238',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cv'
  }, {
    'name': 'Caribbean Netherlands',
    'iso2': 'bq',
    'dialCode': '599',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'bq'
  }, {
    'name': 'Cayman Islands',
    'iso2': 'ky',
    'dialCode': '1345',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ky'
  }, {
    'name': 'Central African Republic (République centrafricaine)',
    'iso2': 'cf',
    'dialCode': '236',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cf'
  }, {
    'name': 'Chad (Tchad)',
    'iso2': 'td',
    'dialCode': '235',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'td'
  }, {
    'name': 'Chile',
    'iso2': 'cl',
    'dialCode': '56',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cl'
  }, {
    'name': 'China (中国)',
    'iso2': 'cn',
    'dialCode': '86',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cn'
  }, {
    'name': 'Christmas Island',
    'iso2': 'cx',
    'dialCode': '61',
    'priority': 2,
    'areaCode': null,
    'flagClass': 'cx'
  }, {
    'name': 'Cocos (Keeling) Islands',
    'iso2': 'cc',
    'dialCode': '61',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'cc'
  }, {
    'name': 'Colombia',
    'iso2': 'co',
    'dialCode': '57',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'co'
  }, {
    'name': 'Comoros (‫جزر القمر‬‎)',
    'iso2': 'km',
    'dialCode': '269',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'km'
  }, {
    'name': 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    'iso2': 'cd',
    'dialCode': '243',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cd'
  }, {
    'name': 'Congo (Republic) (Congo-Brazzaville)',
    'iso2': 'cg',
    'dialCode': '242',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cg'
  }, {
    'name': 'Cook Islands',
    'iso2': 'ck',
    'dialCode': '682',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ck'
  }, {
    'name': 'Costa Rica',
    'iso2': 'cr',
    'dialCode': '506',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cr'
  }, {
    'name': 'Côte d’Ivoire',
    'iso2': 'ci',
    'dialCode': '225',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ci'
  }, {
    'name': 'Croatia (Hrvatska)',
    'iso2': 'hr',
    'dialCode': '385',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'hr'
  }, {
    'name': 'Cuba',
    'iso2': 'cu',
    'dialCode': '53',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cu'
  }, {
    'name': 'Curaçao',
    'iso2': 'cw',
    'dialCode': '599',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cw'
  }, {
    'name': 'Cyprus (Κύπρος)',
    'iso2': 'cy',
    'dialCode': '357',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cy'
  }, {
    'name': 'Czech Republic (Česká republika)',
    'iso2': 'cz',
    'dialCode': '420',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'cz'
  }, {
    'name': 'Denmark (Danmark)',
    'iso2': 'dk',
    'dialCode': '45',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'dk'
  }, {
    'name': 'Djibouti',
    'iso2': 'dj',
    'dialCode': '253',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'dj'
  }, {
    'name': 'Dominica',
    'iso2': 'dm',
    'dialCode': '1767',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'dm'
  }, {
    'name': 'Dominican Republic (República Dominicana)',
    'iso2': 'do',
    'dialCode': '1',
    'priority': 2,
    'areaCode': null,
    'flagClass': 'do'
  }, {
    'name': 'Ecuador',
    'iso2': 'ec',
    'dialCode': '593',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ec'
  }, {
    'name': 'Egypt (‫مصر‬‎)',
    'iso2': 'eg',
    'dialCode': '20',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'eg'
  }, {
    'name': 'El Salvador',
    'iso2': 'sv',
    'dialCode': '503',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sv'
  }, {
    'name': 'Equatorial Guinea (Guinea Ecuatorial)',
    'iso2': 'gq',
    'dialCode': '240',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gq'
  }, {
    'name': 'Eritrea',
    'iso2': 'er',
    'dialCode': '291',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'er'
  }, {
    'name': 'Estonia (Eesti)',
    'iso2': 'ee',
    'dialCode': '372',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ee'
  }, {
    'name': 'Ethiopia',
    'iso2': 'et',
    'dialCode': '251',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'et'
  }, {
    'name': 'Falkland Islands (Islas Malvinas)',
    'iso2': 'fk',
    'dialCode': '500',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'fk'
  }, {
    'name': 'Faroe Islands (Føroyar)',
    'iso2': 'fo',
    'dialCode': '298',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'fo'
  }, {
    'name': 'Fiji',
    'iso2': 'fj',
    'dialCode': '679',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'fj'
  }, {
    'name': 'Finland (Suomi)',
    'iso2': 'fi',
    'dialCode': '358',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'fi'
  }, {
    'name': 'France',
    'iso2': 'fr',
    'dialCode': '33',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'fr'
  }, {
    'name': 'French Guiana (Guyane française)',
    'iso2': 'gf',
    'dialCode': '594',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gf'
  }, {
    'name': 'French Polynesia (Polynésie française)',
    'iso2': 'pf',
    'dialCode': '689',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'pf'
  }, {
    'name': 'Gabon',
    'iso2': 'ga',
    'dialCode': '241',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ga'
  }, {
    'name': 'Gambia',
    'iso2': 'gm',
    'dialCode': '220',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gm'
  }, {
    'name': 'Georgia (საქართველო)',
    'iso2': 'ge',
    'dialCode': '995',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ge'
  }, {
    'name': 'Germany (Deutschland)',
    'iso2': 'de',
    'dialCode': '49',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'de'
  }, {
    'name': 'Ghana (Gaana)',
    'iso2': 'gh',
    'dialCode': '233',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gh'
  }, {
    'name': 'Gibraltar',
    'iso2': 'gi',
    'dialCode': '350',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gi'
  }, {
    'name': 'Greece (Ελλάδα)',
    'iso2': 'gr',
    'dialCode': '30',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gr'
  }, {
    'name': 'Greenland (Kalaallit Nunaat)',
    'iso2': 'gl',
    'dialCode': '299',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gl'
  }, {
    'name': 'Grenada',
    'iso2': 'gd',
    'dialCode': '1473',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gd'
  }, {
    'name': 'Guadeloupe',
    'iso2': 'gp',
    'dialCode': '590',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gp'
  }, {
    'name': 'Guam',
    'iso2': 'gu',
    'dialCode': '1671',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gu'
  }, {
    'name': 'Guatemala',
    'iso2': 'gt',
    'dialCode': '502',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gt'
  }, {
    'name': 'Guernsey',
    'iso2': 'gg',
    'dialCode': '44',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'gg'
  }, {
    'name': 'Guinea (Guinée)',
    'iso2': 'gn',
    'dialCode': '224',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gn'
  }, {
    'name': 'Guinea-Bissau (Guiné Bissau)',
    'iso2': 'gw',
    'dialCode': '245',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gw'
  }, {
    'name': 'Guyana',
    'iso2': 'gy',
    'dialCode': '592',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gy'
  }, {
    'name': 'Haiti',
    'iso2': 'ht',
    'dialCode': '509',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ht'
  }, {
    'name': 'Honduras',
    'iso2': 'hn',
    'dialCode': '504',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'hn'
  }, {
    'name': 'Hong Kong (香港)',
    'iso2': 'hk',
    'dialCode': '852',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'hk'
  }, {
    'name': 'Hungary (Magyarország)',
    'iso2': 'hu',
    'dialCode': '36',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'hu'
  }, {
    'name': 'Iceland (Ísland)',
    'iso2': 'is',
    'dialCode': '354',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'is'
  }, {
    'name': 'India (भारत)',
    'iso2': 'in',
    'dialCode': '91',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'in'
  }, {
    'name': 'Indonesia',
    'iso2': 'id',
    'dialCode': '62',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'id'
  }, {
    'name': 'Iran (‫ایران‬‎)',
    'iso2': 'ir',
    'dialCode': '98',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ir'
  }, {
    'name': 'Iraq (‫العراق‬‎)',
    'iso2': 'iq',
    'dialCode': '964',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'iq'
  }, {
    'name': 'Ireland',
    'iso2': 'ie',
    'dialCode': '353',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ie'
  }, {
    'name': 'Isle of Man',
    'iso2': 'im',
    'dialCode': '44',
    'priority': 2,
    'areaCode': null,
    'flagClass': 'im'
  }, {
    'name': 'Israel (‫ישראל‬‎)',
    'iso2': 'il',
    'dialCode': '972',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'il'
  }, {
    'name': 'Italy (Italia)',
    'iso2': 'it',
    'dialCode': '39',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'it'
  }, {
    'name': 'Jamaica',
    'iso2': 'jm',
    'dialCode': '1876',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'jm'
  }, {
    'name': 'Japan (日本)',
    'iso2': 'jp',
    'dialCode': '81',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'jp'
  }, {
    'name': 'Jersey',
    'iso2': 'je',
    'dialCode': '44',
    'priority': 3,
    'areaCode': null,
    'flagClass': 'je'
  }, {
    'name': 'Jordan (‫الأردن‬‎)',
    'iso2': 'jo',
    'dialCode': '962',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'jo'
  }, {
    'name': 'Kazakhstan (Казахстан)',
    'iso2': 'kz',
    'dialCode': '7',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'kz'
  }, {
    'name': 'Kenya',
    'iso2': 'ke',
    'dialCode': '254',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ke'
  }, {
    'name': 'Kiribati',
    'iso2': 'ki',
    'dialCode': '686',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ki'
  }, {
    'name': 'Kosovo',
    'iso2': 'xk',
    'dialCode': '383',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'xk'
  }, {
    'name': 'Kuwait (‫الكويت‬‎)',
    'iso2': 'kw',
    'dialCode': '965',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'kw'
  }, {
    'name': 'Kyrgyzstan (Кыргызстан)',
    'iso2': 'kg',
    'dialCode': '996',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'kg'
  }, {
    'name': 'Laos (ລາວ)',
    'iso2': 'la',
    'dialCode': '856',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'la'
  }, {
    'name': 'Latvia (Latvija)',
    'iso2': 'lv',
    'dialCode': '371',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'lv'
  }, {
    'name': 'Lebanon (‫لبنان‬‎)',
    'iso2': 'lb',
    'dialCode': '961',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'lb'
  }, {
    'name': 'Lesotho',
    'iso2': 'ls',
    'dialCode': '266',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ls'
  }, {
    'name': 'Liberia',
    'iso2': 'lr',
    'dialCode': '231',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'lr'
  }, {
    'name': 'Libya (‫ليبيا‬‎)',
    'iso2': 'ly',
    'dialCode': '218',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ly'
  }, {
    'name': 'Liechtenstein',
    'iso2': 'li',
    'dialCode': '423',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'li'
  }, {
    'name': 'Lithuania (Lietuva)',
    'iso2': 'lt',
    'dialCode': '370',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'lt'
  }, {
    'name': 'Luxembourg',
    'iso2': 'lu',
    'dialCode': '352',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'lu'
  }, {
    'name': 'Macau (澳門)',
    'iso2': 'mo',
    'dialCode': '853',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mo'
  }, {
    'name': 'Macedonia (FYROM) (Македонија)',
    'iso2': 'mk',
    'dialCode': '389',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mk'
  }, {
    'name': 'Madagascar (Madagasikara)',
    'iso2': 'mg',
    'dialCode': '261',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mg'
  }, {
    'name': 'Malawi',
    'iso2': 'mw',
    'dialCode': '265',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mw'
  }, {
    'name': 'Malaysia',
    'iso2': 'my',
    'dialCode': '60',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'my'
  }, {
    'name': 'Maldives',
    'iso2': 'mv',
    'dialCode': '960',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mv'
  }, {
    'name': 'Mali',
    'iso2': 'ml',
    'dialCode': '223',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ml'
  }, {
    'name': 'Malta',
    'iso2': 'mt',
    'dialCode': '356',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mt'
  }, {
    'name': 'Marshall Islands',
    'iso2': 'mh',
    'dialCode': '692',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mh'
  }, {
    'name': 'Martinique',
    'iso2': 'mq',
    'dialCode': '596',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mq'
  }, {
    'name': 'Mauritania (‫موريتانيا‬‎)',
    'iso2': 'mr',
    'dialCode': '222',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mr'
  }, {
    'name': 'Mauritius (Moris)',
    'iso2': 'mu',
    'dialCode': '230',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mu'
  }, {
    'name': 'Mayotte',
    'iso2': 'yt',
    'dialCode': '262',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'yt'
  }, {
    'name': 'Mexico (México)',
    'iso2': 'mx',
    'dialCode': '52',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mx'
  }, {
    'name': 'Micronesia',
    'iso2': 'fm',
    'dialCode': '691',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'fm'
  }, {
    'name': 'Moldova (Republica Moldova)',
    'iso2': 'md',
    'dialCode': '373',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'md'
  }, {
    'name': 'Monaco',
    'iso2': 'mc',
    'dialCode': '377',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mc'
  }, {
    'name': 'Mongolia (Монгол)',
    'iso2': 'mn',
    'dialCode': '976',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mn'
  }, {
    'name': 'Montenegro (Crna Gora)',
    'iso2': 'me',
    'dialCode': '382',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'me'
  }, {
    'name': 'Montserrat',
    'iso2': 'ms',
    'dialCode': '1664',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ms'
  }, {
    'name': 'Morocco (‫المغرب‬‎)',
    'iso2': 'ma',
    'dialCode': '212',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ma'
  }, {
    'name': 'Mozambique (Moçambique)',
    'iso2': 'mz',
    'dialCode': '258',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mz'
  }, {
    'name': 'Myanmar (Burma) (မြန်မာ)',
    'iso2': 'mm',
    'dialCode': '95',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mm'
  }, {
    'name': 'Namibia (Namibië)',
    'iso2': 'na',
    'dialCode': '264',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'na'
  }, {
    'name': 'Nauru',
    'iso2': 'nr',
    'dialCode': '674',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'nr'
  }, {
    'name': 'Nepal (नेपाल)',
    'iso2': 'np',
    'dialCode': '977',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'np'
  }, {
    'name': 'Netherlands (Nederland)',
    'iso2': 'nl',
    'dialCode': '31',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'nl'
  }, {
    'name': 'New Caledonia (Nouvelle-Calédonie)',
    'iso2': 'nc',
    'dialCode': '687',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'nc'
  }, {
    'name': 'New Zealand',
    'iso2': 'nz',
    'dialCode': '64',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'nz'
  }, {
    'name': 'Nicaragua',
    'iso2': 'ni',
    'dialCode': '505',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ni'
  }, {
    'name': 'Niger (Nijar)',
    'iso2': 'ne',
    'dialCode': '227',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ne'
  }, {
    'name': 'Nigeria',
    'iso2': 'ng',
    'dialCode': '234',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ng'
  }, {
    'name': 'Niue',
    'iso2': 'nu',
    'dialCode': '683',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'nu'
  }, {
    'name': 'Norfolk Island',
    'iso2': 'nf',
    'dialCode': '672',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'nf'
  }, {
    'name': 'North Korea (조선 민주주의 인민 공화국)',
    'iso2': 'kp',
    'dialCode': '850',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'kp'
  }, {
    'name': 'Northern Mariana Islands',
    'iso2': 'mp',
    'dialCode': '1670',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'mp'
  }, {
    'name': 'Norway (Norge)',
    'iso2': 'no',
    'dialCode': '47',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'no'
  }, {
    'name': 'Oman (‫عُمان‬‎)',
    'iso2': 'om',
    'dialCode': '968',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'om'
  }, {
    'name': 'Pakistan (‫پاکستان‬‎)',
    'iso2': 'pk',
    'dialCode': '92',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'pk'
  }, {
    'name': 'Palau',
    'iso2': 'pw',
    'dialCode': '680',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'pw'
  }, {
    'name': 'Palestine (‫فلسطين‬‎)',
    'iso2': 'ps',
    'dialCode': '970',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ps'
  }, {
    'name': 'Panama (Panamá)',
    'iso2': 'pa',
    'dialCode': '507',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'pa'
  }, {
    'name': 'Papua New Guinea',
    'iso2': 'pg',
    'dialCode': '675',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'pg'
  }, {
    'name': 'Paraguay',
    'iso2': 'py',
    'dialCode': '595',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'py'
  }, {
    'name': 'Peru (Perú)',
    'iso2': 'pe',
    'dialCode': '51',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'pe'
  }, {
    'name': 'Philippines',
    'iso2': 'ph',
    'dialCode': '63',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ph'
  }, {
    'name': 'Poland (Polska)',
    'iso2': 'pl',
    'dialCode': '48',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'pl'
  }, {
    'name': 'Portugal',
    'iso2': 'pt',
    'dialCode': '351',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'pt'
  }, {
    'name': 'Puerto Rico',
    'iso2': 'pr',
    'dialCode': '1',
    'priority': 3,
    'areaCode': null,
    'flagClass': 'pr'
  }, {
    'name': 'Qatar (‫قطر‬‎)',
    'iso2': 'qa',
    'dialCode': '974',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'qa'
  }, {
    'name': 'Réunion (La Réunion)',
    'iso2': 're',
    'dialCode': '262',
    'priority': 0,
    'areaCode': null,
    'flagClass': 're'
  }, {
    'name': 'Romania (România)',
    'iso2': 'ro',
    'dialCode': '40',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ro'
  }, {
    'name': 'Russia (Россия)',
    'iso2': 'ru',
    'dialCode': '7',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ru'
  }, {
    'name': 'Rwanda',
    'iso2': 'rw',
    'dialCode': '250',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'rw'
  }, {
    'name': 'Saint Barthélemy (Saint-Barthélemy)',
    'iso2': 'bl',
    'dialCode': '590',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'bl'
  }, {
    'name': 'Saint Helena',
    'iso2': 'sh',
    'dialCode': '290',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sh'
  }, {
    'name': 'Saint Kitts and Nevis',
    'iso2': 'kn',
    'dialCode': '1869',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'kn'
  }, {
    'name': 'Saint Lucia',
    'iso2': 'lc',
    'dialCode': '1758',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'lc'
  }, {
    'name': 'Saint Martin (Saint-Martin (partie française))',
    'iso2': 'mf',
    'dialCode': '590',
    'priority': 2,
    'areaCode': null,
    'flagClass': 'mf'
  }, {
    'name': 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    'iso2': 'pm',
    'dialCode': '508',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'pm'
  }, {
    'name': 'Saint Vincent and the Grenadines',
    'iso2': 'vc',
    'dialCode': '1784',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'vc'
  }, {
    'name': 'Samoa',
    'iso2': 'ws',
    'dialCode': '685',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ws'
  }, {
    'name': 'San Marino',
    'iso2': 'sm',
    'dialCode': '378',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sm'
  }, {
    'name': 'São Tomé and Príncipe (São Tomé e Príncipe)',
    'iso2': 'st',
    'dialCode': '239',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'st'
  }, {
    'name': 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    'iso2': 'sa',
    'dialCode': '966',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sa'
  }, {
    'name': 'Senegal (Sénégal)',
    'iso2': 'sn',
    'dialCode': '221',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sn'
  }, {
    'name': 'Serbia (Србија)',
    'iso2': 'rs',
    'dialCode': '381',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'rs'
  }, {
    'name': 'Seychelles',
    'iso2': 'sc',
    'dialCode': '248',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sc'
  }, {
    'name': 'Sierra Leone',
    'iso2': 'sl',
    'dialCode': '232',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sl'
  }, {
    'name': 'Singapore',
    'iso2': 'sg',
    'dialCode': '65',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sg'
  }, {
    'name': 'Sint Maarten',
    'iso2': 'sx',
    'dialCode': '1721',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sx'
  }, {
    'name': 'Slovakia (Slovensko)',
    'iso2': 'sk',
    'dialCode': '421',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sk'
  }, {
    'name': 'Slovenia (Slovenija)',
    'iso2': 'si',
    'dialCode': '386',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'si'
  }, {
    'name': 'Solomon Islands',
    'iso2': 'sb',
    'dialCode': '677',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sb'
  }, {
    'name': 'Somalia (Soomaaliya)',
    'iso2': 'so',
    'dialCode': '252',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'so'
  }, {
    'name': 'South Africa',
    'iso2': 'za',
    'dialCode': '27',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'za'
  }, {
    'name': 'South Korea (대한민국)',
    'iso2': 'kr',
    'dialCode': '82',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'kr'
  }, {
    'name': 'South Sudan (‫جنوب السودان‬‎)',
    'iso2': 'ss',
    'dialCode': '211',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ss'
  }, {
    'name': 'Spain (España)',
    'iso2': 'es',
    'dialCode': '34',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'es'
  }, {
    'name': 'Sri Lanka (ශ්‍රී ලංකාව)',
    'iso2': 'lk',
    'dialCode': '94',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'lk'
  }, {
    'name': 'Sudan (‫السودان‬‎)',
    'iso2': 'sd',
    'dialCode': '249',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sd'
  }, {
    'name': 'Suriname',
    'iso2': 'sr',
    'dialCode': '597',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sr'
  }, {
    'name': 'Svalbard and Jan Mayen',
    'iso2': 'sj',
    'dialCode': '47',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'sj'
  }, {
    'name': 'Swaziland',
    'iso2': 'sz',
    'dialCode': '268',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sz'
  }, {
    'name': 'Sweden (Sverige)',
    'iso2': 'se',
    'dialCode': '46',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'se'
  }, {
    'name': 'Switzerland (Schweiz)',
    'iso2': 'ch',
    'dialCode': '41',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ch'
  }, {
    'name': 'Syria (‫سوريا‬‎)',
    'iso2': 'sy',
    'dialCode': '963',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'sy'
  }, {
    'name': 'Taiwan (台灣)',
    'iso2': 'tw',
    'dialCode': '886',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tw'
  }, {
    'name': 'Tajikistan',
    'iso2': 'tj',
    'dialCode': '992',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tj'
  }, {
    'name': 'Tanzania',
    'iso2': 'tz',
    'dialCode': '255',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tz'
  }, {
    'name': 'Thailand (ไทย)',
    'iso2': 'th',
    'dialCode': '66',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'th'
  }, {
    'name': 'Timor-Leste',
    'iso2': 'tl',
    'dialCode': '670',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tl'
  }, {
    'name': 'Togo',
    'iso2': 'tg',
    'dialCode': '228',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tg'
  }, {
    'name': 'Tokelau',
    'iso2': 'tk',
    'dialCode': '690',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tk'
  }, {
    'name': 'Tonga',
    'iso2': 'to',
    'dialCode': '676',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'to'
  }, {
    'name': 'Trinidad and Tobago',
    'iso2': 'tt',
    'dialCode': '1868',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tt'
  }, {
    'name': 'Tunisia (‫تونس‬‎)',
    'iso2': 'tn',
    'dialCode': '216',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tn'
  }, {
    'name': 'Turkey (Türkiye)',
    'iso2': 'tr',
    'dialCode': '90',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tr'
  }, {
    'name': 'Turkmenistan',
    'iso2': 'tm',
    'dialCode': '993',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tm'
  }, {
    'name': 'Turks and Caicos Islands',
    'iso2': 'tc',
    'dialCode': '1649',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tc'
  }, {
    'name': 'Tuvalu',
    'iso2': 'tv',
    'dialCode': '688',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'tv'
  }, {
    'name': 'U.S. Virgin Islands',
    'iso2': 'vi',
    'dialCode': '1340',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'vi'
  }, {
    'name': 'Uganda',
    'iso2': 'ug',
    'dialCode': '256',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ug'
  }, {
    'name': 'Ukraine (Україна)',
    'iso2': 'ua',
    'dialCode': '380',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ua'
  }, {
    'name': 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    'iso2': 'ae',
    'dialCode': '971',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ae'
  }, {
    'name': 'United Kingdom',
    'iso2': 'gb',
    'dialCode': '44',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'gb'
  }, {
    'name': 'United States',
    'iso2': 'us',
    'dialCode': '1',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'us'
  }, {
    'name': 'Uruguay',
    'iso2': 'uy',
    'dialCode': '598',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'uy'
  }, {
    'name': 'Uzbekistan (Oʻzbekiston)',
    'iso2': 'uz',
    'dialCode': '998',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'uz'
  }, {
    'name': 'Vanuatu',
    'iso2': 'vu',
    'dialCode': '678',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'vu'
  }, {
    'name': 'Vatican City (Città del Vaticano)',
    'iso2': 'va',
    'dialCode': '39',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'va'
  }, {
    'name': 'Venezuela',
    'iso2': 've',
    'dialCode': '58',
    'priority': 0,
    'areaCode': null,
    'flagClass': 've'
  }, {
    'name': 'Vietnam (Việt Nam)',
    'iso2': 'vn',
    'dialCode': '84',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'vn'
  }, {
    'name': 'Wallis and Futuna',
    'iso2': 'wf',
    'dialCode': '681',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'wf'
  }, {
    'name': 'Western Sahara (‫الصحراء الغربية‬‎)',
    'iso2': 'eh',
    'dialCode': '212',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'eh'
  }, {
    'name': 'Yemen (‫اليمن‬‎)',
    'iso2': 'ye',
    'dialCode': '967',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'ye'
  }, {
    'name': 'Zambia',
    'iso2': 'zm',
    'dialCode': '260',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'zm'
  }, {
    'name': 'Zimbabwe',
    'iso2': 'zw',
    'dialCode': '263',
    'priority': 0,
    'areaCode': null,
    'flagClass': 'zw'
  }, {
    'name': 'Åland Islands',
    'iso2': 'ax',
    'dialCode': '358',
    'priority': 1,
    'areaCode': null,
    'flagClass': 'ax'
  }];
}
