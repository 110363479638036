import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appointmentReason'
})
export class AppointmentReasonPipe implements PipeTransform {

    transform(value: string | number): unknown {
        let reason = '';
        switch (+value) {
            case 1: reason = $localize`:checkup|appointment reason:checkup`; break;
            case 2: reason = $localize`:emergency|appointment reason:emergency`; break;
            case 3: reason = $localize`:follow up|appointment reason:follow up`; break;
            case 4: reason = $localize`:routine|appointment reason:routine`; break;
            case 5: reason = $localize`:walk in|appointment reason:walk in`; break;
            case 6: reason = $localize`:initial|appointment reason:initial`; break;

        }
        return reason;
    }

}
