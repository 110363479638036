import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BrowserCheckGuard implements CanActivate {

    constructor(
        private matDialog: MatDialog
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isIEOrEdge = /msie\s|trident\//i.test(window.navigator.userAgent);

        if (isIEOrEdge) {
            return false;
        }
        return true;
    }
}
