import { FmFormGroup } from '@app/classes/form/fm-form-group';

export function PasswordConfirmation() {
    return (group: FmFormGroup): { [key: string]: any; } => {

        if (group) {

            const password = group.controls['password'];
            const confirmPassword = group.controls['password_confirmation'];

            if (password.value !== confirmPassword.value) {
                return { passwordConfirmation: true };
            }
        }
    };
}

