<div class="page">
    <!-- Logo -->
    <div class="container">
        <div class="header p-3">
            <img
                src="./assets/img/logos/FreddieMed_logo_blue@2x-8.png"
                class="h-100"
                alt="Freddie Med logo"
                i18n-alt="@@general_alt_for_images"
            />
        </div>
    </div>

    <div class="sub-header">
        <div class="container row no-gutters flex-column safari-container mt-4 mx-auto">
            <div class="col-auto">
                <h2
                    i18n="
                        Freddie med is optimized|safari optimized page@@shared.safari-optimized.freddie med is optimized"
                >
                    Freddie Med is optimized for Safari.
                </h2>
                <p i18n="copy the link|safari optimized page@@shared.safari-optimized.please copy the link">
                    Please copy the link and open Freddie Med with Apple’s native browser.
                </p>
                <img src="./assets/img/browsers/safari.png" alt="safari logo" class="safari_logo mt-3" />
                <div class="w-100"></div>
                <span
                    class="copy ml-2"
                    (click)="copy_link()"
                    i18n="copy link|safari optimized page@@shared.safari-optimized.copy link"
                >
                    Copy link
                </span>
            </div>
            <div class="col mt-4">
                <img alt="computer image" src="./assets/img/illustrations/Doctor_Computer.svg" class="w-100" />
            </div>
        </div>
    </div>
</div>
